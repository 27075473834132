import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { correctHotelPrice } from '../../store/checkout/checkoutActions'
import { useDispatch, useSelector } from 'react-redux'
import isIframed from '../../utils/isIframed'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: '300px',
    textAlign: 'center',
    marginTop: '18px',
    marginBottom: theme.spacing(4),
  },
  text: {
    maxWidth: '400px',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  smallText: {
    fontSize: '12px',
    fontWeight: 'normal',
    textDecoration: 'underline',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  priceBtn: {
    textTransform: 'uppercase',
    letterSpacing: '1.9px',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightSemiBold,
    padding: '7px 29px',
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 19px',
    },
  },
  btn: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.medium,
    letterSpacing: '1.7px',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightSemiBold,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}))

const BookingFailModal = ({ error, setIsOpened, submit }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const isOpened = !!error
  const dispatch = useDispatch()
  const { items } = useSelector((state) => state.checkout)
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'

  const goMain = () => {
    if (isIframed) {
      history.push('/iframe')
    } else {
      history.push('/')
    }
    setIsOpened(false)
  }

  const correctPrice = () => {
    const { extData } = error
    if (extData?.bookingCode && extData?.realPrice)
      dispatch(correctHotelPrice({ [extData.bookingCode]: extData.realPrice }))
    setIsOpened(false)
    submit()
  }

  const prepareDialogBody = () => {
    const { code, error: errorText } = error
    if (!error) return null

    // This happens in multiticket flights that can not be combined.
    if (/UNABLE TO SATISFY/.test(errorText)) {
      return buildGeneralError(t('attempt failed - combination'))
    }

    if (/INVALID LOYALTY/.test(errorText)) {
      return buildGeneralError(t('attempt failed - loyalty'))
    }

    if (/duplicate booking/.test(errorText)) {
      return buildGeneralError(t('attempt failed - duplicate'))
    }

    switch (code) {
      case 1001: {
        return buildPriceError()
      }

      case 1030:
      case 1031:
      case 1032:
      case 1033:
      case 1034:
      case 1035: {
        return buildSpecificError(
          t('payment failed title'),
          t('payment failed message')
        )
      }

      default: {
        return buildGeneralError(t('attempt failed'), errorText)
      }
    }
  }

  const buildSpecificError = (title, message) => {
    return (
      <Box
        pt={isMobile ? 6 : 7}
        pb={isMobile ? 10 : 7}
        paddingX={isMobile ? 4 : 12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.content}
      >
        <SentimentVeryDissatisfiedIcon
          style={{ fontSize: '90px' }}
          color="primary"
        />
        <Typography className={classes.heading}>{title}</Typography>
        <Typography className={classes.text}>{message}</Typography>
        <Button onClick={goMain} className={classes.btn}>
          {t('go home page')}
        </Button>
      </Box>
    )
  }

  const buildGeneralError = (message, errTxt) => {
    return (
      <Box
        pt={isMobile ? 6 : 7}
        pb={isMobile ? 10 : 7}
        paddingX={isMobile ? 4 : 12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.content}
      >
        <SentimentVeryDissatisfiedIcon
          style={{ fontSize: '90px' }}
          color="primary"
        />
        <Typography className={classes.heading}>
          {t('your booking failed')}
        </Typography>
        <Typography className={classes.text}>{message}</Typography>
        {!!errTxt && typeof errTxt === 'string' && (
          <Typography className={classes.smallText}>
            Error: "{errTxt}"
          </Typography>
        )}
        <Button onClick={goMain} className={classes.btn}>
          {t('go home page')}
        </Button>
      </Box>
    )
  }

  const buildPriceError = () => {
    const { extData } = error
    const { roomRate, roomType, rawPrice } = extData
    const hotel = items.find((i) => {
      return (
        i.type === 'Hotel' &&
        i.room.roomTypeCode === roomType &&
        i.room.ratePlanCode === roomRate &&
        i.room.rawPrice === rawPrice
      )
    })
    const replaces = {
      to_price: extData?.realPrice || 0,
      from_price: hotel?.totalPrice || 0,
      type: t('hotel'),
      currencyCode,
    }
    return (
      <Box
        pt={isMobile ? 6 : '45px'}
        pb={isMobile ? 10 : '45px'}
        paddingX={isMobile ? 4 : 12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.content}
      >
        <AttachMoneyIcon
          style={{ fontSize: '94px', color: theme.palette.common.black }}
        />
        <Typography className={classes.heading}>
          {t('change price header')}
        </Typography>
        <Typography className={classes.text}>
          {t('change price text', replaces)}
        </Typography>
        <Box mt={'12px'} width={'100%'} display={'flex'}>
          <Box style={{ justifyContent: 'flex-end' }} display={'flex'} flex={1}>
            <Button
              variant="outlined"
              onClick={goMain}
              className={classes.priceBtn}
              color="primary"
            >
              {t('no price change')}
            </Button>
          </Box>
          <Box width={'8px'} />
          <Box
            style={{ justifyContent: 'flex-start' }}
            display={'flex'}
            flex={1}
          >
            <Button
              variant="contained"
              onClick={correctPrice}
              className={classes.priceBtn}
              color="primary"
            >
              {t('accept')}
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  const backdropProps = isIframed
    ? { style: { backgroundColor: 'transparent' } }
    : null

  return (
    <Dialog BackdropProps={backdropProps} open={isOpened}>
      {prepareDialogBody()}
    </Dialog>
  )
}

export default BookingFailModal
