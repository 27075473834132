export function getBrandedUrl(path) {
    const isLocalhost = window.location.hostname.startsWith('localhost')
    const onlyLocalhost = window.location.hostname === 'localhost'
    let agency = ( isLocalhost ? (onlyLocalhost ? 'localhost' : window.location.hostname.substring(10)) : window.location.hostname)
    return `https://static.citycity.se/agencies/${agency}/${path}`
}

export function getDomain() {
    const isLocalhost = window.location.hostname.startsWith('localhost')
    const onlyLocalhost = window.location.hostname === 'localhost'
    return ( isLocalhost ? (onlyLocalhost ? 'localhost' : window.location.hostname.substring(10)) : window.location.hostname)
}