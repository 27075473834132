import React, {
  memo,
  useState,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  createContext,
} from 'react'
import TransferModalHeader from '../../components/transfer/transferModalHeader'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { addToCheckoutAction } from '../../store/checkout/checkoutActions'
import { closeAllModals } from '../../store/modals/modalAction'
import moment from 'moment'

import AddTransferStep from '../../components/transfer/steps/addTransferStep'
import DropoffAdressStep from '../../components/transfer/steps/dropOffAdressStep'
import SelectTransferStep from '../../components/transfer/steps/selectTransferStep'
import SelectTransferTypeStep from '../../components/transfer/steps/selectTransferTypeStep'
import { useTranslation } from 'react-i18next'
import {
  cleanTransferSearch,
  setTaxiError,
} from '../../store/transfer/transferAction'
import AddRequiredInformationStep from '../../components/transfer/steps/addRequiredInformationStep'
import { fetchTransferPhone } from '../../repositories/transfer'
import { fetchTrainCityByCode } from '../../repositories/trains'
import { CircularProgress, Box, Step, StepLabel } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import storage from 'local-storage-fallback'
import transferAirports from '../../constants/transferAirports'
import { generateUniqueRandomString } from '../../utils/randomString'

const initialState = {
  transfers: [],
  transfersToCancel: [],
  current: '',
  street: null,
  phone: null,
  isInit: false,
}


function reducer(state, action) {
  const { payload, type, skipLocalStorage = false } = action

  switch (type) {
    case 'init':
      return {
        ...state,
        transfers: [...payload.transfers],
        transfersToCancel: [],
        phone: payload?.phone || null,
        isInit: true,
      }
    case 'from_storage':
      return {
        ...state,
        transfers: payload.transfers,
        transfersToCancel: [],
        phone: payload?.phone || null,
        isInit: true,
      }
    case 'set_phone': {
      if (!payload && !skipLocalStorage) {
        storage.removeItem('transfer_phone')
      } else if (!skipLocalStorage) {
        storage.setItem('transfer_phone', JSON.stringify(payload))
      }

      return {
        ...state,
        phone: payload,
      }
    }
    case 'update_single_transfer':
      const updateTransfers = state.transfers.map((trip, i) => {
        if (trip.meta.uniqueKey === payload.index) {
          const tmpTransfer =
            typeof payload == 'object' && payload
              ? { ...payload, dirKey: trip.meta.uniqueKey }
              : payload
          return { ...trip, transfer: [...(trip?.transfer || []), tmpTransfer] }
        }
        return trip
      })
      if (!skipLocalStorage) storage.setItem('transfer', JSON.stringify(updateTransfers))
      return {
        ...state,
        transfers: updateTransfers,
      }
    case 'update_transfer_passenger': {
      const { uniqueKey, passenger } = payload
      const updatedTransfers = state.transfers.map((trip) => {
        if (!uniqueKey || !trip?.transfer) return trip

        trip.transfer = trip?.transfer.map((t) => {
          if (uniqueKey !== t.uniqueKey) return t
          return { ...t, passenger: passenger }
        })

        return trip
      })
      if (!skipLocalStorage) storage.setItem('transfer', JSON.stringify(updatedTransfers))
      return {
        ...state,
        transfers: updatedTransfers,
      }
    }
    case 'remove__single_transfer':
      let delTrKey = null
      const removeTransfer = state.transfers.map((trip, i) => {
        const { index, uniqueKey } = payload
        if (trip.meta.uniqueKey !== index || !trip.transfer?.length || !uniqueKey) return trip
        trip.transfer = trip.transfer.filter((t) => {
          const needDelete = t?.uniqueKey === uniqueKey;
          if (needDelete && !!t?.isAdded) delTrKey = t.bookingReference;
          return !needDelete
        })
        if (!trip.transfer?.length) trip.transfer = null
        return trip
      })
      if (!skipLocalStorage) storage.setItem('transfer', JSON.stringify(removeTransfer))
      const toCancel = [...state.transfersToCancel]
      if (!!delTrKey) toCancel.push(delTrKey)

      return {
        ...state,
        transfers: removeTransfer,
        transfersToCancel: toCancel,
      }
    case 'set_current':
      return {
        ...state,
        current: payload,
      }
    case 'set_street':
      return {
        ...state,
        street: payload,
      }
    default:
      throw new Error()
  }
}

const useStyles = makeStyles((theme) => ({
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  doneBtn: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 6),
    borderRadius: theme.spacing(3),
    letterSpacing: 1.1,
    fontSize: '18px',
    width: '235px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  alert: {
    margin: '0 48px 15px 48px',
  },
}))

export const TransfersContext = createContext({
  allUsers: [],
  userLocators: []
})

const TransferContainer = ({users, trip, skipLocalStorage, onSelect, onClose, addedTransfers}) => {
  const { t } = useTranslation()

  const reuduxDispatch = useDispatch()
  const classes = useStyles()
  const { user } = useSelector((state) => state.auth)
  const { items } = useSelector((state) => state.checkout)
  const transferItem = useMemo(
    () => items.find((itm) => itm.type === 'transfer'),
    [items]
  )

  const restrictedTransfers = user?.bookingLink?.restrictedTransfers

  const [isLoadingPhone, setIsLoadingPhone] = useState(true)
  const [tripDataLoading, setTripDataLoading] = useState(true)
  const [error, setError] = useState(null)
  const [submitTried, setSubmitTried] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [tripData, setTripData] = useState(null)
  const [skipped, setSkipped] = React.useState(new Set())
  const [itinerary, dispatch] = useReducer(reducer, initialState)

  const isDataLoading = isLoadingPhone || tripDataLoading

  const prepareTripData = useCallback(async () => {
    setTripDataLoading(true)
    let resultTripData = null

    if (!trip) {
      const railItem = items?.find((itm) => itm.type === 'Train')
      const flightItem = items?.find((itm) => itm.type === 'Flight')

      if (!!railItem || !!flightItem) {
        resultTripData = {}
        if (!!railItem) resultTripData.rail = {...railItem}
        if (!!flightItem) resultTripData.flight = {...flightItem}
      }
      setTripDataLoading(false)
      setTripData(resultTripData)
      return true
    }

    if (trip?.rail) {
      const outboundStartCode = await fetchTrainCityByCode(trip?.rail?.outbound?.railstart?.raillocationCode)
      const outboundEndCode = await fetchTrainCityByCode(trip?.rail?.outbound?.railend?.raillocationCode)
      const resultTrip = {}
      if (!outboundEndCode || !outboundStartCode) {
        setTripData(null)
        setTripDataLoading(false)
        return false
      }
      resultTrip.searchCodes = {from: outboundStartCode.UIC, to: outboundEndCode.UIC}
      resultTrip.outboundTrip = {
        train: {
          segments: trip.rail.outbound.segments,
          scheduleSolution: {
            railstart: trip.rail.outbound.railstart,
            railend: trip.rail.outbound.railend,
          }
        }
      }
      if (!!trip.rail.return) {
        resultTrip.returnTrip = {
          train: {
            segments: trip.rail.return.segments,
            scheduleSolution: {
              railstart: trip.rail.return.railstart,
              railend: trip.rail.return.railend,
            }
          }
        }
      }
      if (!resultTripData) resultTripData = {}
      resultTripData.rail = resultTrip
    }

    if (!!trip?.flight) {
      const resultTrip = {}
      if (!trip?.flight?.outbound?.length) {
        setTripData(null)
        setTripDataLoading(false)
        return false
      }
      const oFirst = trip.flight.outbound[0]
      const oLast = trip.flight.outbound[trip.flight.outbound.length - 1]

      resultTrip.outgoing = {
        airline: oFirst.airline,
        operatingCarrier: oFirst.operatingCarrier,
        departure: oFirst.departure,
        arrival: oLast.arrival,
        flightnumber: oFirst.flightnumber,

        from: {
          shortName: oFirst.from,
          displayName: oFirst.fromAirport,
          city: oFirst.fromCity,
        },

        to: {
          shortName: oLast.to,
          displayName: oLast.toAirport,
          city: oLast.toCity,
        },

        original: {
          trip: trip.flight.outbound
        }
      }

      if (!!trip.flight.return) {
        const rFirst = trip.flight.return[0]
        const rLast = trip.flight.return[trip.flight.return.length - 1]

        resultTrip.returning = {
          airline: rFirst.airline,
          operatingCarrier: rFirst.operatingCarrier,
          departure: rFirst.departure,
          arrival: rLast.arrival,
          flightnumber: rFirst.flightnumber,

          from: {
            shortName: rFirst.from,
            displayName: rFirst.fromAirport,
            city: rFirst.fromCity,
          },

          to: {
            shortName: rLast.to,
            displayName: rLast.toAirport,
            city: rLast.toCity,
          },

          original: {
            returnTrip: trip.flight.return
          }
        }
      }

      if (!resultTripData) resultTripData = {}
      resultTripData.flight = resultTrip
    }
    setTripData(resultTripData)
    setTripDataLoading(false)
    return true
  }, [items, trip])

  useEffect(() => {
    prepareTripData()
  }, [prepareTripData])

  const localDispatch = (data) => {
    const dispatchData = {...data}
    if (skipLocalStorage) dispatchData.skipLocalStorage = skipLocalStorage
    dispatch(dispatchData)
  }

  const {allUsers, userLocators} = useMemo(() => {
    const allUsers = []
    const userLocators = []

    if (!users) {
      items?.forEach(i => {
        i?.passengers?.forEach((p) => {
          if (!p?.uniqueId || userLocators.includes(p.uniqueId)) return false
          allUsers.push({...p})
          userLocators.push(p.uniqueId)
        })
      })
    } else {
      (users || [])?.forEach((p) => {
        if (!p?.uniqueId || userLocators.includes(p.uniqueId)) return false
        allUsers.push({...p})
        userLocators.push(p.uniqueId)
      })
    }

    return {allUsers, userLocators}
  }, [items, users])

  const getAddedTransfers = useCallback(() => {
    if (!addedTransfers || typeof addedTransfers !== 'object' || !addedTransfers?.length) {
      return []
    }
    const result = []

    const indexes = [
      'from_flight_outbound',
      'to_flight_outbound',
      'from_flight_return',
      'to_flight_return',
      'from_rail_outbound',
      'to_rail_outbound',
      'from_rail_return',
      'to_rail_return'
    ]

    addedTransfers.forEach(tr => {
      const type = ['FBUS', 'RAILE'].includes(tr?.productCode) ? 'flightBus' : 'flightTaxi';
      const startDate = moment(`${tr?.fromDate} ${tr?.fromTime}`, 'DD MMM YYYY HH:mm');
      const endDate = moment(`${tr?.toDate} ${tr?.toTime}`, 'DD MMM YYYY HH:mm');

      if (!tr ||
        !indexes.includes(tr.dirKey) ||
        !startDate.isValid() ||
        !endDate.isValid() ||
        (!!tr.user?.locator && !userLocators.includes(tr.user?.locator)) ||
        tr.taxiType !== 'taxi' ||
        tr.cancelled
      ) {
        return false;
      }

      let user = null;
      if (!!tr.user?.locator) {
        user = (type === 'flightBus') ? [tr.user.locator] : tr.user.locator
      }

      result.push({
        product: {
          "code": tr.productCode,
          "name": tr.productName,
          "description": tr.productName,
        },
        price: {
          "priceListName": "Standard",
          "includingVat": tr.price,
        },
        startNode: {
          "time": startDate.format(),
          "street": {
            "location": {
              "name": tr.from
            }
          }
        },
        endNode: {
          "time": endDate.format(),
          "street": {
            "location": {
              "name": tr.to
            }
          }
        },
        isAdded: true,
        bookingReference: tr.bookingReference,
        passenger: user,
        uniqueKey: generateUniqueRandomString(),
        type: type,
        index: tr.dirKey
      })
    });

    return result
  }, [addedTransfers, userLocators])

  const contextValue = useMemo(() => ({
      allUsers,
      userLocators,
    }), [allUsers, userLocators])

  const noPhoneUsers = useMemo(() => {
    if (allUsers?.length <= 0) return []
    const res = []
    allUsers.forEach((p) => {
      const uid = p?.uniqueId || null
      const uPhone = !!uid ? itinerary?.phone?.[uid] : null
      if (!!uid && (!uPhone || typeof uPhone !== 'string')) {
        res.push({ ...p })
      }
    })
    return res
  }, [allUsers, itinerary])

  const getSteps = {
    '0': t('add transfers'),
    '1': t('select transfer'),
    '1.1': t('select flygbuss'),
    '2': itinerary.current?.meta?.from ? t('drop-off') : t('pick-up'),
    '3': t('select transfer')
}

  const getFlightNumber = (trip) => {
    if (!trip) return null
    return `${trip.company || trip.airline || trip.operatingCarrier}${
      trip.flightnumber
    }`
  }

  const getFlightMeta = useCallback(() => {
    const { outgoing, returning } = tripData?.flight || {}

    const outgoingNumber = getFlightNumber(outgoing)
    const returningNumber = returning ? getFlightNumber(returning) : null

    const oDep = outgoing ? new moment(outgoing.departure) : null;
    const rDep = returning ? new moment(returning.departure) : null;
    const results = []

    const outboundConnection = outgoing
      ? {
          departureCode: outgoing.from.shortName,
          departureDate: oDep.format(
            'YYYY-MM-DDTHH:mm:ss'
          ),
          arrivalCode: outgoing.to.shortName,
          arrivalDate: new moment(outgoing.arrival).format(
            'YYYY-MM-DDTHH:mm:ss'
          ),
          number: outgoingNumber,
          international: false,
          type: 'FLIGHT',
        }
      : null

    const returnConnection = returning
      ? {
          departureCode: returning.from.shortName,
          departureDate: rDep.format(
            'YYYY-MM-DDTHH:mm:ss'
          ),
          arrivalCode: returning.to.shortName,
          arrivalDate: new moment(returning.arrival).format(
            'YYYY-MM-DDTHH:mm:ss'
          ),
          number: returningNumber,
          international: false,
          type: 'FLIGHT',
        }
      : null

    if (outgoing) {
      results.push({
        sortKey: oDep.unix(),
        locationCode: outgoing.from.shortName,
        locationName: `${outgoing.from?.displayName}, ${outgoing.from?.city}`,
        flightnumber: outgoingNumber,
        time: (new moment(outgoing.departure)).format('YYYY-MM-DD HH:mm'),
        from: false,
        isInternational: outgoing.from.countryCode !== 'SE',
        transferEnabled: transferAirports.includes(outgoing.from.shortName),
        connection: {
          ...outboundConnection,
          number: getFlightNumber(outgoing.original.trip[0]),
          international:
            outgoing.from.countryCode !== outgoing.to.countryCode,
        },
        type: 'flight',
        dir: 'outbound',
        uniqueKey: 'to_flight_outbound'
      })

      results.push({
        sortKey: oDep.unix(),
        locationCode: outgoing.to.shortName,
        locationName: `${outgoing.to?.displayName}, ${outgoing.to?.city}`,
        flightnumber: outgoingNumber,
        time: (new moment(outgoing.arrival)).format('YYYY-MM-DD HH:mm'),
        from: true,
        isInternational: outgoing.to.countryCode !== 'SE',
        transferEnabled:
          transferAirports.includes(outgoing.to.shortName) &&
          !restrictedTransfers,
        connection: {
          ...outboundConnection,
          number: getFlightNumber(
            outgoing.original.trip[outgoing.original.trip.length - 1]
          ),
          international: outgoing.from.countryCode !== outgoing.to.countryCode,
        },
        type: 'flight',
        dir: 'outbound',
        uniqueKey: 'from_flight_outbound'
      })
    }

    if (returning) {
      results.push({
        sortKey: rDep.unix(),
        locationCode: returning.from.shortName,
        locationName: `${returning.from?.displayName}, ${returning.from?.city}`,
        flightnumber: returningNumber,
        time: (new moment(returning.departure)).format('YYYY-MM-DD HH:mm'),
        from: false,
        isInternational: returning.from.countryCode !== 'SE',
        transferEnabled:
          transferAirports.includes(returning.from.shortName) &&
          !restrictedTransfers,
        connection: {
          ...returnConnection,
          number: getFlightNumber(returning.original.returnTrip[0]),
          international: returning.from.countryCode !== returning.to.countryCode,
        },
        type: 'flight',
        dir: 'return',
        uniqueKey: 'to_flight_return'
      })

      results.push({
        sortKey: rDep.unix(),
        locationCode: returning.to.shortName,
        locationName: `${returning.to?.displayName}, ${returning.to?.city}`,
        flightnumber: returningNumber,
        time: (new moment(returning.arrival)).format('YYYY-MM-DD HH:mm'),
        from: true,
        isInternational: returning.to.countryCode !== 'SE',
        transferEnabled: transferAirports.includes(returning.to.shortName),
        connection: {
          ...returnConnection,
          number: getFlightNumber(
            returning.original.returnTrip[returning.original.returnTrip.length - 1]
          ),
          international: returning.from.countryCode !== returning.to.countryCode,
        },
        type: 'flight',
        dir: 'return',
        uniqueKey: 'from_flight_return'
      })
    }

    return results
  }, [tripData])

  const getRailMeta = useCallback(() => {
    const convDate = (date, unix = false) => {
      const moDate = new moment(date, 'YYYY-MM-DDTHH:mm:ss')
      return unix ? moDate.unix() : moDate.format('YYYY-MM-DD HH:mm:ss')
    }

    const {
      outboundTrip: outgoing,
      returnTrip: returning,
      searchCodes,
    } = tripData?.rail || {}

    const oUnix = outgoing ? convDate(outgoing.train.scheduleSolution.railstart.dateTime, true) : null;
    const rUnix = returning ? convDate(returning.train.scheduleSolution.railstart.dateTime, true) : null;
    const results = []


    const outboundConnection = outgoing
      ? {
          departureCode: searchCodes.from,
          departureDate: outgoing.train.scheduleSolution.railstart.dateTime,
          arrivalCode: searchCodes.to,
          arrivalDate: outgoing.train.scheduleSolution.railend.dateTime,
          number: outgoing.flightnumber,
          international: false,
          type: 'TRAIN',
        }
      : null

    const returnConnection = returning
      ? {
          departureCode: searchCodes.to,
          departureDate: returning.train.scheduleSolution.railstart.dateTime,
          arrivalCode: searchCodes.from,
          arrivalDate: returning.train.scheduleSolution.railend.dateTime,
          number: returning.flightnumber,
          international: false,
          type: 'TRAIN',
        }
      : null

    if (!!outgoing) {
      results.push({
        sortKey: oUnix,
        locationCode: searchCodes.from,
        locationName:
          outgoing.train.scheduleSolution.railstart.locationName.toLowerCase(),
        time: convDate(outgoing.train.scheduleSolution.railstart.dateTime),
        from: false,
        transferEnabled: true,
        connection: {
          ...outboundConnection,
          number: outgoing.train.segments[0].railidentifier,
        },
        type: 'rail',
        dir: 'outbound',
        uniqueKey: 'to_rail_outbound'
      })

      results.push({
        sortKey: oUnix,
        locationCode: searchCodes.to,
        time: convDate(outgoing.train.scheduleSolution.railend.dateTime),
        from: true,
        locationName:
          outgoing.train.scheduleSolution.railend.locationName.toLowerCase(),
        transferEnabled: !restrictedTransfers,
        connection: {
          ...outboundConnection,
          number:
          outgoing.train.segments[outgoing.train.segments.length - 1]
            .railidentifier,
        },
        type: 'rail',
        dir: 'outbound',
        uniqueKey: 'from_rail_outbound'
      })
    }

    if (returning) {
      results.push({
        sortKey: rUnix,
        locationCode: searchCodes.to,
        locationName:
          returning.train.scheduleSolution.railstart.locationName.toLowerCase(),
        time: convDate(returning.train.scheduleSolution.railstart.dateTime),
        from: false,
        transferEnabled: !restrictedTransfers,
        connection: {
          ...returnConnection,
          number: returning.train.segments[0].railidentifier,
        },
        type: 'rail',
        dir: 'return',
        uniqueKey: 'to_rail_return'
      })

      results.push({
        sortKey: rUnix,
        locationCode: searchCodes.from,
        time: convDate(returning.train.scheduleSolution.railend.dateTime),
        locationName:
          returning.train.scheduleSolution.railend.locationName.toLowerCase(),
        from: true,
        transferEnabled: true,
        connection: {
          ...returnConnection,
          number:
          returning.train.segments[returning.train.segments.length - 1]
            .railidentifier,
        },
        type: 'rail',
        dir: 'return',
        uniqueKey: 'from_rail_return'
      })
    }

    return results;
  }, [tripData])

  useEffect(() => {
    if (!tripData || !!tripDataLoading) return undefined;

    let cleanedData =[...getFlightMeta(), ...getRailMeta()]
    cleanedData.sort((a, b) => a.sortKey - b.sortKey)

    const addedTransfers = getAddedTransfers()

    let phone = null
    if (!skipLocalStorage) JSON.parse(storage.getItem('transfer_phone'))

    const getPhone = async () => {
      if (!phone) {
        try {
          let phoneData = await fetchTransferPhone(
            allUsers.map((p) => p.uniqueId)
          )
          if (typeof phoneData === 'object' && !!phoneData) {
            phone = phoneData
          }

          allUsers.forEach((p) => {
            const mobile = p?.mobile || p?.phone
            if (!mobile || !p?.uniqueId || !!phone?.[p.uniqueId]) return false
            if (!phone || typeof phone !== 'object') phone = {}
            phone[p.uniqueId] = mobile
          })
        } catch (ex) {
          // No phone found
        }
      }
    }

    getPhone().then(() => {
      if (!skipLocalStorage && storage.getItem('transfer')) {
        const trans = JSON.parse(storage.getItem('transfer'))
        setIsLoadingPhone(false)
        return localDispatch({
          type: 'from_storage',
          payload: {
            transfers: trans,
            phone: phone || null,
          },
        })
      }

      const initPayload = {
        transfers : [],
        phone: phone || null
      }

      cleanedData.forEach(async (trip) => {
        if (!trip) return
        const index = trip.uniqueKey
        const tmpATransfers = addedTransfers.filter(added => added.index === index)
        initPayload.transfers.push({
          meta: { ...trip, index: index },
          users: [...allUsers],
          phone: phone || null,
          ...(tmpATransfers.length ? {transfer:tmpATransfers} : {})
        })
      })

      setIsLoadingPhone(false)
      localDispatch({ type: 'init', payload: initPayload })
    })
  }, [
    dispatch,
    getFlightMeta,
    getAddedTransfers,
    getRailMeta,
    tripData,
    tripDataLoading,
    allUsers,
    transferItem,
    setIsLoadingPhone,
  ])

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    reuduxDispatch(setTaxiError(null))
    reuduxDispatch(cleanTransferSearch())

    setActiveStep((prevActiveStep) => {
      const currMainStep = Math.floor(prevActiveStep || 0)
      return currMainStep + 1
    })
    setError(false)
  }

  const handleBack = () => {
    if (activeStep?.step === 0) return
    setActiveStep((prevActiveStep) => {
      const currStep = prevActiveStep || 0
      const currMainStep = Math.floor(prevActiveStep || 0)
      return (currStep > currMainStep) ? currMainStep : currMainStep - 1
    })
    setError(false)
  }

  const handleReset = () => {
    setActiveStep(0)
    setError(false)
  }

  const setCurrentPath = (path) => {
    handleNext()
    return localDispatch({ type: 'set_current', payload: path })
  }

  const setCurrentStreet = (street) => {
    return localDispatch({ type: 'set_street', payload: street })
  }

  const setRelevantItinerary = (transferData) => {
    const payload = transferData
    if (!!payload && typeof payload === 'object') {
      payload.uniqueKey = generateUniqueRandomString()
    }

    const currTrip = (itinerary?.transfers || []).find(t => {
      return !!t?.meta?.uniqueKey && t?.meta?.uniqueKey === payload?.index
    })
    const options = currTrip?.transfer || []
    const remainedUsers = getRemainedUsers(options)

    if (typeof remainedUsers === 'object' && remainedUsers?.length === 1) {
      const isTrain =
        transferData?.type === 'flightBus' ||
        (transferData?.type === 'tagTaxi' &&
          transferData?.product?.code === 'RAILE')
      if (isTrain) {
        payload.passenger = [remainedUsers[0]]
      } else {
        payload.passenger = remainedUsers[0]
      }
    }

    return localDispatch({ type: 'update_single_transfer', payload })
  }

  const setTransferPassenger = (payload) => {
    return localDispatch({ type: 'update_transfer_passenger', payload })
  }

  const removeTransfer = (index) => {
    return localDispatch({ type: 'remove__single_transfer', payload: index })
  }

  const preActiveStep = useCallback((step) => {
    setActiveStep(step)
    setError(false)
  }, [setActiveStep, setError])

  const addTransferToCheckout = () => {
    try {
      const cleanDataForPrice = itinerary.transfers
        .map((taxi) => {
          if (!taxi.transfer?.length) return 0
          return taxi.transfer
            .map((t) => {
              if (!!t?.isAdded) return 0
              const isTrain =
                t?.type === 'flightBus' ||
                (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE')
              const userNotList =
                typeof t.passenger !== 'object' || !t.passenger?.length
              if (!t.passenger || (isTrain && userNotList))
                throw new Error('transfer without user')

              if (isTrain) {
                return parseInt(
                  parseFloat(t?.price?.includingVat) * t.passenger.length
                )
              }
              return parseInt(t?.price?.includingVat)
            })
            .reduce((a, b) => a + b, 0)
        })
        .reduce((a, b) => a + b, 0)

      let co2 = 0
      itinerary.transfers.forEach((taxi) => {
        if (!taxi.transfer?.length) return
        taxi.transfer.forEach((t) => {
          if (!t.passenger?.length || !!t?.isAdded) return
          const numPassengers = Array.isArray(t.passenger)
            ? t.passenger.length
            : 1

          co2 += t.co2 * numPassengers
        })
      })

      const payload = {
        totalPrice: cleanDataForPrice,
        type: 'Transfer',
        details: itinerary.transfers.map(dirBlock => {
          const resData = {...dirBlock}
          if (!!resData?.transfer?.length) {
            resData.transfer = resData.transfer.filter(t => !t.isAdded)
            if (!resData.transfer?.length) resData.transfer = null
          }
          return resData
        }),
        transfersToCancel: itinerary.transfersToCancel,
        phone: itinerary.phone,
        co2,
      }

      if (skipLocalStorage) {
        onSelect(payload)
      } else {
        reuduxDispatch(addToCheckoutAction(payload))
        reuduxDispatch(closeAllModals())
      }
    } catch (e) {
      setError(t(e.message))
      setSubmitTried(true)
      return false
    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddTransferStep
            title={getSteps[step]}
            setCurrentPath={setCurrentPath}
            transfers={itinerary.transfers}
            removeTransfer={removeTransfer}
            setTransferPassenger={setTransferPassenger}
            addTransferToCheckout={addTransferToCheckout}
            touched={submitTried}
          />
        )
      case 1:
      case 1.1:
        return (
          <SelectTransferTypeStep
            title={getSteps[step]}
            onChangeStep={setActiveStep}
            isFlightbusDisabled={!itinerary?.phone}
            current={itinerary.current}
            setRelevantItinerary={setRelevantItinerary}
            handleReset={handleReset}
            onlyType={step === 1.1 ? 'fbus' : null}
          />
        )
      case 2:
        return (
          <DropoffAdressStep
            handleNext={handleNext}
            title={getSteps[step]}
            current={itinerary.current}
            setCurrentStreet={setCurrentStreet}
            street={itinerary.street}
          />
        )
      case 3:
        return (
          <SelectTransferStep
            title={getSteps[step]}
            isFlightbusDisabled={!itinerary?.phone}
            current={itinerary.current}
            street={itinerary.street}
            setRelevantItinerary={setRelevantItinerary}
            handleReset={handleReset}
            setActiveStep={preActiveStep}
          />
        )

      default:
        return 'Unknown step'
    }
  }

  const getRemainedUsers = (options) => {
    const used = []
    const optsArray =
      typeof options === 'object' && !!options?.length ? options : []
    optsArray?.forEach((t, i) => {
      const isTrain =
        t?.type === 'flightBus' ||
        (t?.type === 'tagTaxi' && t?.product?.code === 'RAILE')

      if (
        !t?.passenger ||
        (isTrain && (typeof t.passenger !== 'object' || !t.passenger?.length))
      ) {
        return false
      }
      if (isTrain) {
        t.passenger?.forEach((pId) => {
          if (!used.includes(pId)) used.push(pId)
        })
      } else {
        if (!used.includes(t.passenger)) used.push(t.passenger)
      }
    })

    const notUsed = []
    allUsers?.forEach((p) => {
      if (
        !p?.uniqueId ||
        used.includes(p.uniqueId) ||
        notUsed.includes(p.uniqueId)
      )
        return false
      notUsed.push(p.uniqueId)
    })

    return notUsed
  }

  const ifWeHaveAnyTransfersInState = itinerary.transfers.some((t) => {
    const found = (t?.transfer || []).find(singleTr => {
      return !!singleTr && !singleTr?.isAdded
    })
    return !!found
  })

  // User needs to add mobile number if it does not exist.
  if (noPhoneUsers?.length > 0 && itinerary.isInit) {
    return (
      <AddRequiredInformationStep
        users={noPhoneUsers}
        setPhones={(newPhones) => {
          const result =
            !!itinerary?.phone && typeof itinerary?.phone === 'object'
              ? itinerary?.phone
              : {}
          const newVal =
            !!newPhones && typeof newPhones === 'object' ? newPhones : {}

          localDispatch({ type: 'set_phone', payload: { ...result, ...newVal } })
        }}
        onClose={onClose}
      />
    )
  }

  return (
    <TransfersContext.Provider value={contextValue}>
      <Box>
        <TransferModalHeader
          handleBack={handleBack}
          activeStep={activeStep}
          title={getSteps[activeStep]}
          onClose={onClose}
        />

        <Box p={2}>
          {getStepContent(activeStep)}
          {!isDataLoading && !!error && (
            <Alert className={classes.alert} severity="error">
              {error}
            </Alert>
          )}
        </Box>

        {isDataLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
            <CircularProgress size={30} color={'light'} />
          </Box>
        )}

        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          {ifWeHaveAnyTransfersInState && activeStep === 0 && (
            <Box
              color="primary"
              variant="contained"
              className={classes.doneBtn}
              onClick={addTransferToCheckout}
              disableElevation
            >
              {t('done')}
            </Box>
          )}
        </Box>
      </Box>
    </TransfersContext.Provider>
  )
}

export default memo(TransferContainer)
