import React, { useState } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useProfileStyles } from './profileStyles'
import { useSelector } from 'react-redux'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import { Pagination } from '@material-ui/lab'
import { getApiUrl } from '../../repositories/agent'
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const PolicyDocument = ({ isStandalone = false }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isDesktop = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useSelector((state) => state.auth)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const classes = useProfileStyles()

  const lang = i18n.languages[0]
  const documentLink = getApiUrl(
    `/v2/policy-document/${user.companyId}/${lang}`
  )
  //const documentLink = `https://test.citycity.se/api/v2/policy-document/${user.companyId}`

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleChangePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber)
  }

  const pdfWidth = isDesktop ? window.innerWidth - 1 : null

  return (
    <Box pt={isDesktop ? 0 : 2}>
      {!isDesktop && !isStandalone && (
        <Typography pb={4} variant="h3" className={classes.weight} gutterBottom>
          {t('policy document')}
        </Typography>
      )}

      {numPages > 1 && (
        <Box
          display="flex"
          style={{
            width: '100%',
            justifyContent: 'center',
          }}
          pt={4}
        >
          <Pagination
            count={numPages}
            color="primary"
            page={pageNumber}
            onChange={handleChangePage}
            style={{
              borderRadius: '8px',
              backgroundColor: '#F9F9F9',
              padding: '6px',
            }}
          />
        </Box>
      )}

      <Box
        display="flex"
        style={{
          width: '100%',
          justifyContent: 'center',
          maxHeight: isDesktop ? '80vh' : '70vh',
          overflowY: 'auto',
          marginTop: '10px',
        }}
      >
        <Document
          loading={<CircularProgress />}
          file={documentLink}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} width={pdfWidth} />
        </Document>
      </Box>
    </Box>
  )
}

export default PolicyDocument
