import React, {Fragment, useCallback, useEffect, useState, useMemo} from 'react'
import _ from 'lodash'
import {Box, Button, CircularProgress, Grid, Container} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import {editAndApproveRail, fetchRailSchedules} from '../../repositories/suggestions'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { getBrandedUrl, getDomain } from '../../utils/branding'
import {useTranslation} from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  tripInfoBlock: {
    padding: '38px 50px',
    borderRadius: '4px',
    border: 'solid 1px #ededed',
    backgroundColor: '#fff',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      padding: '18px 20px',
      borderRadius: '8px'
    }
  },

  tripInfoBlockInner: {
    display: 'inline-block'
  },

  tripHead: {
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
    color: 'black',
    marginBottom: '30px',
    minWidth: '100%',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '23px',
      marginBottom: '27px',
      fontSize: '32px',
      borderBottom: 'solid 1px #ededed'
    }
  },

  tripInfoLine: {
    display: 'flex',
    marginBottom: '23px',
    lineHeight: '19px',
    fontSize: '14px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: '16px',
    }
  },

  tripInfoLineHead: {
    minWidth: '114px',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '3px'
    }
  },

  priceTotalText: {
    display: 'inline',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      lineHeight: '1.44'
    }
  },

  tripInfoLineValue: {
    flex: '1',

    [theme.breakpoints.down('sm')]: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '16px',
    }
  },

  changedText: {
    borderRadius: '8px',
    border: 'solid 1px #23cbff',
    backgroundColor: 'rgba(35, 203, 255, 0.2)',
    padding: '5px 11px',
    lineHeight: 'normal',
    display: 'inline-block'
  },

  changedTotal: {
    borderRadius: '8px',
    border: 'solid 1px #23cbff',
    backgroundColor: 'rgba(35, 203, 255, 0.2)',
    padding: '9px 40px',
    lineHeight: 'normal',
    fontSize: '18px',
    marginBottom: '18px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '327px'
    }
  },

  crossedText: {
    color: '#979797',
    textDecoration: 'line-through',
    lineHeight: 'normal',
  },

  bold: {
    fontWeight: theme.typography.fontWeightBold
  },

  topPriceBlock: {
    padding: '16px',
    borderRadius: '4px',
    border: 'solid 1px #ededed',
    backgroundColor: 'white',
    marginBottom: '16px',

    '& $changedTotal': {
      marginBottom: '0',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%'
      }
    },

    [theme.breakpoints.down('sm')]: {
      borderRadius: '8px',
    }
  },

  tripBottomBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '45px',
    borderRadius: '4px',
    border: 'solid 1px #ededed',
    backgroundColor: 'white',
    marginTop: '14px',
    alignItems: 'center',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
      padding: '37px 16px',
      borderRadius: '8px'
    }
  },

  tripTotalBlock: {
    display: 'inline-block',
    fontSize: '18px',
    marginBottom: '18px'
  },

  bookQuestion: {
    display: 'inline-block',
    fontSize: '18px',
    marginBottom: '30px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  },

  buttonsRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },

  approveButton: {
    display: 'flex',
    width: '280px',
    height: '48px',
    borderRadius: '12px',
    backgroundColor: '#ee4c25',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    marginBottom: '23px',
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '0 15px'
  },

  moreButton: {
    display: 'flex',
    marginRight: '12px',
    width: '280px',
    height: '48px',
    borderRadius: '12px',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    marginBottom: '23px',
    fontWeight: theme.typography.fontWeightBold,
    color: '#ee4c25',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '0 15px',
    border: 'solid 1px #ee4c25',

    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    }
  },

  classLink: {
    display: 'inline-block',
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  logoImage: {
    height: '85px',
    width: 'auto',
    marginBottom: '70px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  resultHeaderBlock: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '26px',

    '&.error': {
      color: theme.palette.secondary.main
    },

    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.4',
    }
  },
  checkmarkBlock: {
    width: '45px',
    height: '45px',
    borderRadius: '25px',
    border: '2px solid #34c300',
    marginBottom: '31px',
    position: 'relative'
  },
  checkMark: {
    display: 'inline-block',
    position: 'absolute',
    top: '4px',
    left: '13px',
    height: '27px',
    width: '15px',
    borderRight: '2px solid #34c300',
    borderBottom: '2px solid #34c300',
    transform: 'rotate(45deg)'
  },
  resultText: {
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '32px',

    '&.error': {
      color: theme.palette.secondary.main
    },

    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.75',
    }
  },
  successBlock: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '80px',
    minHeight: '680px',
    borderRadius: '8px',
    border: 'solid 1px #ededed',

    [theme.breakpoints.down('sm')]: {
      padding: '69px 27px',
      justifyContent: 'flex-start',

      '& $approveButton': {
        height: '38px',
        borderRadius: '4px',
        fontsize: '16px'
      }
    }
  }
}))

const SelectedTripsData = ({ trips, suggestion, jwt, onCancel, compareState }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    loading: false,
    approveSent: false,
    error: false
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const approve = useCallback(async () => {
    setState({...state, loading: true})
    try {
      const rqData = {outboundTrip: trips?.outbound, returnTrip: trips?.return}
      if (!!trips?.seats) rqData.seats = {
        outbound: trips.seats?.outbound?.seats || null,
        return: trips.seats?.return?.seats || null,
      }
      const data = await editAndApproveRail(jwt, rqData)
      setState({...state, loading: false, approveSent: true, error: false})
    } catch (e) {
      setState({...state, loading: false, approveSent: true, error: true})
    }
  }, [state, jwt])

  const buildInfoLine = useCallback((title, value, key) => {
    return (
      <Box key={`trip-info-line-${key}`} className={classes.tripInfoLine}>
        <Box key="tripInfoLineHead" className={classes.tripInfoLineHead}>{title}</Box>
        <Box key="tripInfoLineValue" className={classes.tripInfoLineValue}>{value}</Box>
      </Box>
    )
  }, [state, classes])

  const nightTrainTexts = {
    'bed.sleeper.compartment': 'sleeper shared',
    'bed in sleepcomp': 'sleeper shared',
    'bed.couchette.compartment': 'couchette',
    'bed in couchettecomp': 'couchette',
    'prvt.sleep.comp.(wc/shower)': 'sleeper single',
    'sleepcomp wc/shower 1p': 'sleeper single'
  }

  const getBerthDescr = useCallback(txt => {
    const match = txt.match(/^\d{1}(\s|)[A-z]{2,}(\:\s|\s)(.+)$/i)
    const txtKey = !!match?.[3] ? _.trim(match[3].toLowerCase()) : ''
    return nightTrainTexts?.[txtKey] || null
  }, [])


  const buildSingleTrip = useCallback(trip => {
    const tripData = trips[trip]
    const currTripData = suggestion?.trip?.rail?.[trip]
    if (!tripData) return null
    const tripCl = tripData?.solution?.BCLocal
    let classText = ''
    let isSameSeats = true

    const newSeats = trips?.seats?.[trip]
    const newSeatTexts = []

    if (!!compareState && !!newSeats?.seats && typeof newSeats.seats === 'object') {
      (tripData?.segments || []).forEach(s => {
        const tmpDescr = currTripData?.solution?.bcDescriptions?.[s?.ID || ''] || ''
        const bcDescr = getBerthDescr(tmpDescr)
        const trainInd = s?.railidentifier || null

        isSameSeats = !!newSeats.isMatch
        const newTmpSeats = newSeats.seats

        for (let ukey in newTmpSeats) {
          const uSeats = newTmpSeats[ukey]?.seats
          if (!uSeats) continue
          const tmpSeat = uSeats[trainInd]

          if (tmpSeat.seatKey === 'berth') {
            newSeatTexts.push(`${t('train')} ${trainInd}, ${t(bcDescr || 'berth')}`)
          } else if (!!tmpSeat.seatPos) {
            newSeatTexts.push(`${t('train')} ${trainInd}, ${t(`seat pos ${tmpSeat.seatPos.value}`)}`)
          } else {
            newSeatTexts.push(`${t('train')} ${trainInd}, ${t('coach')} ${tmpSeat.coach}, ${t('seat')} ${tmpSeat.seatNum}`)
          }
        }
      })
    }

    const newSeatsText = newSeatTexts.length > 0 ? newSeatTexts.join('; ') : '-';
    const currSeatTexts = [];

    (currTripData?.segments || []).forEach(s => {
      const tmpDescr = currTripData?.solution?.bcDescriptions?.[s?.ID || ''] || ''
      const bcDescr = getBerthDescr(tmpDescr)
      const trainInd = s?.railidentifier || null

      suggestion.passengers.forEach(p => {
        const tmpSeats = p?.railSeats?.seats?.[trip] || null
        if (!tmpSeats) return false
        const tmpSeat = tmpSeats[trainInd]

        if (tmpSeat.seatKey === 'berth') {
          currSeatTexts.push(`${t('train')} ${trainInd}, ${t(bcDescr || 'berth')}`)
        } else if (!!tmpSeat.seatPos) {
          currSeatTexts.push(`${t('train')} ${trainInd}, ${t(`seat pos ${tmpSeat.seatPos.value}`)}`)
        } else {
          currSeatTexts.push(`${t('train')} ${trainInd}, ${t('coach')} ${tmpSeat.coach}, ${t('seat')} ${tmpSeat.seatNum}`)
        }
      })
    })

    const currSeatsText = currSeatTexts.length > 0 ? currSeatTexts.join('; ') : '-'

    const seatsRes = (
      <Fragment>
        {!isSameSeats && (
          <Fragment>
            <span className={`${classes.crossedText} ${classes.bold}`}>{currSeatsText}</span>{' '}
          </Fragment>
        )}
        <span className={`${!isSameSeats ? classes.changedText : '' }`}>{newSeatsText}</span>
      </Fragment>
    )

    const mainSegDescr = tripData?.solution?.bcDescriptions?.[tripData?.scheduleSolution?.mainSegment || ''] || ''
    const match = mainSegDescr.match(/^\d{1}(\s|)[A-z]{2,}(\:\s|\s)(.+)$/i)
    const txtKey = !!match?.[3] ? _.trim(match[3].toLowerCase()) : ''
    const nightTrainType = nightTrainTexts?.[txtKey]
    const flexCodes = ['002', '003', '004', '005', '006', '007']
    const flexText = flexCodes.includes(tripData?.solution?.flexibility) ? t(`flexibility_${tripData.solution.flexibility}`) : ''

    if (!!tripCl) {
      const clExplode = tripCl.split('_')
      const classNumText = clExplode?.[1] === 'cs' ? t('class calm') : t('class')
      const classNum = clExplode?.[0] === '001' ? '1' : '2'
      const currPrice = currTripData?.solution?.price?.Amount || '-'
      const newPrice = tripData?.solution?.price?.Amount || '-'

      const priceDiffer = currPrice !== newPrice && !!compareState
      const price = (
        <Fragment>
          {!!priceDiffer && (
            <Fragment>
              <span key={'class-price-text'} className={`${!!priceDiffer ? classes.crossedText : '' } ${classes.bold}`}>{currPrice} kr</span>{' '}
            </Fragment>
          )}
          <span className={`${!!priceDiffer ? classes.changedText : '' } ${classes.bold}`}>{newPrice} kr</span>
        </Fragment>
      )
      classText = (
        <Fragment>
          <span key={'class-text'}>{classNum} {classNumText}{flexText ? ` ${flexText}` : ''}{price ? ',' : '' } {price}</span>
          {!!nightTrainType && (
            <Fragment>
              <br/>
              <span key={'night-class-text'}>{t(nightTrainType)}</span>
            </Fragment>
          )}
        </Fragment>
      )
    }

    const header = trip === 'outbound' ? t('outbound') : t('inbound')
    const from = tripData?.scheduleSolution?.railstart?.locationName
    const to = tripData?.scheduleSolution?.railend?.locationName
    const passengers = (suggestion?.passengers || []).map(p => _.trim(`${p.firstName} ${p.lastName}`)).join(', ')

    const depDate = moment(tripData?.scheduleSolution?.railstart?.dateTime)
    const arrDate = moment(tripData?.scheduleSolution?.railend?.dateTime)

    const diffHrs = arrDate.diff(depDate, 'hours')
    const diffMin = arrDate.diff(depDate, 'minutes') - (diffHrs*60)
    const provider = `${tripData?.providerName} ${tripData?.hasFastTrain ? t('express train') : ''}`

    let changes = (tripData?.segments?.length || 0) - 1
    if (changes < 0) changes = 0
    const duration = `${diffHrs > 0 ? diffHrs +'h' : ''} ${diffMin > 0 ? diffMin +'m' : ''}, ${changes} ${t('change', {count: changes})}`

    const tripDate = (
      <span key="trip-date">
        {depDate.format('dddd D MMM')}
        <span key="trip-date-time" className={classes.bold}> {depDate.format('HH:mm')} - {arrDate.format('HH:mm')}</span>
      </span>
    )

    return (
      <Box key="trip-info-block" justifyContent={!!trips?.return ? 'flex-start' : 'center'} className={classes.tripInfoBlock}>
        <Box key="trip-info-block-inner" className={classes.tripInfoBlockInner}>
          <Box key="tripHead" textAlign={!!trips?.return ? 'left' : 'center'} className={classes.tripHead}>{header}</Box>
          {buildInfoLine(_.capitalize(t('from')), from, 'from')}
          {buildInfoLine(_.capitalize(t('search to')), to, 'to')}
          {buildInfoLine(header, tripDate, 'trip-date')}
          {buildInfoLine(t('duration'), duration, 'duration')}
          {buildInfoLine(t('ticket'), classText, 'ticket')}
          {buildInfoLine(t('operator'), provider, 'provider')}
          {buildInfoLine(t('traveller', {count: suggestion?.passengers?.length || 0}), passengers, 'traveller')}
          {!!newSeats?.seats ? buildInfoLine(t('seats'), seatsRes, 'seats') : null}
        </Box>
      </Box>
    )
  }, [state, classes, t, getBerthDescr])

  const currTotal = useMemo(() => {
    const oPrice = parseInt(suggestion?.trip?.rail?.outbound?.solution?.price?.Amount || 0)
    const rPrice = parseInt(suggestion?.trip?.rail?.return?.solution?.price?.Amount || 0)
    return oPrice + rPrice
  }, [suggestion])

  const total = useMemo(() => {
    const oPrice = parseInt(trips?.outbound?.solution?.price?.Amount || 0)
    const rPrice = parseInt(trips?.return?.solution?.price?.Amount || 0)
    return oPrice + rPrice
  }, [trips])

  const getConfirmBlock = useCallback(() => {
    const buttonsRow = (
      <Box key={'buttons-row'} className={classes.buttonsRow}>
        {!!compareState && (
          <Box key="more-button" onClick={onCancel} className={classes.moreButton}>
            {t('view more departures')}
          </Box>
        )}
        <Box key="approve-button" onClick={approve} className={classes.approveButton}>
          {t('approve suggest button')}
        </Box>
      </Box>
    )

    const totalBlock = total === currTotal || !compareState ? (
      <Box key="trip-total-block" className={classes.tripTotalBlock}>
        {t('detail total')} {total} kr
      </Box>
    ) : (
      <Box key="trip-total-block" className={classes.changedTotal}>
        <Box className={classes.priceTotalText}>{t('price changed')},</Box>{' '}
        <Box className={classes.priceTotalText}>{t('new total price')}: <span className={classes.bold}>{total} kr</span></Box>
      </Box>
    )

    return (
      <Fragment key="bottom-general">
        {totalBlock}
        <Box key="question-block" className={classes.bookQuestion}>
          {t('approve suggest question')}
        </Box>
        {buttonsRow}
        <Box key="cancel-link" className={classes.classLink} onClick={onCancel}> {t('cancel')} </Box>
      </Fragment>
    )
  }, [])

  const getTripsData = useCallback(() => {
    return (
      <Fragment key="trips-fragment">
        {total !== currTotal && !!compareState && isMobile && (
          <Box className={classes.topPriceBlock}>
            <Box key="trip-total-block" className={classes.changedTotal}>
              <Box className={classes.priceTotalText}>{t('price changed')},</Box>{' '}
              <Box className={classes.priceTotalText}>{t('new total price')}: <span className={classes.bold}>{total} kr</span></Box>
            </Box>
          </Box>
        )}
        <Grid key="tkt-trip-data-trips" container spacing={2}>
          <Grid key="outboundOuter" item xs={12} md={!!trips?.return ? 6 : 12} className={classes.outboundOuter}>
            {buildSingleTrip('outbound')}
          </Grid>
          {!!trips?.return && (
            <Grid key="returnOuter" item xs={12} md={6} className={classes.returnOuter}>
              {buildSingleTrip('return')}
            </Grid>
          )}
        </Grid>
        <Box key="trip-bottom-block" className={classes.tripBottomBlock}>
          {!state.loading ? ( getConfirmBlock() ) : (
            <CircularProgress key="loading-block"/>
          )}
        </Box>
      </Fragment>
    )
  },[classes, trips, state, t, isMobile])

  const getSuccess = useCallback(() => {
    return (
      <Box key="success-block" className={classes.successBlock}>
        <img src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
        <Box key="checkmark-block" className={classes.checkmarkBlock}>
          <Box className={classes.checkMark}/>
        </Box>
        <Box key="header-block" className={classes.resultHeaderBlock}>{t('suggestion approve header')}</Box>
        <Box key="text-block" className={classes.resultText}>{t('suggestion approve text')}</Box>
        <a key="approve-button" href="https://bigtravel.se" className={classes.approveButton}>
          {t('go to')} bigtravel.se
        </a>
      </Box>
    )
  },[classes, t])

  const getError = useCallback(() => {
    return (
      <Box key="success-block" className={classes.successBlock}>
        <img src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
        <Box key="header-block" className={`${classes.resultHeaderBlock} error`}>{t('trip edit failed')}</Box>
      </Box>
    )
  },[classes, t])

  return (
    <Container key="tkt-trip-data-root" maxWidth={'md'} disableGutters className={classes.root}>
      {!state.approveSent && getTripsData()}
      {!state.loading && !!state.approveSent && !state.error && getSuccess()}
      {!state.loading && !!state.approveSent && !!state.error && getError()}
    </Container>
  )
}

export default SelectedTripsData
