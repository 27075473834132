import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import {
  IconButton,
  Button,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Container,
} from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import UsersSearch from '../../components/reusable/usersSearch'
import ReusableDatpicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// Hotel
import AutocompleteInutHotel from '../../components/reusable/autoCompleteInputHotel'
import { ReactComponent as Arrows } from '../../assets/images/Arrows-WHT.svg'
import SelectCounts from '../../components/reusable/selectCounts'
import { useSelector } from 'react-redux'
import { Lock } from '@material-ui/icons'
import isIframed from '../../utils/isIframed'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  changeBtnTrain: {
    position: 'absolute',
    left: '145px',
    top: '17px',
    background: 'white',
    padding: 0,
    zIndex: 1,
  },
  changeBtn: {
    position: 'absolute',
    left: '255px',
    top: '17px',
    background: 'white',
    padding: 0,
    zIndex: 1,
  },
  align: {
    display: 'flex',
    alignItems: 'stretch',
  },
  searchBtn: {
    width: '100%',
    fontWeight: 600,
    borderRadius: `0 8px 8px 0`,
    minHeight: '100%',
    padding: 0,
    margin: 0,
    fontSize: '16px',
    transform: 'scaleY(1.04)',
  },
  lowercase: {
    textTransform: 'capitalize',
  },
  border: {
    height: '100%',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  addTravelerBtn: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
  },
  borderRight: {
    borderRight: `1px dotted ${theme.palette.border}`,
    height: '100%',
  },

  head: {
    top: '72px',
    zIndex: theme.zIndex.drawer - 1,
    boxShadow: '0px 20px 15px -20px rgba(0, 0, 0, .15)',
  },
  arrows: {
    height: '18px',
    width: '18px',
  },
  dateBlock: ({ isAuthenticated }) => ({
    ...(isAuthenticated
      ? {}
      : {
          minWidth: '160px',
          maxWidth: '160px',
        }),
  }),
  buttonBlock: ({ isAuthenticated }) => ({
    ...(isAuthenticated
      ? {
          minWidth: '130px',
          maxWidth: '130px',
        }
      : {
          minWidth: '166px',
          maxWidth: '166px',
        }),
  }),
  usersContainer: {
    maxWidth: '345px',
  },
  toolbar: {
    margin: 0,
  },

  lockIcon: {
    height: '100%',
    color: theme.palette.primary.main,
  },
}))

const SearchBar = ({
  /* */
  entityType = null,
  from,
  setFrom,
  to,
  setTo,
  /**/
  departure,
  setDeparture,
  arrival,
  setArrival,
  /* */
  disableUsersSelect,
  travellers,
  setTravellers,
  switchDestinations,
  isOneWay,
  onSubmit,
  /* */
  getCities,
  optionLabel,
  array,
  /**/
  HOTEL,
  setHotel,
  renderOption,
  counts,
  modifyCounts,
  /**/
  setTicketClass,
  ticketClass,
  ticketClasses,
  TRAIN,
  earliestDate,
  latestDate,
  isToLocked,
  extendCheckout,
}) => {
  const { isAuthenticated, user, authType, banner } = useSelector(
    (state) => state.auth
  )
  const classes = useStyles({ isAuthenticated })
  const { i18n, t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isBannerActive = () => {
    const lang = i18n.language?.split('-')[0].toLowerCase()

    return banner?.[lang]
  }

  const getOffsetTop = () => {
    let top = isIframed ? 74 : 78
    if (
      !!user?.isBookingLink ||
      (['agent', 'viaAgent'].includes(authType) && !extendCheckout)
    )
      top += 49
    if (isBannerActive()) top += 49
    return top
  }

  const offsetTop = getOffsetTop()

  // If mobile, reuturn null
  if (isMobile) {
    return null
  }

  const buildCounts = () => {
    return HOTEL || !isAuthenticated ? (
      <Grid item xs={12} sm>
        <Box
          style={{
            height: '100%',
            borderLeft: '1px solid #e6e6e6',
            display: 'flex',
            justifyContent: 'stretch',
            alignItems: 'stretch',
          }}
        >
          <SelectCounts
            isHotel={!!HOTEL}
            counts={counts}
            modifyCounts={modifyCounts}
            setTicketClass={setTicketClass}
            ticketClass={ticketClass}
            ticketClasses={ticketClasses}
            menuOffset={'12px'}
            hideHeader={true}
            zIndex={1203}
          />
        </Box>
      </Grid>
    ) : null
  }

  return (
    <AppBar
      color="inherit"
      position="sticky"
      style={{ top: offsetTop + 'px' }}
      elevation={0}
      className={classes.head}
    >
      <Container maxWidth="lg" disableGutters>
        <Toolbar className={classes.toolbar} disableGutters>
          <Box
            className={classes.container}
            mt={1}
            mb={1}
            style={{ width: '100%', flexWrap: 'unset' }}
          >
            <Grid container style={{ width: '100%' }}>
              <Grid item xs>
                <Grid
                  container
                  style={{
                    border: '1px solid #e6e6e6',
                    borderRadius: '8px',
                    positon: 'relative',
                  }}
                >
                  {HOTEL ? (
                    <Grid item xs={3} sm={3}>
                      <Box pl={1} height="100%">
                        <AutocompleteInutHotel
                          renderOption={renderOption}
                          value={from}
                          onChange={setHotel}
                          array={array}
                          fetch={getCities}
                          optionLabel={optionLabel}
                          label={t('destination')}
                          icon={
                            <LocationCityIcon
                              color="primary"
                              fontSize="small"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={TRAIN ? 3 : HOTEL ? 4 : 5}>
                      <Grid container style={{ height: '100%' }}>
                        <Grid item xs sm={6} style={{ position: 'relative' }}>
                          <Box
                            className={classes.border}
                            style={{
                              positon: 'relative',
                            }}
                          >
                            {!isToLocked && (
                              <IconButton
                                className={
                                  TRAIN
                                    ? classes.changeBtnTrain
                                    : classes.changeBtn
                                }
                                onClick={switchDestinations}
                                color="primary"
                              >
                                <SwapHorizIcon />
                              </IconButton>
                            )}

                            <Box pl={2} className={classes.borderRight}>
                              <AutoCompleteInput
                                fetch={getCities}
                                value={from}
                                onChange={(_, value) => setFrom(value)}
                                array={array}
                                optionLabel={optionLabel}
                                label={t('search from')}
                                placeholder={t('search from')}
                                renderOption={renderOption}
                                isFrom={true}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box pl={2} className={classes.border}>
                            <AutoCompleteInput
                              renderOption={renderOption}
                              fetch={getCities}
                              value={to}
                              onChange={(_, value) => setTo(value)}
                              array={array}
                              optionLabel={optionLabel}
                              label={t('search to')}
                              placeholder={t('search to')}
                              isTo={true}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} sm>
                    <Grid
                      container
                      style={{ height: '100%', flexWrap: 'unset' }}
                    >
                      <Grid
                        className={classes.dateBlock}
                        item
                        xs={isAuthenticated ? 12 : true}
                        sm={!!isAuthenticated}
                      >
                        <Box
                          p={1}
                          pt={0}
                          display="flex"
                          style={{
                            borderLeft: '1px solid #e6e6e6',
                            height: '100%',
                          }}
                        >
                          <ReusableDatpicker
                            disableLabel
                            value={departure}
                            onChange={setDeparture}
                            from={departure}
                            to={arrival}
                            isOutgoing
                            back
                            minDate={earliestDate}
                            maxDate={latestDate}
                          />
                        </Box>
                      </Grid>
                      {TRAIN && (
                        <Grid
                          className={classes.dateBlock}
                          item
                          xs={isAuthenticated ? 12 : true}
                          sm={!!isAuthenticated}
                        >
                          <Box
                            p={1}
                            pt={0}
                            display="flex"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <ReusableDatpicker
                              disableLabel
                              value={departure}
                              onChange={setDeparture}
                              from={departure}
                              to={arrival}
                              isOutgoing
                              back
                              timeOnly
                              minDate={earliestDate}
                              maxDate={latestDate}
                            />
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        className={classes.dateBlock}
                        item
                        xs={isAuthenticated ? 12 : true}
                        sm={!!isAuthenticated}
                      >
                        <Box
                          p={1.5}
                          pt={0.5}
                          display="flex"
                          style={{
                            height: '100%',
                            borderLeft: '1px dotted #e6e6e6',
                          }}
                          pr={TRAIN && 0}
                        >
                          <ReusableDatpicker
                            disableLabel
                            value={arrival}
                            onChange={setArrival}
                            isOneWay={isOneWay}
                            HOTEL={HOTEL}
                            from={departure}
                            to={arrival}
                            minDate={earliestDate}
                            maxDate={latestDate}
                          />
                        </Box>
                      </Grid>
                      {TRAIN && (
                        <Grid
                          className={classes.dateBlock}
                          item
                          xs={isAuthenticated ? 12 : true}
                          sm={!!isAuthenticated}
                        >
                          <Box
                            p={1}
                            pt={0}
                            display="flex"
                            style={{
                              borderLeft: '1px solid #e6e6e6',
                              height: '100%',
                            }}
                          >
                            <ReusableDatpicker
                              disableLabel
                              value={arrival}
                              onChange={setArrival}
                              from={departure}
                              to={arrival}
                              isOutgoing
                              back
                              timeOnly
                              minDate={earliestDate}
                              maxDate={latestDate}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isAuthenticated && (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className={classes.usersContainer}
                    >
                      <Box
                        pl={1}
                        style={{
                          height: '100%',
                          borderLeft: '1px solid #e6e6e6',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <UsersSearch
                          entityType={entityType}
                          value={travellers}
                          setValue={setTravellers}
                          disabled={!!disableUsersSelect}
                          noLabel
                          withUserModal={true}
                          userShadow={false}
                          isMinimized
                          setTicketClass={setTicketClass}
                          ticketClass={ticketClass}
                          ticketClasses={ticketClasses}
                        />
                      </Box>
                    </Grid>
                  )}
                  {buildCounts()}

                  <Grid
                    className={classes.buttonBlock}
                    item
                    xs={isAuthenticated ? 12 : true}
                    sm={!!isAuthenticated}
                  >
                    <Button
                      disableRipple
                      disableElevation
                      onClick={onSubmit}
                      variant="contained"
                      color="primary"
                      className={classes.searchBtn}
                      endIcon={
                        document.dir === 'rtl' ? null : (
                          <Arrows className={classes.arrows} />
                        )
                      }
                      startIcon={
                        document.dir === 'rtl' ? (
                          <Arrows className={classes.arrows} />
                        ) : null
                      }
                    >
                      {t('search go')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default memo(SearchBar)

//search go
