import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import numberWithCommas from '../../utils/numberWithCommas'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { fetchTarifInfo } from '../../repositories/cars'
import SingleCarsDataBlock from '../../containers/cars/singleCarsDataBlock'
import ExtrasModal from '../../containers/cars/extrasModal'
import { Button, CircularProgress, Hidden, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import ChoosenTripCard from '../../components/cars/choosenTripCard'
import TotalPriceBlock from '../../components/cars/totalPriceBlock'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { addToCheckoutAction } from '../../store/checkout/checkoutActions'
import InsuranceBlock from '../../components/cars/insuranceBlock'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid transparent',
  },
  freeCancelRow: {
    backgroundColor: theme.palette.lightGreen,
    borderRadius: '7px',
    border: `1px solid ${theme.palette.policyTextGreen}`,
    padding: '15px 11px',
    marginBottom: '24px',
  },
  mainBlock: {
    marginTop: '35px',
    marginBottom: '40px',
  },
  pageHeader: {
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '24px',
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.border}`,
    marginBottom: '19px',
  },
  subHeaderBlock: {
    fontSize: '24px',
    paddingBottom: '20px',
    borderBottom: `1px solid ${theme.palette.border}`,
    marginBottom: '28px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,

    '&.noBorder': {
      paddingBottom: 0,
      borderBottom: 0,
      marginBottom: '21px',
    },
  },
  divider: {
    height: '1px',
    backgroundColor: theme.palette.border,
  },
  infoRow: {
    display: 'flex',
    marginBottom: '18px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  infoRowText: {
    flex: 1,
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.black,
    lineHeight: 'normal',
    paddingLeft: '9px',
    display: 'flex',
    alignItems: 'center',
  },
  infoRowIcon: {
    width: '22px',
    lineHeight: '7px',
    display: 'flex',
    alignItems: 'center',
  },
  extraText: {
    fontSize: '16px',
    color: theme.palette.common.black,
    marginBottom: '21px',
  },
  extrasBtn: {
    color: '#007e7e',
    backgroundColor: '#ceebea',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: 'none',
    padding: '8px 60px',
    marginBottom: '62px',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#ceebea',
    },
  },
  selectedTrip: {
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
  },
  detailsBock: {
    borderRadius: '12px',
    padding: '31px',
  },
  fixedDiv: {
    position: 'sticky',
    top: 100,
  },
  link: {
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    textTransform: 'none',
    left: 0,
    position: 'absolute',
  },
  continueBtn: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
    letterSpacing: '1.1px',
    padding: '23px 60px',
  },
  btnContainer: {
    marginBottom: theme.spacing(6),
    marginTop: '25px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const CarDetails = () => {
  const classes = useStyles()
  const { selectedCar, selectedExtras, addedAddOns } = useSelector(
    (state) => state.cars
  )
  const searchState = useSelector((state) => state.search)
  const { travellers, from, to } = searchState.cars
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const [state, setState] = useState({
    fetchStatus: null,
    tariffInfo: null,
  })

  const isFreeCancellation =
    !selectedCar?.guaranteeRequired || selectedCar.guaranteeRequired === 'iata'
  const [isExtrasOpen, setIsExtrasOpen] = useState()
  const { user, authType } = useSelector((state) => state.auth)
  const authTypes = ['agent', 'viaAgent']
  const isTopRow = !!user?.isBookingLink || authTypes.includes(authType)

  const goBack = () => history.goBack()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = currency === 'EUR' ? 'eur' : 'kr'

  const fetchTariffInfo = useCallback(async () => {
    setState({ ...state, fetchStatus: 'loading' })
    const tariffData = await fetchTarifInfo({ rate: selectedCar })
    setState({ ...state, tariffInfo: tariffData, fetchStatus: 'fetched' })
  }, [setState, state, selectedCar])

  const dataForCheckout = useMemo(() => {
    const dest = from || to
    let total = selectedCar?.estimatedTotal || 0
    const extras = selectedExtras?.length ? selectedExtras : []

    extras.forEach((e) => (total += Number(e?.price) || 0))
    addedAddOns?.forEach((addOn) => (total += Number(addOn?.price) || 0))

    return {
      type: 'Car',
      city: [dest.description, dest.description],
      passengers: travellers || [],
      totalPrice: total,
      services: [],
      tarif: { ...selectedCar },
      chargeCodes: selectedCar?.chargeCodes || [],
      extras: [...extras],
      addedAddOns: [...addedAddOns],
      co2: selectedCar.co2,
    }
  }, [selectedCar, travellers, from, to, selectedExtras, addedAddOns])

  const goForwardAndSetCheckout = async () => {
    dispatch(addToCheckoutAction(dataForCheckout))
    return history.push('/checkout')
  }

  const inclTexts = useMemo(() => {
    const textsByCovType = {
      TPW: 'car tpw',
      CDW: 'car cdw',
      THW: 'car thw',
      TP: 'car tp',
      TPI: 'car tpi',
      LDW: 'car ldw',
    }

    const insuranceTextsByCovType = {
      TPW: 'car tpw',
      CDW: 'insurance cdw',
      THW: 'car thw',
      TP: 'car tp',
      TPI: 'car tpi',
      LDW: 'insurance ldw',
    }

    const chargeTexts = {
      OWC: 'one way charge included',
      OWC_price: 'one way charge included price',
    }

    const result = []
    const insuranceTexts = []

    if (isFreeCancellation) result.push(t('free cancellation'))
    if (selectedCar?.vehicle?.freeMileage === 'UNL')
      result.push(t('unlimited mileage'))

    let covAmount = 0
    if (state.tariffInfo?.insurance?.incl?.length > 0) {
      state.tariffInfo.insurance.incl.forEach((ins) => {
        const covType = ins?.covType || ''
        if (ins?.covAmount) covAmount += ins.covAmount
        if (textsByCovType?.[covType]) {
          let tmpText = t(textsByCovType?.[covType])
          if (ins?.covAmount)
            tmpText = `${tmpText} ${t('cov excess', {
              amount: numberWithCommas(ins.covAmount),
              currencyCode,
            })}`
          result.push(tmpText)
        }

        if (insuranceTextsByCovType?.[covType]) {
          let insuranceTmpText = t(insuranceTextsByCovType?.[covType])
          if (ins?.covAmount)
            insuranceTmpText = `${insuranceTmpText} ${t('insurance excess', {
              amount: numberWithCommas(ins.covAmount),
              currencyCode,
            })}`
          insuranceTexts.push(insuranceTmpText)
        }
      })
    }

    if (selectedCar?.chargeCodes?.length) {
      selectedCar.chargeCodes.forEach((ch) => {
        const price = !!ch?.amount
          ? numberWithCommas(Math.round(parseFloat(ch?.amount)))
          : null
        const priceKey = !!price ? ch.code + '_price' : null
        const tData = { amount: price, currency: currencyCode }

        if (!!priceKey && !!chargeTexts?.[priceKey]) {
          result.push(t(chargeTexts[priceKey], tData))
        } else if (!!chargeTexts?.[ch.code]) {
          result.push(t(chargeTexts[ch.code], tData))
        }
      })
    }
    return { texts: result, covAmount, insuranceTexts }
  }, [selectedCar, t, state.tariffInfo, isFreeCancellation, currencyCode])

  useEffect(() => {
    if (!state.fetchStatus) fetchTariffInfo()
  }, [state.fetchStatus, fetchTariffInfo])

  return (
    <Box className={classes.root}>
      {isMobile ? (
        <MobileHeader tripPrice={dataForCheckout.totalPrice} />
      ) : (
        <DesktopDetailsHeader tripPrice={dataForCheckout.totalPrice} />
      )}
      <Container className={classes.mainBlock} maxWidth="lg" disableGutters>
        <Grid item xs style={{ padding: theme.spacing(0, isMobile ? 1 : 0) }}>
          <Grid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={isMobile ? 2 : 6}
          >
            <Grid item xs={12} md={7} sm={12}>
              <Paper elevation={0} className={classes.detailsBock}>
                <Box className={classes.pageHeader}>{t('your deal')}</Box>
                {isFreeCancellation && (
                  <Box className={classes.freeCancelRow}>
                    <Box className={classes.infoRow}>
                      <Box className={classes.infoRowIcon}>
                        <CheckIcon />
                      </Box>
                      <Box className={classes.infoRowText}>
                        {t('free cancellation day before')}
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box mb={'28px'}>
                  <SingleCarsDataBlock car={selectedCar} />
                </Box>
                <Box mb={'30px'} mx={'-31px'} className={classes.divider} />
                {state.fetchStatus !== 'fetched' ? (
                  <CircularProgress />
                ) : (
                  <Fragment>
                    <Box className={classes.subHeaderBlock}>
                      {t('included in the price')}
                    </Box>
                    <Box mb={'50px'}>
                      {inclTexts.texts.map((it) => {
                        return (
                          <Box className={classes.infoRow}>
                            <Box className={classes.infoRowIcon}>
                              <CheckIcon />
                            </Box>
                            <Box className={classes.infoRowText}>{it}</Box>
                          </Box>
                        )
                      })}
                    </Box>
                    <Box mb={'30px'} mx={'-31px'} className={classes.divider} />
                    <Box className={`${classes.subHeaderBlock} noBorder`}>
                      {t('extras')}
                    </Box>
                    <Box className={classes.extraText}>
                      {t('cov extra text')}
                    </Box>
                    <Button
                      onClick={() => setIsExtrasOpen(true)}
                      className={classes.extrasBtn}
                      variant="contained"
                    >
                      {t('add extras')}
                    </Button>
                    <Box mb={'30px'} mx={'-31px'} className={classes.divider} />
                    <InsuranceBlock
                      tariffInfo={state.tariffInfo}
                      inclTexts={inclTexts}
                      currency={currency}
                      addedAddOns={addedAddOns}
                    />
                  </Fragment>
                )}
              </Paper>
              <Box
                className={classes.btnContainer}
                mb={7}
                mt={isMobile ? 3 : 0}
              >
                <Hidden smDown>
                  <Button
                    onClick={goBack}
                    className={classes.link}
                    startIcon={<ArrowBackIcon color="primary" />}
                  >
                    {t('back')}
                  </Button>
                </Hidden>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 50 }}
                  className={classes.continueBtn}
                  onClick={goForwardAndSetCheckout}
                >
                  {t('detail btn')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sm={12}>
              <Box
                style={{ top: !!isTopRow ? '150px' : '100px' }}
                className={classes.fixedDiv}
              >
                <Paper
                  style={{ marginBottom: '16px' }}
                  elevation={0}
                  className={classes.selectedTrip}
                >
                  <ChoosenTripCard car={dataForCheckout} />
                </Paper>
                <Paper elevation={0} className={classes.selectedTrip}>
                  <TotalPriceBlock
                    data={dataForCheckout}
                    addedAddons={addedAddOns}
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {state.fetchStatus === 'fetched' && (
        <ExtrasModal
          onClose={() => setIsExtrasOpen(false)}
          isOpen={isExtrasOpen}
          extras={state.tariffInfo?.extras || []}
        />
      )}
    </Box>
  )
}

export default CarDetails
