import React, { memo } from 'react'
import ModalFlightSeatReservation from '../flights/details/modalContent/modalFlightSeatReservation'
import ModalHook from './reusable/modalHook'

const SeatReservationModal = ({ modalBoolean }) => {
  return (
    <ModalHook modalBoolean={modalBoolean}>
      <ModalFlightSeatReservation />
    </ModalHook>
  )
}

export default memo(SeatReservationModal)
