import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {checkSuggestion} from '../../repositories/suggestions'
import {makeStyles} from '@material-ui/core/styles'
import { getBrandedUrl } from '../../utils/branding'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import SelectedTripsData from '../../components/automateBooking/SelectedTripsData'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9'
  },

  header: {
    display: 'none',
    position: 'sticky',
    top: 0,
    zIndex: '2',
    backgroundColor: 'white',
    height: '65px',
    width: '100%',
    borderBottom: 'solid 1px #ededed',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
  },

  headLogoImage: {
    height: '52px',
    width: 'auto'
  },

  contentRoot: {
    display: 'flex',
    flex: 1,
    padding: '25px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width:'100%'
  },

  progress: {
    marginBottom: '50px'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    minHeight: '680px',
    padding: '80px',
    borderRadius: '8px',
    border: 'solid 1px #ededed',

    [theme.breakpoints.down('sm')]: {
      padding: '69px 27px',
      justifyContent: 'flex-start',
    }
  },
  logoImage: {
    height: '85px',
    width: 'auto',
    marginBottom: '70px',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none'
  },
  headerBlock: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '26px',

    '&.error': {
      color: theme.palette.secondary.main
    }
  },
  checkmarkBlock: {
    width: '45px',
    height: '45px',
    borderRadius: '25px',
    border: '2px solid #34c300',
    marginBottom: '31px',
    position: 'relative'
  },
  checkMark: {
    display: 'inline-block',
    position: 'absolute',
    top: '4px',
    left: '13px',
    height: '27px',
    width: '15px',
    borderRight: '2px solid #34c300',
    borderBottom: '2px solid #34c300',
    transform: 'rotate(45deg)'
  },
  text: {
    fontSize: '20px',
    lineHeight: 'normal',
    color: theme.palette.common.black,
    textAlign: 'center',

    '&.error': {
      color: theme.palette.secondary.main
    }
  },
}))
const errorTexts = {
  1100: { header: 'suggestion approve failed' },
}

const EmailBookingApprove = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    approveData: null,
    error: false,
  })
  let { jwt } = useParams()

  const goToSelect = () => {
    history.push(`/automate-booking/choose-trip/${jwt}`);
  }

  const approve = useCallback(async () => {
    setState({...state, isSent: true, loading: true})
    const resultState = {...state, isSent: true, loading: false}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const rsData = await checkSuggestion(jwt)
      if (rsData?.status !== 'confirm' && rsData?.status !== 'booking') throw Error('activation failed')
      resultState.error = null
      resultState.approveData = rsData
      setState(resultState)
      return true
    } catch (e) {
      const errCode = e?.response?.data?.code || 1100
      resultState.error = !!errorTexts?.[errCode] ? {...errorTexts[errCode]} : {...errorTexts[1100]}
      setState(resultState)
      return false
    }
  }, [history, dispatch, t, state, jwt])

  const approveStatus = useMemo(() => {
    const statuses = ['booking', 'confirm']
    const resStatus = state.approveData?.status || 'unknown'
    if (!!state.loading) return 'loading'
    if (!!state.error || !statuses.includes(resStatus)) return 'error'
    return resStatus
  }, [state])

  const buildMainContent = useCallback(() => {

    if (approveStatus === 'loading') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <img key={'logo-image'} src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
          <CircularProgress className={classes.progress}/>
          <div key="header-block" className={classes.headerBlock}>{t('checking suggestion head text')}</div>
          <div key="text-block" className={classes.text}>{t('checking suggestion text')}</div>
        </Container>
      )
    }

    if (approveStatus === 'error') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <img key={'logo-image'} src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
          <div key="header-block" className={`${classes.headerBlock} error`}>{t(state.error.header)}</div>
        </Container>
      )
    }

    if (approveStatus === 'booking') {
      return (
        <Container key={'approving-container'} maxWidth="md" disableGutters className={classes.container}>
          <img key={'logo-image'} src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
          <div key="checkmark-block" className={classes.checkmarkBlock}>
            <div className={classes.checkMark}/>
          </div>
          <div key="header-block" className={classes.headerBlock}>{t('suggestion approve header')}</div>
          <div key="text-block" className={classes.text}>{t('suggestion approve text')}</div>
        </Container>
      )
    }

    if (approveStatus === 'confirm') {
      const trips = {
        outbound: state.approveData.outboundTrip,
        return: state.approveData.returnTrip,
        seats: state.approveData.seats,
      }
      return (
        <SelectedTripsData compareState={true} trips={trips} suggestion={state.approveData.suggestion} jwt={jwt} onCancel={goToSelect}/>
      )
    }
  }, [approveStatus, classes, jwt])

  useEffect(() => {
    if (!state.isSent) approve()
  }, [state, approve])

  return (
    <Box className={classes.root}>
      <Box key={'header'} className={classes.header}>
        <img src={getBrandedUrl('logo.jpg')} className={classes.headLogoImage}/>
      </Box>
      <Box key={'contentRoot'} className={classes.contentRoot}>
        {buildMainContent()}
      </Box>
    </Box>
  )
}

export default EmailBookingApprove
