import React, {useCallback, useEffect, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { fetchSuggestion } from '../../repositories/suggestions'
import {makeStyles } from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Container from '@material-ui/core/Container'
import AutomateBookingRailTrip from '../../components/automateBooking/AutomateBookingRailTrip'
import SelectedTripsData from '../../components/automateBooking/SelectedTripsData'
import moment from 'moment/moment'
import {getBrandedUrl} from '../../utils/branding'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9'
  },

  header: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: '2',
    backgroundColor: 'white',
    height: '65px',
    width: '100%',
    borderBottom: 'solid 1px #ededed',
    justifyContent: 'center',
    alignItems: 'center'
  },

  logoImage: {
    height: '52px',
    width: 'auto',

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  contentRoot: {
    display: 'flex',
    flex: 1,
    padding: '25px 10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width:'100%'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    minHeight: '680px',
    padding: '25px 50px',
    borderRadius: '8px',
    border: 'solid 1px #ededed',

    [theme.breakpoints.up('slg')]: {
      maxWidth: '1082px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '18px 0'
    },
  },

  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    color: theme.palette.secondary.main,
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold
  },

  loadingBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1'
  }
}))

const EmailBookingChooseTrip = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [state, setState] = useState({
    isSent: false,
    loading: true,
    error: false,
    suggestion: null,
    trips: null,
  })
  let { jwt } = useParams()

  const getCurrentTicket = useCallback(async () => {
    setState({...state, isSent: true, loading: true})
    const resultState = {...state, isSent: true, loading: false}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const data = await fetchSuggestion(jwt)
      if (!data?.suggestion) throw new Error('no ticket found')
      resultState.suggestion = {...data.suggestion}
      resultState.error = null
      setState(resultState)
      return true
    } catch (e) {
      resultState.error = 'no ticket found'
      resultState.suggestion = null
      setState(resultState)
      return false
    }
  }, [state, jwt])

  const selectTrip = useCallback( trips => {
    const retDate = moment(state.suggestion?.parsedSourceData?.returnDate, 'YYYY-MM-DD HH:mm')

    if (!!trips?.outbound && (!!trips?.return || !retDate.isValid())) {
      setState({...state, trips: {...trips}})
    }
  }, [state])

  const onCancel = useCallback( () => {
    setState({...state, trips: null})
  }, [state])

  const isError = !state.loading && !!state.error
  const tripIsSelected = !isError && !!state.trips
  const isTripsList = !isError && !tripIsSelected && !!state.suggestion


  useEffect(() => {
    if (!state.isSent) getCurrentTicket()
  }, [state, getCurrentTicket])

  const renderLoading = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <Box key="loading-block" className={classes.loadingBlock}>
        <CircularProgress/>
      </Box>
    </Container>
  ), [classes])

  const renderError = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <Box className={classes.errorBlock}>
        {t('suggestion not found')}
      </Box>
    </Container>
  ), [classes])

  const renderTrips = useCallback( () => (
    <Container disableGutters className={classes.container}>
      <AutomateBookingRailTrip selectTrip={selectTrip} suggestion={state.suggestion} jwt={jwt}/>
    </Container>
  ), [state, jwt, selectTrip, classes])

  const renderTripsData = useCallback( () => (
    <SelectedTripsData suggestion={state.suggestion} trips={state.trips} jwt={jwt} onCancel={onCancel}/>
  ), [state, classes, jwt])

  return (
    <Box key={'root'} className={classes.root}>
      <Box key={'header'} className={classes.header}>
        <img src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
      </Box>
      <Box key={'contentRoot'} className={classes.contentRoot}>
        {!!state.loading && renderLoading()}
        {!!isError && renderError()}
        {!!isTripsList && renderTrips()}
        {!!tripIsSelected && renderTripsData()}
      </Box>
    </Box>
  )
}

export default EmailBookingChooseTrip
