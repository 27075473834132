import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function useEnabledBookingTypesHook() {
  const { isAuthenticated, disabled, disabledPublic, user } = useSelector(
    (state) => state.auth
  )

  const { hideTrain } = useSelector((state) => state.other)
  const isCurrentlyEnabled = (type) => {
    // Special case for trains
    if (hideTrain == 1 && type === 'trains') return false
    if (hideTrain == 0 && type === 'trains') return true

    // Authenticated and disabled
    if (isAuthenticated && disabled?.[type]) return false

    // Booking links
    if (user?.bookinglink) {
      if (!user?.bookinglink?.allowed[type]) return false
    }

    // Public - agency setting not used - default train and card disabled
    if (
      !isAuthenticated &&
      !disabledPublic &&
      ['trains', 'cars'].includes(type)
    )
      return false

    // Public - agency setting
    if (!isAuthenticated && disabledPublic?.[type]) return false

    // Transfer - needs customerId
    if (type === 'transfer' && !user?.customerId) return false

    // Default - enabled
    return true
  }

  const [isFlightsEnabled, setIsFlightsEnabled] = useState(
    isCurrentlyEnabled('flights')
  )
  const [isTrainsEnabled, setIsTrainsEnabled] = useState(
    isCurrentlyEnabled('trains')
  )
  const [isCarsEnabled, setIsCarsEnabled] = useState(isCurrentlyEnabled('cars'))
  const [isHotelsEnabled, setIsHotelsEnabled] = useState(
    isCurrentlyEnabled('hotels')
  )
  const [isTransfersEnabled, setIsTransfersEnabled] = useState(
    isCurrentlyEnabled('transfer')
  )

  useEffect(() => {
    setIsFlightsEnabled(isCurrentlyEnabled('flights'))
    setIsTrainsEnabled(isCurrentlyEnabled('trains'))
    setIsHotelsEnabled(isCurrentlyEnabled('hotels'))
    setIsCarsEnabled(isCurrentlyEnabled('cars'))
    setIsTransfersEnabled(isCurrentlyEnabled('transfer'))
  }, [user, isAuthenticated, disabled, disabledPublic])

  return {
    isFlightsEnabled,
    isCarsEnabled,
    isHotelsEnabled,
    isTrainsEnabled,
    isTransfersEnabled,
  }
}

export default useEnabledBookingTypesHook
