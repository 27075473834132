import React, { memo } from 'react'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { withStyles } from '@material-ui/core/styles'
import MinimizedCard from './minimizedCard'
import MaximiedCard from './maximizedCard'

const Accordion = withStyles({
  root: {
    border: '0',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    expanded: {
      margin: 'auto',
    },
  },
})(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderBottom: 0,
    marginBottom: -1,
    minHeight: 0,
    padding: 0,
    '&expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: '22px 0 12px 0',
    '&.Mui-expanded': {
      margin: '22px 0 12px 0',
    },

    [theme.breakpoints.down('sm')]: {
      margin: '32px 0 12px 0',
      '&.Mui-expanded': {
        margin: '32px 0 12px 0',
      },
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails)

const FlightItem = ({
  flight,
  expanded,
  handleChange,
  index,
  minimumPrice,
}) => {
  return (
    <>
      <Accordion expanded={expanded === index} onChange={handleChange(index)}>
        <AccordionSummary>
          <MinimizedCard
            flight={flight}
            minimumPrice={minimumPrice}
            expanded={expanded === index}
          />
        </AccordionSummary>
        <AccordionDetails>
          <MaximiedCard flight={flight} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default memo(FlightItem)
