import React, { memo } from 'react'
import { Box } from '@material-ui/core'
import FlightTimeline from './flightTimeline'

const MaximizedCard = ({ flight }) => {
  return (
    <Box>
      <FlightTimeline flight={[flight]} />
    </Box>
  )
}

export default memo(MaximizedCard)
