import React, { memo, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ViewHandler from '../../containers/checkout/viewHandler'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, Container, Divider, Button } from '@material-ui/core'
import { Check as CheckIcon, Phone as PhoneIcon } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import Travelers from '../../containers/checkout/details/travelers'
import { useHistory } from 'react-router-dom'
import { setBookingData } from '../../store/checkout/checkoutActions'
import Total from '../../containers/checkout/details/total'
import isIframed from '../../utils/isIframed'

const useStyles = makeStyles((theme) => ({
  outerBlock: {
    fontSize: '16px',
    color: theme.palette.common.black,
  },
  bold: {
    fontWeight: 'bold',
  },
  codes: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
}))

const BookingRefData = ({ bookingResult }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const codes = []
  const extDataKeys = ['extData', 'flightBookingInformation']
  for (let ind in bookingResult) {
    if (
      !bookingResult[ind] ||
      extDataKeys.includes(ind) ||
      !bookingResult[ind].length
    )
      continue
    const tripTxt = ind === 'car' ? 'rental car' : ind
    let tmpText = `${t('confirm reference')} (${t(tripTxt)})`
    if (ind === 'railRef') {
      tmpText = t('book data rail ref')
    }
    codes.push(
      <Box className={classes.codes}>
        <span>
          {tmpText}{' '}
          <span className={classes.bold}>{bookingResult[ind].join(', ')}</span>
        </span>
      </Box>
    )
  }

  return <Box className={`${classes.outerBlock}`}>{codes}</Box>
}

export default memo(BookingRefData)
