import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import Picker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { makeStyles } from '@material-ui/core/styles'
import enGB from 'date-fns/locale/en-GB'

import { Box, Typography, IconButton, Button } from '@material-ui/core'

import EventIcon from '@material-ui/icons/Event'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CancelIcon from '@material-ui/icons/Cancel'
import { useTranslation } from 'react-i18next'
import { getAbsoluteMonths } from '../../../utils/getAbsoluteMonths'
import { useSelector } from 'react-redux'

registerLocale('en-GB', enGB)

const useStyles = makeStyles((theme) => ({
  forceCursorPointer: {
    cursor: 'pointer',

    '& input': {
      cursor: 'pointer',
    },
  },
  align: ({ popperOffset, minimalPlacementLogic }) => ({
    display: 'flex',
    alignItems: 'center',
    // position: minimalPlacementLogic ? null : 'relative',
    position: 'relative',
    width: '100%',
    height: '100%',

    '& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '18px',
        paddingTop: '10px',
      },
    '& .react-datepicker-popper': {
      [theme.breakpoints.down('sm')]: {
        top: !!popperOffset ? `${popperOffset}px !important` : '0 !important',
      },
    },
    '& .react-datepicker__input-container input': {
      paddingRight: ({ pr }) => pr || null,
      width: '100%',
    },
  }),
  input: {
    borderRadius: '0',
    height: '100%',
    fontWeight: 600,
    fontSize: '14px',
    '& .MuiInputBase-input': {
      fontWeight: 600,
      paddingTop: 7,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& > input': {
      margin: '0',
      fontWeight: 600,
    },
  },
  clearBtn: {
    position: 'relative',
    marginTop: '-6px',
    alignSelf: 'center',
    justifySelf: 'end',
    marginLeft: 'auto',
    padding: 'unset',
    marginRight: '10px',
  },

  weight: {
    fontWeight: 600,
    letterSpacing: 0.7,
    textTransform: 'uppercase',
  },

  cancelBtnColor: ({ minimalPlacementLogic }) => ({
    color: minimalPlacementLogic ? '#abdddd' : '#abdddd',
  }),

  day: {
    width: 34,
    height: 34,
    color: 'inherit',
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  dayText: {
    fontWeight: 500,
    fontSize: theme.spacing(2),
  },
  disabledDayText: {
    fontWeight: 400,
    fontSize: theme.spacing(2),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 39,
    height: 32,
    margin: theme.spacing(0.25, 0),
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  startWrapper: {
    background: `linear-gradient(to right, ${theme.palette.common.white}, ${theme.palette.common.white} 50%, ${theme.palette.dateRange} 50%, ${theme.palette.dateRange})`,
  },
  endWrapper: {
    background: `linear-gradient(to right, ${theme.palette.dateRange}, ${theme.palette.dateRange} 50%, ${theme.palette.common.white} 50%, ${theme.palette.common.white})`,
  },
  activeWrapper: {
    backgroundColor: theme.palette.dateRange,
  },
  cancelBtnMobile: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    textTransform: 'capitalize',
  },
  mobileHeader: {
    position: 'fixed',
    left: '0',
    top: '0',
    width: '100vw',
    height: theme.spacing(8),
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  mobileHeading: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },
}))

const SingleDatePicker = ({
  textAlign = 'left',
  pr,
  value,
  onChange,
  isOutgoing,
  isOneWay,
  placeHolder,
  title,
  hideCancel,
  back,
  minDate,
  maxDate,
  HOTEL,
  disableLabel,
  disableIcon,
  from,
  to,
  btnRef,
  timeOnly,
  popperTop = false,
  TRAIN,
  MULTISTOP,
  minimalPlacementLogic,
}) => {
  const popperOffset = popperTop !== false ? popperTop : !!timeOnly ? -70 : 0
  const classes = useStyles({
    timeOnly,
    popperOffset,
    value,
    inputAlign: textAlign,
    pr: pr,
    minimalPlacementLogic,
  })
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()
  const ref = useRef()
  const dayRef = useRef()
  const [isOpened, setIsOpened] = useState(false)
  const [openedBefore, setOpenedBefore] = useState(false)
  const { isIframe } = useSelector((state) => state.customization)

  const keyDown = (event) => {
    event.preventDefault()
    setIsOpened(false)
    if ((event.which === 9 && !HOTEL) || (HOTEL && !isOutgoing)) {
      btnRef.current.focus()
    } else {
      document
        .getElementsByClassName('react-datepicker__input-container')[1]
        .children[0].focus()
    }
  }

  useEffect(() => {
    if (!isDesktop && ref.current !== null) {
      ref.current.input.readOnly = true
    }
  }, [isDesktop, ref])

  useEffect(() => {
    if (minimalPlacementLogic) return

    window.scrollTo(0, 0)
    if (dayRef.current && isOpened) {
      document.getElementsByClassName('react-datepicker')[0].scrollTo({
        behavior: 'smooth',
        top: dayRef.current.parentNode?.parentNode?.parentNode.offsetTop - 140,
      })
    }
  }, [isOpened])

  const closePicker = (e) => {
    if (e) e.stopPropagation()
    ref.current.setOpen(false)
    return false
  }

  const selectedDate = () => {
    if (value) {
      return new Date(value)
    }
    return null
  }

  const handleChange = (e) => {
    setOpenedBefore(true)
    onChange(e)
    if (HOTEL && isOutgoing) {
    }
    closePicker()
  }

  const selectMonth = () => {
    if (!openedBefore) {
      return moment().toDate()
    }
    const monthToAdd =
      getAbsoluteMonths(moment(from)) - getAbsoluteMonths(moment())
    return moment().add(monthToAdd, 'months').toDate()
  }

  const selectClassName = (day) => {
    let classes = ``
    if (to === from) {
      classes += 'react-datepicker__day--range-end'
    } else if (moment(from).isSame(day, 'day') && !to) {
      classes += 'react-datepicker__day--range-end'
    }
    if (to) {
      classes += ' react-datepicker__day--has-end'
    }
    if (from) {
      classes += ' react-datepicker__day--has-start'
    }
    return classes
  }
  const generalTitle = HOTEL
    ? isOutgoing
      ? t('hotel search check in')
      : t('hotel search check out')
    : isOutgoing
    ? t('outbound title')
    : t('search return')
  const generalPlaceholder = isOutgoing
    ? t('detail outbound')
    : t('search return trip')

  const startDate = MULTISTOP ? null : new Date(from)
  const endDate = MULTISTOP ? null : new Date(to)

  const handleClick = () => {
    ref.current.setOpen(true)
  }

  return (
    <Box
      onClick={handleClick}
      className={
        minimalPlacementLogic
          ? classes.forceCursorPointer
          : `${classes.align} ${classes.forceCursorPointer}`
      }
    >
      <Box
        style={
          minimalPlacementLogic
            ? null
            : {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 0,
              }
        }
      />
      <Box pt={1} width="100%">
        {!disableLabel && (
          <Box width="100%">
            <Typography
              className={classes.weight}
              variant="caption"
              color="textSecondary"
            >
              {title || generalTitle}
            </Typography>
          </Box>
        )}
        <Box className={classes.align}>
          {!disableIcon && (
            <Box mr={1} mt={0.5}>
              <EventIcon
                color="primary"
                fontSize="small"
                className={isIframe ? classes.eventIcon : null}
              />
            </Box>
          )}
          <Box
            className={isOneWay === true && !back ? classes.isDisabled : ''}
            position={minimalPlacementLogic ? 'unset' : 'relative'}
            onClick={(e) => {
              e.stopPropagation()
              return false
            }}
          >
            <Picker
              ref={ref}
              placeholderText={placeHolder || generalPlaceholder}
              dateFormat={!timeOnly ? 'd MMM' : 'HH:mm'}
              minDate={moment(minDate).toDate() || moment().toDate()}
              maxDate={maxDate ? moment(maxDate).toDate() : null}
              selectsStart={isOutgoing}
              selectsEnd={!isOutgoing}
              startDate={startDate}
              endDate={endDate}
              selected={selectedDate()}
              onChange={handleChange}
              showTimeSelect={!!timeOnly}
              showTimeSelectOnly={!!timeOnly}
              dayClassName={selectClassName}
              calendarClassName={!isOpened && 'closed'}
              onCalendarOpen={() => setIsOpened(true)}
              onCalendarClose={() => {
                setIsOpened(false)
              }}
              locale="en-GB"
              timeIntervals="60"
              renderDayContents={(day, date) => {
                return (
                  <div
                    className={`react-datepicker__day--inner`}
                    ref={
                      isOutgoing || !to
                        ? moment(date).isSame(from, 'day') && dayRef
                        : moment(date).isSame(to, 'day') && dayRef
                    }
                  >
                    {day}
                  </div>
                )
              }}
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              withPortal={!isDesktop && !timeOnly}
              monthsShown={!isDesktop && !timeOnly ? 12 : 1}
              openToDate={isDesktop && !timeOnly ? null : selectMonth()}
              showPreviousMonths={false}
              onFocus={(e) => (e.target.readOnly = true)}
              onKeyDown={keyDown}
              popperPlacement={
                minimalPlacementLogic ? 'top-end' : 'bottom-start'
              }
            >
              {!isDesktop && !timeOnly && (
                <Box className={classes.mobileHeader}>
                  <Button
                    className={classes.cancelBtnMobile}
                    color="primary"
                    onClick={closePicker}
                  >
                    {t('cancel btn')}
                  </Button>
                  <Typography className={classes.mobileHeading}>
                    {isOutgoing
                      ? t('select outbound date')
                      : t('select inbound date')}
                  </Typography>
                  <Box width={theme.spacing(8)} />
                </Box>
              )}
            </Picker>
          </Box>
          {to && !isOutgoing ? (
            <>
              {HOTEL || hideCancel ? (
                ''
              ) : (
                <IconButton
                  className={classes.clearBtn}
                  onClick={(e) => {
                    e.stopPropagation()
                    onChange(null)
                    return false
                  }}
                >
                  <CancelIcon className={classes.cancelBtnColor} />
                </IconButton>
              )}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default SingleDatePicker
