import i18n from 'i18next'

export const TAX_FIXED = 1.12
export const WIFI_REGEX =
  /free wifi|free wireless|free int|freei|wireless internet, complimentary|complimentary wireless internet/i
export const SIZE_REGEX =
  /([0-9]+)(sqm)|([0-9]+) (sqm)|([0-9]+)(sqft)|([0-9]+) (sqft)/gi
export const STARS = {
  S: 3,
  L: 5,
  F: 4,
  T: 2,
  '': null,
}
export const optionUserRatingFilters = [
  {
    short: `${i18n.t('guest rating1 title')}`,
    name: `${i18n.t('guest rating1')}`,
    value: 4.5,
    color: '#316300',
  },
  {
    short: `${i18n.t('guest rating2 title')}`,
    name: `${i18n.t('guest rating2')}`,
    value: 4,
    color: '#428500',
  },
  {
    short: `${i18n.t('guest rating3 title')}`,
    name: `${i18n.t('guest rating3')}`,
    value: 3.5,
    color: '#71A340',
  },
  {
    short: `${i18n.t('guest rating4 title')}`,
    name: `${i18n.t('guest rating4')}`,
    value: 3,
    color: '#F48F00',
  },
  { short: '', name: `${i18n.t('guest rating5')}`, value: 0, color: '#C94A30' },
]

export const ROOM_CATEGORY = () => ({
  H: `${i18n.t('disabled room')}`,
  I: `${i18n.t('budget room')}`,
  B: `${i18n.t('business room')}`,
  G: `${i18n.t('comfort room')}`,
  D: `${i18n.t('deluxe room')}`,
  X: `${i18n.t('duplex room')}`,
  E: `${i18n.t('executive room')}`,
  C: `${i18n.t('executive svit')}`,
  F: `${i18n.t('family category')}`,
  S: `${i18n.t('junior suite')}`,
  P: `${i18n.t('penthouse')}`,
  R: `${i18n.t('apartment')}`,
  M: `${i18n.t('standard')}`,
  L: `${i18n.t('studio')}`,
  A: `${i18n.t('superior room')}`,
  V: `${i18n.t('villa')}`,
})

export const BED_TYPE = () => ({
  D: `${i18n.t('double beds')}`,
  K: `${i18n.t('king-size beds')}`,
  P: `${i18n.t('pull-out beds')}`,
  Q: `${i18n.t('queen beds')}`,
  S: `${i18n.t('twin')}`,
  T: `${i18n.t('twin')}`,
  W: `${i18n.t('waterbeds')}`,
})

export const BED_TYPE_MULTIPLE = () => ({
  D: `${i18n.t('doubles')}`,
  K: `${i18n.t('king-size beds')}`,
  P: `${i18n.t('pull-out beds')}`,
  Q: `${i18n.t('queen beds')}`,
  S: `${i18n.t('twin')}`,
  T: `${i18n.t('twin')}`,
  W: `${i18n.t('waterbeds')}`,
})

export const BED_SIZE = {
  D: 2,
  K: 2,
  P: 1,
  Q: 2,
  S: 1,
  T: 1,
  W: 1,
  '*': 1,
}

export const AMENITIES = () => ({
  2: `${i18n.t('air conditioning')}`,
  3: `${i18n.t('alarm clock')}`,
  4: `${i18n.t('all news channels')}`,
  5: `${i18n.t('radio')}`,
  6: `${i18n.t('baby listening unit')}`,
  7: `${i18n.t('balcony terrace')}`,
  8: `${i18n.t('grill')}`,
  9: `${i18n.t('bath with jets')}`,
  10: `${i18n.t('bathrobe')}`,
  11: `${i18n.t('bathroom accessories')}`,
  12: `${i18n.t('telephone in bathroom')}`,
  13: `${i18n.t('bath')}`,
  14: `${i18n.t('bath only')}`,
  15: `${i18n.t('bathtub shower')}`,
  16: `${i18n.t('bidet')}`,
  17: `${i18n.t('bottle water')}`,
  18: `${i18n.t('cable TV')}`,
  19: `${i18n.t('coffee tea maker')}`,
  20: `${i18n.t('tv')}`,
  21: `${i18n.t('computer')}`,
  22: `${i18n.t('connecting rooms')}`,
  23: `${i18n.t('adapters')}`,
  24: `${i18n.t('copier')}`,
  25: `${i18n.t('wireless phone')}`,
  26: `${i18n.t('cot')}`,
  27: `${i18n.t('data port')}`,
  28: `${i18n.t('desk')}`,
  29: `${i18n.t('desk with lamp')}`,
  30: `${i18n.t('dining guide')}`,
  31: `${i18n.t('direkt telefonnummer')}`,
  32: `${i18n.t('diskmaskin')}`,
  33: `${i18n.t('double bed')}`,
  34: `${i18n.t('dual voltage outlet')}`,
  35: `${i18n.t('electrical current voltage')}`,
  36: `${i18n.t('ergonomic chair')}`,
  37: `${i18n.t('extended phone cord')}`,
  38: `${i18n.t('fax')}`,
  39: `${i18n.t('fire alarm')}`,
  40: `${i18n.t('fire alarm light')}`,
  41: `${i18n.t('fireplace')}`,
  42: `${i18n.t('free toll calls')}`,
  43: `${i18n.t('call for free')}`,
  44: `${i18n.t('free card calls')}`,
  45: `${i18n.t('free local calls')}`,
  46: `${i18n.t('free movies')}`,
  47: `${i18n.t('full kitchen')}`,
  48: `${i18n.t('handrails bathroom')}`,
  49: `${i18n.t('grecian tub')}`,
  50: `${i18n.t('hair dryer')}`,
  51: `${i18n.t('highspeed internet')}`,
  52: `${i18n.t('interactive web TV')}`,
  53: `${i18n.t('international direct dialing')}`,
  54: `${i18n.t('internet')}`,
  55: `${i18n.t('iron')}`,
  56: `${i18n.t('ironing board')}`,
  57: `${i18n.t('jacuzzi')}`,
  58: `${i18n.t('king size bed')}`,
  59: `${i18n.t('kitchen')}`,
  60: `${i18n.t('kitchen utensils')}`,
  61: `${i18n.t('small kitchen')}`,
  62: `${i18n.t('knock light')}`,
  63: `${i18n.t('laptop')}`,
  64: `${i18n.t('large desk')}`,
  65: `${i18n.t('large working area')}`,
  66: `${i18n.t('laundry basket')}`,
  67: `${i18n.t('loft')}`,
  68: `${i18n.t('microwave')}`,
  69: `${i18n.t('minibar')}`,
  70: `${i18n.t('modem')}`,
  71: `${i18n.t('modemuttag')}`,
  72: `${i18n.t('multiline phone')}`,
  73: `${i18n.t('newspaper')}`,
  74: `${i18n.t('smoke free room')}`,
  75: `${i18n.t('tablet')}`,
  76: `${i18n.t('office supplies')}`,
  77: `${i18n.t('oven')}`,
  78: `${i18n.t('pay-per-view TV')}`,
  79: `${i18n.t('pens')}`,
  80: `${i18n.t('telephone bathroom')}`,
  81: `${i18n.t('plates and bowls')}`,
  82: `${i18n.t('pans frying pans')}`,
  83: `${i18n.t('prayer mats')}`,
  84: `${i18n.t('printer')}`,
  85: `${i18n.t('private bathroom')}`,
  86: `${i18n.t('queen size bed')}`,
  87: `${i18n.t('recliner')}`,
  88: `${i18n.t('refrigerator')}`,
  89: `${i18n.t('refrigerator ice maker')}`,
  90: `${i18n.t('TV with control')}`,
  91: `${i18n.t('rollaway extra bed')}`,
  92: `${i18n.t('safe')}`,
  93: `${i18n.t('scanner')}`,
  94: `${i18n.t('separate wardrobe')}`,
  95: `${i18n.t('separate modem line')}`,
  96: `${i18n.t('shoe polisher')}`,
  97: `${i18n.t('shower only')}`,
  98: `${i18n.t('kitchen cutlery')}`,
  99: `${i18n.t('seating area')}`,
  100: `${i18n.t('smoke detectors')}`,
  101: `${i18n.t('smoking room')}`,
  102: `${i18n.t('sofa bed')}`,
  103: `${i18n.t('speakerphone')}`,
  104: `${i18n.t('stereo')}`,
  105: `${i18n.t('list')}`,
  106: `${i18n.t('tape recorder')}`,
  107: `${i18n.t('phone')}`,
  108: `${i18n.t('phone hearing impaired')}`,
  109: `${i18n.t('phone message light')}`,
  110: `${i18n.t('bread voice')}`,
  111: `${i18n.t('byxpressare')}`,
  112: `${i18n.t('turn down service')}`,
  113: `${i18n.t('twin bed')}`,
  114: `${i18n.t('arched ceilings')}`,
  115: `${i18n.t('VCR player')}`,
  116: `${i18n.t('VCR player')}`,
  117: `${i18n.t('video game')}`,
  118: `${i18n.t('voicemail')}`,
  119: `${i18n.t('wake up call')}`,
  120: `${i18n.t('WC')}`,
  121: `${i18n.t('water purification system')}`,
  122: `${i18n.t('wet bar')}`,
  123: `${i18n.t('wireless internet')}`,
  124: `${i18n.t('wireless keyboard')}`,
  125: `${i18n.t('adapter for telephone')}`,
  126: `${i18n.t('conditioning controlled room')}`,
  127: `${i18n.t('bath whirlpool separate')}`,
  128: `${i18n.t('telephone data port')}`,
  129: `${i18n.t('CP playe')}`,
  130: `${i18n.t('free local call')}`,
  131: `${i18n.t('charge for bed')}`,
  132: `${i18n.t('down feather pillows')}`,
  133: `${i18n.t('desk electrical outlet')}`,
  134: `${i18n.t('ESPN available')}`,
  135: `${i18n.t('foam pillows')}`,
  136: `${i18n.t('HBO available')}`,
  137: `${i18n.t('high ceilings')}`,
  138: `${i18n.t('marble bathroom')}`,
  139: `${i18n.t('available movie channels')}`,
  140: `${i18n.t('pets allowed')}`,
  141: `${i18n.t('large bathtub')}`,
  142: `${i18n.t('shower')}`,
  143: `${i18n.t('washbasin room')}`,
  144: `${i18n.t('soundproofed room')}`,
  145: `${i18n.t('storeroom')}`,
  146: `${i18n.t('tables and chairs')}`,
  147: `${i18n.t('two line telephone')}`,
  148: `${i18n.t('walk in closet')}`,
  149: `${i18n.t('washer dryer')}`,
  150: `${i18n.t('wave')}`,
  151: `${i18n.t('welcome gift')}`,
  152: `${i18n.t('desk spare electrical outlets')}`,
  153: `${i18n.t('non refundable pet fee')}`,
  154: `${i18n.t('refundable pet fee')}`,
  155: `${i18n.t('separate bath shower')}`,
  156: `${i18n.t('mat guest room')}`,
  157: `${i18n.t('ceiling fan')}`,
  158: `${i18n.t('CNN available')}`,
  159: `${i18n.t('electrical adapters available')}`,
  160: `${i18n.t('buffet breakfast')}`,
  161: `${i18n.t('handicapped accessible room')}`,
  162: `${i18n.t('wardrobes provided room')}`,
  163: `${i18n.t('DVD player')}`,
  164: `${i18n.t('mini fridge')}`,
  165: `${i18n.t('payment multiline phone')}`,
  166: `${i18n.t('self-controlled heating cooling')}`,
  167: `${i18n.t('bread voice')}`,
  168: `${i18n.t('analog dataport')}`,
  169: `${i18n.t('pays the calls')}`,
  170: `${i18n.t('international calls')}`,
  171: `${i18n.t('operator acces')}`,
  172: `${i18n.t('interstate calls')}`,
  173: `${i18n.t('intrastat conversation')}`,
  174: `${i18n.t('local calls')}`,
  175: `${i18n.t('long distance calls')}`,
  176: `${i18n.t('operator-assisted calls')}`,
  177: `${i18n.t('card accessible calls')}`,
  178: `${i18n.t('calling card call')}`,
  179: `${i18n.t('toll-free calls')}`,
  180: `${i18n.t('universal ac dc adapters')}`,
  181: `${i18n.t('bath seat')}`,
  182: `${i18n.t('canopy bed')}`,
  183: `${i18n.t('copper glass')}`,
  184: `${i18n.t('entertainment center')}`,
  185: `${i18n.t('family')}`,
  186: `${i18n.t('allergy-friendly bed')}`,
  187: `${i18n.t('allergy-friendly pillow')}`,
  188: `${i18n.t('lamp')}`,
  189: `${i18n.t('breakfast included')}`,
  190: `${i18n.t('breakfast included')}`,
  191: `${i18n.t('dinner included')}`,
  192: `${i18n.t('lunch included')}`,
  193: `${i18n.t('shared bathroom')}`,
  194: `${i18n.t('phone TDD text')}`,
  195: `${i18n.t('water bed')}`,
  196: `${i18n.t('extra cost adult')}`,
  197: `${i18n.t('extra cost children')}`,
  198: `${i18n.t('extra charge children extra bed')}`,
  199: `${i18n.t('breakfast included')}`,
  200: `${i18n.t('futon mattress')}`,
  201: `${i18n.t('murphy bed')}`,
  202: `${i18n.t('tatami rugs')}`,
  203: `${i18n.t('twin bed')}`,
  204: `${i18n.t('annex rum')}`,
  205: `${i18n.t('free newspapers')}`,
  206: `${i18n.t('honeymoon suite')}`,
  207: `${i18n.t('free internet room')}`,
  208: `${i18n.t('cleaning')}`,
  209: `${i18n.t('PC connection room')}`,
  210: `${i18n.t('satellite TV')}`,
  211: `${i18n.t('VIP room')}`,
  212: `${i18n.t('mobile phone charger')}`,
  213: `${i18n.t('DVRs')}`,
  214: `${i18n.t('iPod docking station')}`,
  215: `${i18n.t('media center')}`,
  216: `${i18n.t('plug play panel')}`,
  217: `${i18n.t('satellitradio')}`,
  218: `${i18n.t('video on demand')}`,
  219: `${i18n.t('exterior corridors')}`,
  220: `${i18n.t('gulf views')}`,
  221: `${i18n.t('handicapped accessible room')}`,
  222: `${i18n.t('internal corridors')}`,
  223: `${i18n.t('mountain view')}`,
  224: `${i18n.t('sea view')}`,
  225: `${i18n.t('internet connection extra charge')}`,
  226: `${i18n.t('wireless internet')}`,
  227: `${i18n.t('premium movie channels')}`,
  228: `${i18n.t('slippers')}`,
  229: `${i18n.t('first nighters kit')}`,
  230: `${i18n.t('chair belonging desk')}`,
  231: `${i18n.t('extra Waderaad madras')}`,
  232: `${i18n.t('feather bed')}`,
  233: `${i18n.t('duvet')}`,
  234: `${i18n.t('etra nice sheets')}`,
  235: `${i18n.t('international TV channels')}`,
  236: `${i18n.t('pantry')}`,
  237: `${i18n.t('disk cleaner')}`,
  238: `${i18n.t('double sinks')}`,
  239: `${i18n.t('illuminated makeup mirror')}`,
  240: `${i18n.t('extra bathroom amenities')}`,
  241: `${i18n.t('VCR available reception')}`,
  242: `${i18n.t('hot water')}`,
  243: `${i18n.t('outdoor space')}`,
  244: `${i18n.t('hinoki tub')}`,
  245: `${i18n.t('private pool')}`,
  246: `${i18n.t('HD TV 32 larger')}`,
  247: `${i18n.t('Room windows opened')}`,
  248: `${i18n.t('type sheets unspecified')}`,
  249: `${i18n.t('double bed')}`,
  250: `${i18n.t('round bed')}`,
  251: `${i18n.t('tv')}`,
  252: `${i18n.t('extra bed children')}`,
  253: `${i18n.t('DVD player reception')}`,
  254: `${i18n.t('video game players')}`,
  255: `${i18n.t('video player reception')}`,
  256: `${i18n.t('dining places')}`,
  257: `${i18n.t('mirror')}`,
  258: `${i18n.t('mobile phones')}`,
  259: `${i18n.t('movies')}`,
  260: `${i18n.t('several closets')}`,
  261: `${i18n.t('plates glasses')}`,
  262: `${i18n.t('safes holding laptop')}`,
  263: `${i18n.t('bed linen count')}`,
  264: `${i18n.t('blackout curtains')}`,
  265: `${i18n.t('bluray players')}`,
  266: `${i18n.t('mp3 player')}`,
  267: `${i18n.t('no channels locks')}`,
  268: `${i18n.t('allergy friendly room')}`,
  269: `${i18n.t('pillow type')}`,
  270: `${i18n.t('seating sofa chair')}`,
  271: `${i18n.t('separate toilet space')}`,
  272: `${i18n.t('web enabled')}`,
  273: `${i18n.t('widescreen TV')}`,
  274: `${i18n.t('other data connection')}`,
  275: `${i18n.t('telephone line paid')}`,
  276: `${i18n.t('separate bath shower')}`,
  277: `${i18n.t('video game')}`,
  278: `${i18n.t('ceiling fan')}`,
  279: `${i18n.t('playground for children')}`,
  280: `${i18n.t('plunge pool')}`,
  900101: `${i18n.t('duvet')}`,
  900102: `${i18n.t('egyptian cotton sheets')}`,
  900103: `${i18n.t('espresso machine')}`,
  900104: `${i18n.t('free toiletries')}`,
  900105: `${i18n.t('frette italian sheets')}`,
  900106: `${i18n.t('cleaning')}`,
  900107: `${i18n.t('hydrodusch')}`,
  900108: `${i18n.t('babysitting room')}`,
  900109: `${i18n.t('room massages')}`,
  900110: `${i18n.t('individually furnished rooms')}`,
  900111: `${i18n.t('individually furnished rooms')}`,
  900112: `${i18n.t('ipad')}`,
  900113: `${i18n.t('LCD plasma')}`,
  900114: `${i18n.t('LED TV')}`,
  900115: `${i18n.t('living room')}`,
  900116: `${i18n.t('memory foam madrass')}`,
  900117: `${i18n.t('patio')}`,
  900118: `${i18n.t('premium bedding')}`,
  900119: `${i18n.t('private spa bath')}`,
  900120: `${i18n.t('rainfall shower')}`,
  900121: `${i18n.t('secondary bathroom')}`,
  900122: `${i18n.t('shared kitchen')}`,
  900123: `${i18n.t('e-book readers')}`,
  900124: `${i18n.t('weekly cleaning')}`,
  900125: `${i18n.t('courtyard')}`,
  900126: `${i18n.t('hotel popular wifi')}`,
  900127: `${i18n.t('wifi extra charge')}`,
  900128: `${i18n.t('wifi')}`,
  900129: `${i18n.t('high speed internet')}`,
  900130: `${i18n.t('high speed internet cord')}`,
  900131: `${i18n.t('high speed internet cord extra')}`,
  900132: `${i18n.t('hotel popular free')}`,
  900133: `${i18n.t('hotel popular breakfast')}`,
  900134: `${i18n.t('environmentally friendly hotel')}`,
})
