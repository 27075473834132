import React, { Fragment, memo, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  Grid,
  ButtonBase,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter'
import daysBetween from '../../utils/daysBetween'
import HotelAmenitiesCard from '../../components/hotels/hotelAmenities'
import RatingsStars from '../../components/reusable/ratingsStars'
import UsersRating from './usersRating'
import HotelZoneInfo from './hotelZoneInfo'
import { setSelectedHotel } from '../../store/hotels/hotelsAction'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Policy from '../reusable/tags/policy'
import StarIcon from '@material-ui/icons/Star'
import { STARS } from '../../constants/hotelRoomCodes'
import smoothscroll from 'smoothscroll-polyfill'
import { getDistance } from 'geolib'
import LinesEllipsis from 'react-lines-ellipsis'
import { getCurrencyCodeBySign } from '../../utils/general'
import { useInView } from 'react-intersection-observer'
import usePriceFormat from '../../hooks/usePriceFormat'

smoothscroll.polyfill()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  cardContentMobile: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    //    height: '100%',
    minHeight: '194px',
  },

  cardContent: {
    padding: theme.spacing(2, 3),
    height: '100%',
  },

  cardContentMobileRight: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    //    height: '100%',
    height: '180px',
  },

  cardContentRight: {
    padding: theme.spacing(2, 2),
    height: '100%',
  },

  card: {
    borderRadius: '12px',
    margin: 0,
    padding: 0,
    border: 'solid 2px transparent',
  },

  selectedCard: {
    border: 'solid 2px #08c5b9',
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionArea: {
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    alignItems: 'stretch',
    height: '100%',
    paddingBottom: '0 !important',
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    alignItems: 'flex-end',
    paddingBottom: '0 !important',
  },
  cover: {
    height: 220,
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#f3f3f3',
    [theme.breakpoints.down('xs')]: {
      height: 170,
      //  width: 363,
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontFamily: 'Metropolis, sans-serif',
    textAlign: 'left',
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    maxHeight: '84px',
    overflow: 'hidden',
  },
  weight: {
    fontWeight: '500',
  },
  bold: {
    fontSize: '21px',
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    background: '#dcf7d9',
    color: '#15c500',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1, 2),
    fontWeight: theme.typography.fontWeightBold,
  },
  dim: {
    color: theme.palette.dimGray,
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  dimMobile: {
    color: theme.palette.dimGray,
    fontWeight: 500,
    fontSize: '13px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  alignEnd: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > *': {
      marginRight: theme.spacing(0.3),
    },
  },

  priorityCard: {
    borderRadius: '12px',
    margin: 0,
    padding: 0,
    //    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: '#e20078',
  },

  priorityBar: {
    color: 'white',
    padding: '8px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '28px',
    textAlign: 'center',
    height: '63px',
    fontFamily: 'Boucherie Block',
    letterSpacing: '-1px',
    backgroundColor: '#e20078',
    display: 'flex',
    justifyContent: 'space-between',
  },

  priorityText: {
    color: 'white',
    padding: '8px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '24px',
    fontFamily: 'Metropolis, sans-serif',
  },
}))

const HotelSearchItem = ({
  hotel,
  ratings,
  isSmall,
  isMiddleStep,
  showZoneData = false,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const params = useParams()
  const classes = useStyles()
  const {
    CachedData,
    exceedsPolicy,
    isDealFare,
    ratePlanCode,
    policyRatePlanCode,
    isNegotiatedFare,
    title: hotelName,
    hotelCode,
    countryCode,
    isCollectiveAgreement,
  } = hotel
  const { checkIn, checkOut, guests, roomCount } = params
  const [distance, setDistance] = useState(null)

  const selectedHotel = useSelector((state) => state.hotels.selectedHotel)
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = getCurrencyCodeBySign(currency)
  const { formattedCurrency, formatPrice } = usePriceFormat()

  useEffect(() => {
    const meters = hotel?.center
      ? getDistance(
          { latitude: hotel?.center.lat, longitude: hotel?.center.lng },
          { latitude: params.lat, longitude: params.lng }
        )
      : null
    setDistance(meters)
  }, [hotel?.center.lat, hotel?.center.lng, params.lat, params.lng])

  const cityCode = CachedData?.CITY
  const url = `/hotels/details/${countryCode}/${cityCode}/${hotelCode}/${checkIn}/${checkOut}/${guests}/${roomCount}`

  /*
  const goForward = () => {
    history.push({
      pathname: url,
      state: { isMiddleStep }
    })
  }
  */

  const between = daysBetween(checkOut, checkIn)

  const totalPrice = hotel.totalPrice
  const pricePerNight = parseInt(totalPrice / between)
  const pricePerRoomAndNight = hotel.pricePerRoomAndNight

  const [image, setImage] = useState(hotel.image)

  let amenities = []

  if (isCollectiveAgreement) {
    amenities.push({
      code: 900135,
      label: t('collective agreement'),
    })
  }

  if (hotel.environmentallyFriendly) {
    amenities.push({
      code: 900134,
      label: t('environmentally friendly hotel'),
    })
  }

  if (hotel.cancellationType === 'free') {
    amenities.push({
      code: 900132,
      label: t('hotel popular free'),
    })
  }
  if (hotel.breakfastIncluded && hotel.breakfastIncluded !== '0') {
    amenities.push({
      code: 900133,
      label: t('hotel popular breakfast'),
    })
  }
  if (hotel.freeWifi) {
    amenities.push({
      code: 900126,
      label: t('hotel popular wifi'),
    })
  }

  const renderAmenities = amenities.map((amenityObj) => (
    <HotelAmenitiesCard
      key={amenityObj.code}
      RoomAmenityCode={amenityObj.code}
      RoomAmenityLabel={amenityObj.label}
      small={true}
      rightAlign={true}
    />
  ))

  const stars = CachedData?.LOCALRATING
    ? parseInt(CachedData.LOCALRATING)
    : CachedData?.SELFRATING

  const findRatings = ratings?.length
    ? ratings.find(
        (x) =>
          x.name?.toLowerCase() === hotelName.toLowerCase() ||
          x.hotelCode == hotelCode
      )
    : ''

  const mouseEnter = () => {
    dispatch(setSelectedHotel(hotelCode))
  }

  const mouseLeave = () => {}

  const isSelected = selectedHotel && selectedHotel === hotelCode

  const distanceInKm = () => {
    if (distance >= 1000) {
      let kmDistance = distance
      kmDistance = kmDistance / 1000
      kmDistance = Math.round(kmDistance * 10) / 10
      return `${kmDistance} km ${t('from center')}`
    } else {
      return `${distance} m ${t('from center')}`
    }
  }

  const { priority, avropaData } = hotel

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px 0px 200px 0px',
  })

  let height = isMobile
    ? hotel.priority
      ? '420px'
      : '360px'
    : hotel.priority
    ? '280px'
    : '220px'

  return (
    <Card
      ref={ref}
      elevation={0}
      className={`${classes.card} ${
        isSelected && !isMobile ? classes.selectedCard : ''
      } ${priority && classes.priorityCard}`}
      style={{
        minHeight: height,
        border: isMobile && !priority && 'none',
      }}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {inView && (
        <Fragment>
          <ButtonBase
            className={classes.actionArea}
            component={Link}
            to={{
              pathname: url,
              state: { isMiddleStep },
            }}
          >
            {isSmall ? (
              <Box display="flex" style={{ flex: 1 }}>
                <Box style={{ flex: 1 }}>
                  {image &&
                  image.split('.')[image.split('.').length - 1] !== 'png' ? (
                    <CardMedia
                      style={{
                        height: '120px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      image={
                        image.split('.')[image.split('.').length - 1] === 'png'
                          ? null
                          : image
                      }
                      title={hotelName}
                      component="img"
                      onError={(e) => {
                        setImage(null)
                      }}
                    />
                  ) : (
                    <Box
                      style={{
                        height: '120px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>Image missing</Typography>
                    </Box>
                  )}
                </Box>
                <Box style={{ flex: 2 }}>
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '6px',
                    }}
                  >
                    <Box>
                      <Box display="flex" alignItems="center">
                        <StarIcon
                          style={{
                            width: '16px',
                            color: theme.palette.yellowOrange,
                          }}
                          color="primary"
                        />{' '}
                        <Typography style={{ fontSize: '14px' }}>
                          {typeof stars === 'string' ? STARS[stars] : stars}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                      }}
                    >
                      {capitalizeFirstLetter(hotelName)}
                    </Typography>
                    <Box pt="40px">
                      <Typography
                        variant="h5"
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          textAlign: 'left',
                        }}
                      >
                        {Math.floor(pricePerNight)}
                        {currencyCode}/{t('one night')}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Box>
            ) : (
              <Grid container>
                <Grid item xs={12} sm={4}>
                  {image &&
                  image.split('.')[image.split('.').length - 1] !== 'png' ? (
                    <CardMedia
                      className={classes.cover}
                      image={
                        image.split('.')[image.split('.').length - 1] === 'png'
                          ? null
                          : image
                      }
                      title={hotelName}
                      component="img"
                      onError={(e) => {
                        setImage(null)
                      }}
                    />
                  ) : (
                    <Box
                      className={classes.cover}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>Image missing</Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item container xs={12} sm={8}>
                  <Grid item xs={6}>
                    <Box
                      className={
                        isMobile
                          ? classes.cardContentMobile
                          : classes.cardContent
                      }
                    >
                      <CardContent className={classes.content}>
                        <Box display="flex" flexDirection="column" flexGrow="1">
                          <LinesEllipsis
                            basedOn="letters"
                            text={capitalizeFirstLetter(hotelName)}
                            maxLine="3"
                            ellipsis="..."
                            className={classes.title}
                          />
                          <Box pt={0}>
                            <RatingsStars stars={stars} />
                          </Box>
                          <Box pt={2} className={classes.alignEnd}>
                            <Box pb={isMobile ? 1.6 : 0} display="flex">
                              <LocationOnIcon
                                fontSize="small"
                                color="disabled"
                              />
                              <Typography
                                className={
                                  isMobile ? classes.dimMobile : classes.dim
                                }
                                style={{ textAlign: 'left' }}
                                variant="body2"
                              >
                                {distanceInKm()}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box pt={isMobile ? 1 : 0}>
                          <UsersRating rating={findRatings} />
                        </Box>
                      </CardContent>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={
                        isMobile
                          ? classes.cardContentMobileRight
                          : classes.cardContentRight
                      }
                    >
                      <CardContent
                        style={{ height: '100%' }}
                        className={classes.contentRight}
                      >
                        <Box
                          style={{ height: '100%' }}
                          display="flex"
                          alignItems=""
                          flexDirection="column"
                          flexGrow="1"
                          className={classes.contentRight}
                        >
                          <Typography variant="h5" className={classes.bold}>
                            <span style={{ fontSize: '14px' }}>
                              {formattedCurrency}
                            </span>{' '}
                            {formatPrice(pricePerRoomAndNight)}
                          </Typography>

                          <Policy
                            policy={exceedsPolicy}
                            deal={isDealFare}
                            corporate={isNegotiatedFare}
                            ratePlanCode={policyRatePlanCode || ratePlanCode}
                          />

                          <Box pt={0.5}>
                            <Typography
                              className={
                                isMobile ? classes.dimMobile : classes.dim
                              }
                              variant="body2"
                              align="right"
                            >
                              {roomCount}{' '}
                              {roomCount > 1 ? t('rooms') : t('room')} {between}{' '}
                              {between > 1 ? t('night') : t('one night')}{' '}
                              {t('filter for')} {formatPrice(totalPrice)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box pt={3}>{renderAmenities}</Box>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </ButtonBase>

          {showZoneData && avropaData && <HotelZoneInfo data={avropaData} />}

          {priority && (
            <Box className={classes.priorityBar}>
              <Box
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <CardMedia
                  image="https://static.citycity.se/agencies/citycity.bigtravel.se/Solvalla_logo_rgb.png"
                  style={{
                    width: '110px',
                    height: '40px',
                    display: 'inline-block',
                    backgroundSize: 'contain',
                  }}
                />
                <CardMedia
                  image="https://static.citycity.se/agencies/citycity.bigtravel.se/Elitloppet_24_vit.png"
                  style={{
                    width: '130px',
                    height: '25px',
                    display: 'inline-block',
                    marginLeft: '15px',
                    backgroundSize: 'contain',
                  }}
                />
              </Box>
              <Box className={classes.priorityText}>{t('hotel')}</Box>
            </Box>
          )}
        </Fragment>
      )}
    </Card>
  )
}

export default memo(HotelSearchItem)
