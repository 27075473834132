import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  Fragment,
  useImperativeHandle,
  useMemo,
} from 'react'
import {
  Box,
  Paper,
  Divider,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Travelers from './details/travelers'
import TicketDeliveryType from './details/ticketDeliveryType'
import Payment from './details/payment'
import WarrantCard from './details/warrantCard'
import ProfileCCPayment from './details/profileCCPayment'
import Total from './details/total'
import Co2 from './details/co2'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { setFop } from '../../store/checkout/checkoutActions'
import AddPublicUsersInfo from '../../components/reusable/addPublicUsersInfo'
import NetsPaymentBlock from './details/netsPaymentBlock'
import { tktDeliveryExceptions } from '../../utils/booking/logic/train'
import RecipientBlock from './details/recipientBlock'
import DlkBlock from './details/dlkBlock'
import storage from 'local-storage-fallback'
import Cookies from 'js-cookie'
import { getVirtualCustomerConfig } from '../../utils/virtualCustomerConfigs'

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '12px',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  align: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(4),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
}))

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })
  return !ref.current ? { isValid: null, submitCount: null } : ref.current
}

const CheckoutBlock = React.forwardRef(({ items, fops, isCcNeeded }, fref) => {
  const { user, allowAdditionalRecipient } = useSelector((state) => state.auth)
  const refs = {
    travellers: useRef(null),
  }

  const { netsPaymentId, paiwiseCheckoutId } = useSelector(
    (state) => state.checkout
  )

  const scrollTo = useCallback(
    (type) => {
      if (refs?.[type]?.current) {
        window.scrollTo({
          top: refs[type].current.offsetTop - 100,
          behavior: 'smooth',
        })
      }
    },
    [refs]
  )

  useImperativeHandle(
    fref,
    () => ({
      scrollTo: scrollTo,
    }),
    [scrollTo]
  )
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { fop, guaranteeLoading, costfields, costfieldsLoading } = useSelector(
    (state) => state.checkout
  )
  const payIsLoading = !!costfieldsLoading || !!guaranteeLoading
  const formik = useFormikContext()
  const isAddingUserDetails = !!formik?.values?.users?.length // is public users exists
  const prevFormik = usePrevious({
    isValid: formik.isValid,
    submitCount: formik.submitCount,
  })

  const getFirstErrorKey = useCallback((object, keys = []) => {
    if (!object) return null
    if (typeof object === 'object') {
      for (let objKey in object) {
        const tmpRes = getFirstErrorKey(object[objKey], [...keys, objKey])
        if (!tmpRes) continue
        return tmpRes
      }
    }
    return [...keys].join('.')
  }, [])

  const hotel = items.find((i) => i.type === 'Hotel')

  const isDelivAvailable = useMemo(() => {
    const trainTrip = items.find((i) => i.type === 'Train')

    const trainExceptions = ['SNA', 'NSB']
    if (!trainTrip?.outboundTrip?.train?.segments?.length) return false
    let result = true

    trainTrip.outboundTrip.train.segments.forEach((s) => {
      if (!!tktDeliveryExceptions?.[s?.railserviceProvider?.Code])
        result = false
    })

    if (trainTrip?.returnTrip?.train?.segments?.length) {
      trainTrip.returnTrip.train.segments.forEach((s) => {
        if (!!tktDeliveryExceptions?.[s?.railserviceProvider?.Code])
          result = false
      })
    }
    return result
  }, [items])

  const isLeisure = hotel?.room?.isLeisure
  const forceB2BWalletForLeisureHotels =
    costfields && costfields.forceB2BWalletForLeisureHotels
  const payHotelKey = isLeisure ? 'leisureHotel' : 'gdsHotel'
  let hotelAgreement =
    costfields?.paymentMethods?.agreementMethods?.[payHotelKey]
  const isOnlyHotelPayment =
    fops.itemTypesToPay.length === 1 && fops.itemTypesToPay[0] === 'Hotel'
  const onlyMainPay = fops.all.length === 1 && fops.all[0] === fops.primaryFop
  const isHotelInvoice =
    fop === 'invoice' || (fop === 'agreement' && hotelAgreement === 'invoice')

  // Override company agreement for leisure hotels
  if (forceB2BWalletForLeisureHotels && isLeisure) {
    hotelAgreement = 'b2bwallet'
  }

  const isHotelB2BWallet =
    forceB2BWalletForLeisureHotels ||
    fop === 'b2bwallet' ||
    (fop === 'agreement' && hotelAgreement === 'b2bwallet')
  // console.log({
  //   isHotelB2BWallet,
  //   onlyMainPay,
  //   fop,
  //   fops
  // })
  const isHidePayment =
    (onlyMainPay && isOnlyHotelPayment && isHotelInvoice && !isLeisure) ||
    (onlyMainPay && isOnlyHotelPayment && isHotelB2BWallet && isLeisure)

  useEffect(() => {
    if (fops?.all?.length && !fops?.all?.includes(fop)) {
      dispatch(setFop(fops.all[0]))
    }
    // eslint-disable-next-line
  }, [])

  // no need to track prevFormik
  useEffect(
    () => {
      if (
        !formik.isValid &&
        formik.submitCount > 0 &&
        (formik.isValid !== prevFormik.isValid ||
          formik.submitCount !== prevFormik.submitCount)
      ) {
        const firstErrorKey = getFirstErrorKey(formik.errors)
        if (global.window.document.getElementsByName(firstErrorKey).length) {
          global.window.document.getElementsByName(firstErrorKey)[0].focus()
        }
      }
    },
    //eslint-disable-next-line
    [formik.submitCount, formik.isValid, formik.errors, getFirstErrorKey]
  )

  const isProfileCCSeparate =
    fop === 'cc' && fops?.perFop[fops.primaryFop]?.length
  const isSeparateTop =
    fops.perFop[fops.primaryFop]?.includes('Train') ||
    fops.perFop[fops.primaryFop]?.includes('Flight') ||
    false
  let separatePayBlock = null
  if (isProfileCCSeparate) {
    separatePayBlock = (
      <Box>
        <Box py={2} px={isMobile ? 0 : 2}>
          <ProfileCCPayment />
        </Box>
        <Divider />
      </Box>
    )
  }

  const co2Exists = items.find((item) => item.co2)
  const showDlkBlock = getVirtualCustomerConfig()?.showDLK

  // console.log(netsPaymentId, 'netsPaymentId')
  // console.log(paiwiseCheckoutId, 'paiwiseCheckoutId')
  // console.log(payIsLoading, 'payIsLoading')

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Box>
          {!!isDelivAvailable && (
            <Fragment>
              <Box pt={4} pb={4} px={isMobile ? 2 : 4}>
                <TicketDeliveryType />
              </Box>
              <Divider />
            </Fragment>
          )}
          {isAddingUserDetails && (
            <Fragment>
              <Box pt={4} pb={4} px={isMobile ? 2 : 4}>
                <AddPublicUsersInfo type={hotel && 'hotel'} />
              </Box>
              <Divider />
            </Fragment>
          )}
          <Box ref={refs.travellers} /> {/*travellers scroll point*/}
          {items.length > 0 && <Travelers items={items} useCF={true} />}
          {allowAdditionalRecipient && (
            <Box>
              <RecipientBlock />
              <Divider />
            </Box>
          )}
          {showDlkBlock && (
            <Box>
              <DlkBlock />
              <Divider />
            </Box>
          )}
          {!netsPaymentId && !paiwiseCheckoutId && payIsLoading && (
            <Box>
              <Box mx={isMobile ? 2 : 4} m={2} mt={4}>
                <Box mb={2}>
                  <Typography variant="h5" className={classes.bold}>
                    {t('payment pays')}
                  </Typography>
                </Box>
                <Divider />
              </Box>

              <Box mx={4} m={2} textAlign={'center'}>
                <CircularProgress />
              </Box>
              <Divider />
            </Box>
          )}
          {!netsPaymentId && !paiwiseCheckoutId && !payIsLoading && (
            <Box>
              {isSeparateTop && separatePayBlock}
              {!isHidePayment && (
                <Box py={2} px={isMobile ? 0 : 2} pb={fop === 'cc' ? 0 : 4}>
                  <Payment
                    isHotelInvoice={isHotelInvoice}
                    iisHotelB2BWallet={isHotelB2BWallet}
                    fops={fops}
                  />
                </Box>
              )}
              {fop !== 'cc' && !isHidePayment && <Divider />}
              {isCcNeeded && (
                <Box>
                  <WarrantCard fops={fops} />
                  <Divider />
                </Box>
              )}
              {!isSeparateTop && separatePayBlock}
            </Box>
          )}
          {co2Exists && (
            <>
              <Box pt={2} pb={6} px={isMobile ? 0 : 2}>
                <Co2 />
              </Box>
              <Divider />
            </>
          )}
          <Box pt={2} pb={6} px={isMobile ? 0 : 2}>
            <Total addFeeText={user?.priceFeeWarning || false} />
          </Box>
          {netsPaymentId && <NetsPaymentBlock />}
        </Box>
      </Paper>
    </>
  )
})

export default memo(CheckoutBlock)
