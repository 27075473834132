import agent from './agent'

// **POST** fetches flights
export const fetchFlights = async (payload) => {
  try {
    const { data } = await agent.post('/v2/flights', payload)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchCityByIata = async (text, lang) => {
  if (!text) return
  const url = `/v2/flight/city-by-iata/${text}?lang=${lang}`

  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

export const fetchFlightAirport = async (text, lang, isTo = false) => {
  if (!text) {
    text = ''
  }
  const dest = encodeURIComponent(text.trim())
  const url = `/v2/flight/city/${dest}?lang=${lang}&isTo=${isTo ? 1 : 0}`
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}

// ** POST ** Fetches StandaloneCatalogue
export const fetchPostStandaloneCatalogue = async (payload, ctKey = null) => {
  try {
    const url = `/v2/flights/get-standalone-catalogue`
    const { data } = await agent.post(url, payload,{
      cityCityCT: ctKey,
    })
    return data
  } catch (error) {
    throw error
  }
}

/* **POST** fetches family pricing */
export const fetchPostFamiliesPricing = async (payload, ctKey = null) => {
  try {
    const url = '/v2/flights/get-families-pricing'
    const { data } = await agent.post(url, payload, {
      cityCityCT: ctKey,
    })
    return data
  } catch (error) {
    throw error
  }
}

/* **POST**  Get Seats   */
export const fetchPostGetSeats = async (payload, ctKey = null) => {
  const url = `/v2/flights/get-seats`
  try {
    const { data } = await agent.post(url, payload, {
      cityCityCT: ctKey,
    })
    return data
  } catch (error) {
    throw error
  }
}

/* **GET**    Get MIDDLESTEP */
export const fetchGetMiddleStepSettings = async () => {
  const url = '/middlestep-settings'
  try {
    const { data } = await agent.get(url)
    return data
  } catch (error) {
    throw error
  }
}
