import React, {Fragment, useCallback} from 'react'
import {Box} from '@material-ui/core'
import moment from 'moment'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import RoomIcon from '@material-ui/icons/Room'
import RailwayIcon from '@material-ui/icons/DirectionsRailway'
import SyncAltIcon from '@material-ui/icons/SyncAlt'

const useStyles = makeStyles((theme) => ({
  depRow: {
    display: 'flex',
    alignItems: 'center'
  },

  timeBlock: {
    minWidth: '39px',
    marginRight: '15px',
    textAlign: 'right'
  },

  iconBlock: {
    padding: '5px 0',
    lineHeight: '1px'
  },

  materialIcon: {
    marginTop: '3px',
    fontSize: '24px'
  },

  changesIcon: {
    marginRight: '5px'
  },

  circleIcon: {
    display: 'inline-block',
    height: '24px',
    width: '24px',
    borderRadius: '24px',
    border: '2px solid black',
  },

  tripInfoBlock: {
    minHeight: '70px',
    marginLeft: '11px',
    borderLeft: '2px solid black',

    '&.change-info-block': {
      minHeight: '40px',
      borderLeftStyle: 'dashed',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '26px',
    }
  },

  changesBlock: {
    marginLeft: '10px',
  },

  descriptionBlock: {
    flex: '1',
    paddingLeft: '15px'
  },

  fontWeightBold: {
    fontWeight: theme.typography.fontWeightBold
  }
}))

const RailTripScheme = ({ segments }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getChangeTime = useCallback( index => {
    if (index <= 0 || !segments?.[index - 1] || !segments?.[index]) return 0
    const curr = moment(segments?.[index]?.railstart?.dateTime)
    const prev = moment(segments?.[index - 1]?.railend?.dateTime)

    if (!curr.isValid() || !prev.isValid()) return 0

    const mins = curr.diff(prev, 'minutes')
    if (mins <= 0) return 0
    const diffHrs = curr.diff(prev, 'hours')
    const diffMin =  mins - (diffHrs*60)

    return `${diffHrs > 0 ? diffHrs +'h' : ''} ${diffMin > 0 ? diffMin +'m' : ''}`
  }, [segments])

  const getTimeFromDate = useCallback(date => {
    const momentDate = moment(date)
    if (!momentDate.isValid()) return ''
    return momentDate.format('HH:mm')
  }, [])

  return (
    <Box key="tkt-trip-scheme-outer" className={classes.tripSchemeOuter}>
      {segments.map((s, i) => (
        <Fragment key={`fragment-row-${i}`}>
          {i > 0 &&
            <div key={`change-row-${i}`} className={classes.depRow}>
              <div key="rail-time-node" className={classes.timeBlock}/>
              <div key="info-block" className={`${classes.tripInfoBlock} change-info-block`}>
                <SyncAltIcon key="changes-icon" className={classes.changesIcon}/>
                {getChangeTime(i) &&
                  <div key="change-text" className="changes-block"> {getChangeTime(i)}</div>
                }
              </div>
            </div>
          }

          <div key={`rail-start-node-${i}`} className={classes.depRow}>
            <div key="rail-time-node" className={`${classes.timeBlock} ${i === 0 ? classes.fontWeightBold : '' }`}>
              {getTimeFromDate(s?.railstart?.dateTime)}
            </div>
            <div key="rail-icon-block" className={classes.iconBlock}>
              <RailwayIcon key="rail-icon" className={classes.materialIcon}/>
            </div>
            <div key="info-block" className={`${classes.descriptionBlock} ${i === 0 ? classes.fontWeightBold : '' }`}>
              {s?.railstart?.locationName}
            </div>
          </div>

          <div key={`rail-info-row-${i}`} className={classes.depRow}>
            <div key="rail-time-node" className={classes.timeBlock}/>
            <div key="info-block" className={classes.tripInfoBlock}/>
          </div>

          <div key={`rail-end-node-${i}`} className={classes.depRow}>
            <div key="rail-time-node" className={`${classes.timeBlock} ${i >= segments.length - 1 ? classes.fontWeightBold : '' }`}>
              {getTimeFromDate(s?.railend?.dateTime)}
            </div>
            <div key="rail-icon-block" className={classes.iconBlock}>
              {i < segments.length - 1 ? (
                <div key="circle-icon" className={classes.circleIcon}/>
              ) : (
                <RoomIcon key="rail-icon" className={classes.materialIcon}/>
              )}

            </div>
            <div key="info-block" className={`${classes.descriptionBlock} ${i >= segments.length - 1 ? classes.fontWeightBold : '' }`}>
              {s?.railend?.locationName}
            </div>
          </div>
        </Fragment>
      ))}
    </Box>
  )
}

export default RailTripScheme
