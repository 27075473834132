import constants from './constants'

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  isLogginIn: false,
  logInError: '',
  user: null,
  choiceOfAgencies: null,
  authType: null, //type of authenticated user
  agentCompany: null, // Company chosen by Agent user
  agentAuthUser: null, // User token logged in via Agent
  publicityLoading: false,
  publicity: 'both',
  disabled: null,
  disabledPublic: null,
  showDisabledLinks: false,
  countryPhone: { code: 'SE', name: 'Sweden', dialCode: '46' },
  currency: 'SEK',
  trainSearch: 'default',
  showAddressDetails: false,
  showBirthDate: false,
  banners: null,
  supportTelephone: null,
  hideFooter: false,
  defaultPath: null,
  backgroundImage: null,
  backgroundColor: null,
  defaultLang: null,
  agencyTitle: null,
  messages: {},
  skipConfirmationEmail: false,
  requireMobile: false,
  addresses: [],
  carVendorOrder: [],
  carSort: 'default',
  serviceFee: null,
  useAvropa: false,
  restrictedTransfers: false,
  showCalendarInHeader: false,
  allowAdditionalRecipient: false,
  hideCreditcardFees: false,
  favoriteUsers: [],
  hideCorporateCodes: false,
  enableAnyFlightCabin: false,
  encloseCostfieldValues: false,
  enableMultiStop: false,
  forceB2BWalletForLeisureHotels: false,
  useLogoForMapAddresses: false,
  currencies: null,
  selectedCurrency: null,
  allowCurrencySelection: false,
}

export default (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case constants.SET_CURRENCY:
      return {
        ...state,
        selectedCurrency: payload,
      }

    case constants.SET_USER:
      let tmpAuthType = state?.authType || null
      const tmpAuthTypes =
        payload?.authTypes?.length > 0 ? payload.authTypes : []

      if (tmpAuthTypes.length <= 0) {
        tmpAuthType = null
      } else if (tmpAuthTypes.length === 1) {
        tmpAuthType = tmpAuthTypes[0]
      } else if (!!tmpAuthType && !tmpAuthTypes.includes(tmpAuthType)) {
        tmpAuthType = null
      }

      return {
        ...state,
        isAuthenticated: true,
        authType: tmpAuthType,
        user: payload,
        favoriteUsers: payload?.favoriteUsers || [],
      }

    case constants.IS_USER_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case constants.LOGIN_IN_ERROR:
      return {
        ...state,
        logInError: payload,
      }

    case constants.SET_PUBLICITY_LOADING:
      return {
        ...state,
        publicityLoading: !!payload,
      }
    case constants.IS_LOGIN_IN:
      return {
        ...state,
        isLogginIn: payload,
      }
    case constants.LOG_OUT_USER:
      return {
        ...initialState,
        publicity: state.publicity,
      }
    case constants.SET_CHOICE_OF_AGENCIES:
      return {
        ...state,
        choiceOfAgencies: payload,
      }
    case constants.SET_AGENCY_COMPANY:
      return {
        ...state,
        agentCompany: payload?.uniqueId ? { ...payload } : null,
      }
    case constants.SET_AUTH_TYPE:
      return {
        ...state,
        authType: payload,
      }
    case constants.SET_AGENT_AUTH_USER:
      return {
        ...state,
        agentAuthUser: payload.token,
        authType: payload.authType,
      }

    case constants.SET_SITE_OPTIONS:
      return {
        ...state,
        publicity: payload.publicity,
        disabled: payload.disabled,
        disabledPublic: payload.disabledPublic,
        countryPhone: payload.countryPhone,
        currency: payload.currency,
        trainSearch: payload.trainSearch,
        showAddressDetails: payload.showAddressDetails,
        showBirthDate: payload.showBirthDate,
        banners: payload.banners,
        supportTelephone: payload.supportTelephone,
        hideFooter: payload.hideFooter,
        defaultPath: payload.defaultPath,
        backgroundImage: payload.backgroundImage,
        backgroundColor: payload.backgroundColor,
        defaultLang: payload.defaultLang,
        showDisabledLinks: payload.showDisabledLinks,
        agencyTitle: payload.agencyTitle,
        messages: payload.messages || {},
        skipConfirmationEmail: payload.skipConfirmationEmail,
        requireMobile: payload.requireMobile,
        addresses: payload.addresses,
        serviceFee: payload.serviceFee,
        useAvropa: payload.useAvropa,
        restrictedTransfers: payload.restrictedTransfers,
        showCalendarInHeader: payload.showCalendarInHeader,
        allowAdditionalRecipient: payload.allowAdditionalRecipient,
        hideCreditcardFees: payload.hideCreditcardFees,
        hideCorporateCodes: payload.hideCorporateCodes,
        enableAnyFlightCabin: payload.enableAnyFlightCabin,
        encloseCostfieldValues: payload.encloseCostfieldValues,
        enableMultiStop: payload.enableMultiStop,
        forceB2BWalletForLeisureHotels: payload.forceB2BWalletForLeisureHotels,
        useLogoForMapAddresses: payload.useLogoForMapAddresses,
        currencies: payload.currencies,
        allowCurrencySelection: payload.allowCurrencySelection,
      }

    case constants.SET_FAVORITE_USERS:
      return {
        ...state,
        favoriteUsers: payload,
      }

    case constants.SET_LOYALTIES:
      return {
        ...state,
        user: {
          ...state.user,
          loyaltyProgram: payload,
        },
      }

    default:
      return state
  }
}
