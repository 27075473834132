import flightLogic, {
  getAvailableFop as getAvailableFlightFop,
} from './logic/flight'
import trainLogic, {
  getAvailableFop as getAvailableTrainFop,
} from './logic/train'
import hotelLogic, {
  getAvailableFop as getAvailableHotelFop,
} from './logic/hotel'
import transferLogic, {
  getAvailableFop as getAvailableTransferFop,
} from './logic/transfer'
import carLogic, { getAvailableFop as getAvailableCarFop } from './logic/car'
import _ from 'lodash'
import cityCityException from '../cityCityException.Class'
import i18n from '../i18n'
//import mockObject from '../../repositories/mock/booking.json'

const functionHandler = {
  Train: trainLogic,
  Flight: flightLogic,
  Hotel: hotelLogic,
  Transfer: transferLogic,
  Car: carLogic,
}

const fopFunctionHandler = {
  Train: getAvailableTrainFop,
  Flight: getAvailableFlightFop,
  Hotel: getAvailableHotelFop,
  Transfer: getAvailableTransferFop,
  Car: getAvailableCarFop,
}

export const getFops = (
  { isAuthenticated = true, checkoutState },
  onlyTypes = ['Train', 'Flight', 'Hotel', 'Transfer', 'Car']
) => {
  const { items, fop: selectedFop, costfields } = checkoutState
  let perFop = {}
  const primaryFop = costfields?.paymentMethods?.primaryPayment || 'profileCC'
  const itemTypesToPay = []

  let requiredFops = []
  let allFops = []

  items.forEach((itm) => {
    if (onlyTypes && !onlyTypes.includes(itm.type)) return []
    let aFop = [primaryFop]
    if (!itemTypesToPay.includes(itm.type)) itemTypesToPay.push(itm.type)
    const chItems = []

    if (itm.type === 'Hotel') {
      //temporary fix
      const fixedItems = itm.trips ? itm.trips : [itm]
      fixedItems.forEach((fixedItm) => {
        chItems.push({
          ...fixedItm.room,
          country: fixedItm?.hotel?.params?.countryCode,
        })
      })
    } else if (typeof fopFunctionHandler[itm.type] != 'undefined') {
      chItems.push(itm)
    }

    chItems.forEach((chItem) => {
      aFop = fopFunctionHandler[itm.type](
        checkoutState,
        isAuthenticated,
        chItem
      )

      const rqFop = aFop.rqFops
      aFop = aFop.fops
      requiredFops.push(...rqFop)
      allFops.push(...aFop)

      const usedFop = aFop.find((a) => a === selectedFop) || aFop[0]
      if (!perFop[usedFop]) perFop[usedFop] = []
      if (!perFop[usedFop].includes(itm.type)) perFop[usedFop].push(itm.type)

      rqFop.forEach((rqf) => {
        if (!perFop[rqf]) perFop[rqf] = []
        if (!perFop[rqf].includes(itm.type)) perFop[rqf].push(itm.type)
      })
    })
  })

  let uniqueFops = _.uniq(allFops)
  let uniqueRqFops = _.uniq(requiredFops)

  if (uniqueFops.length <= 0 && uniqueRqFops.length > 0)
    uniqueFops.push(uniqueRqFops[0])

  return {
    all: uniqueFops,
    required: uniqueRqFops,
    perFop,
    primaryFop,
    itemTypesToPay,
  }
}
export default (cart, state, extData) => {
  // Cart  = wholecart - all items in cart
  // Iterate based on TYPE, can be 'flight' if of type FLIGHT
  // This should poulate OBJECT
  const bookData = {
    flight: null,
    rail: null,
    hotel: null,
    transfer: null,
  }
  const {
    publicContacts,
    netsPaymentId,
    paiwiseCheckoutId,
    addressDetails,
    checksum,
    additionalRecipientEmail,
  } = state.checkout
  const {
    isAuthenticated,
    user,
    showAddressDetails,
    selectedCurrency,
    currency,
  } = state.auth

  bookData.additionalRecipientEmail = additionalRecipientEmail

  // Selected display currency if different from agency currency
  if (selectedCurrency !== currency) {
    bookData.displayCurrency = selectedCurrency
  }

  if (netsPaymentId) {
    // If Nets payment is used, add paymentId to booking data.
    bookData.netsPaymentId = netsPaymentId
  }

  // If Paiwise payment is used, add checkoutId to booking data.
  if (paiwiseCheckoutId) {
    bookData.paiwiseCheckoutId = paiwiseCheckoutId
  }

  const fees = {}
  cart.forEach((item) => {
    switch (item.type) {
      case 'Flight':
        bookData['flight'] = functionHandler['Flight'](item, state, extData)
        fees.flight = bookData?.flight?.obFee || 0
        return
      case 'Train':
        bookData['rail'] = functionHandler['Train'](item, state, extData)
        return
      case 'Hotel':
        bookData['hotel'] = functionHandler['Hotel'](item, state, extData)
        return
      case 'Transfer':
        bookData['transfer'] = functionHandler['Transfer'](item, state, extData)
        return
      case 'Car':
        bookData['car'] = functionHandler['Car'](item, state)
        return
      default:
        throw new cityCityException({ message: 'there is an unsupported type' })
    }
    // Shoul return an object
  })

  let totalFee = 0
  Object.values(fees).forEach((fee) => {
    if (!!fee && parseFloat(fee) > 0) totalFee += parseFloat(fee)
  })

  if (!!bookData?.transfer?.transfers?.length > 0) {
    bookData.foid = []
    bookData.transfer.transfers.forEach((t) => {
      if (
        !!t?.passenger?.foid &&
        typeof t.passenger.foid === 'string' &&
        !bookData.foid.includes(t.passenger.foid)
      ) {
        bookData.foid.push(t.passenger.foid)
      }
    })
  }

  // Booking checksum for detecting duplicates
  bookData['checksum'] = checksum

  if (!isAuthenticated) {
    bookData['publicContacts'] = {
      email: publicContacts?.email || '',
      phone: publicContacts?.phone,
    }
  }

  if (showAddressDetails) {
    bookData['addressDetails'] = addressDetails
  }
  if (
    !!user?.isBookingLink &&
    !user?.bookingLink?.unlimitedAmount &&
    user?.bookingLink?.amount < state.checkout.sum
  ) {
    throw new cityCityException({
      message: `Booking sum is greater than allowed (${user?.bookingLink?.amount}kr)`,
      type: 'citycity validation',
    })
  }

  bookData['sum'] = parseFloat(state.checkout.sum) + totalFee
  bookData['lang'] = i18n.language
  return bookData
}

// this functions role is to output  --- > mockObject
