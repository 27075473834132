import React, { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CardMedia,
  TextField,
  Typography,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fetchBookLink, joinBookLink } from '../../repositories/auth'
import { fetchPublicity, relogin } from '../../store/auth/authAction'
import { openBookingLinkInfoModalAction } from '../../store/modals/modalAction'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '25px 10px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  wrapper: {
    maxWidth: '500px',
    width: '100%',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    gap: theme.spacing(3),
  },
  btn: {
    borderRadius: '10px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2),
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '28px',
    lineHeight: '1.5',
    color: theme.palette.common.black,
    textAlign: 'center',

    '&.error': {
      color: theme.palette.secondary.main,
    },
  },
  errorText: {
    fontSize: '14px',
    color: theme.palette.error.main,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  logo: {
    maxHeight: '400px',
    maxWidth: '400px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      maxWidth: '200px',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))
const errorTexts = {
  1100: 'booking link activation failed',
  1101: 'expired bl description',
  1102: 'used bl description',
  1103: 'inactive bl',
}

const JoinBookingLink = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  let { identifier } = useParams()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    other: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [linkData, setLinkData] = useState(null)

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(String(email).toLowerCase())
  }

  const joinLink = async () => {
    // Validate fields
    if (!validateEmail(email)) {
      return setErrors({ email: true })
    }
    if (!/\S{3,}/.test(firstName)) return setErrors({ firstName: true })
    if (!/\S{3,}/.test(lastName)) return setErrors({ lastName: true })

    setErrors({})
    setIsLoading(true)

    try {
      let loginData = await joinBookLink(identifier, {
        email,
        firstName,
        lastName,
      })
      if (!loginData?.access_token || !loginData?.refresh_token) {
        throw Error('activation failed')
      }
      await dispatch(relogin(loginData))
      await dispatch(fetchPublicity(true))
      dispatch(openBookingLinkInfoModalAction())
      history.push('/')
      return true
    } catch (e) {
      console.log(e)
      const errCode = e?.response?.data?.code || 1100
      let error = t(errorTexts[errCode] || errorTexts[1100])
      setErrors({ other: error })
      return false
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchBookLink(identifier)
      .then((data) => {
        console.log(data)
        setLinkData(data)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  return (
    <Box className={classes.root}>
      <Container maxWidth="md" disableGutters className={classes.container}>
        <Box className={classes.wrapper}>
          <Fragment>
            {linkData?.logoUrl && (
              <CardMedia
                component="img"
                data-id="header-logo"
                image={linkData.logoUrl}
                className={classes.logo}
              />
            )}

            <Typography className={classes.text}>
              {linkData?.emailTitle || ''}
            </Typography>

            <Box mb={2} mt={2}>
              <TextField
                fullWidth
                label={t('profile name')}
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="normal"
                disabled={isLoading}
                error={!!errors.firstName}
                helperText={errors.firstName && t('first name required')}
              />

              <TextField
                fullWidth
                label={t('profile surname')}
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="normal"
                disabled={isLoading}
                error={!!errors.lastName}
                helperText={errors.lastName && t('last name required')}
              />

              <TextField
                fullWidth
                label={t('email form')}
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                disabled={isLoading}
                error={!!errors.email}
                helperText={errors.email && t('invalid email')}
              />
            </Box>

            {errors.other && (
              <Box className={classes.errorText}>{errors.other}</Box>
            )}

            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={() => joinLink()}
              disableElevation
              disableRipple
              disabled={isLoading}
            >
              {t('book travels')}
            </Button>
          </Fragment>
        </Box>
      </Container>
    </Box>
  )
}

export default JoinBookingLink
