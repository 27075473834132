import React, { memo, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import LocalHotelIcon from '@material-ui/icons/LocalHotel'
import WifiIcon from '@material-ui/icons/Wifi'
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast'
import { addToCheckoutAction } from '../../store/checkout/checkoutActions'
import numberOfDaysBetween from '../../utils/daysBetween'
import Policy from '../reusable/tags/policy'
import { POLICY_RESULT } from '../../constants/policy'
import { openPolicyExceededModal } from '../../store/modals/modalAction'
import { fetchCityByIata } from '../../repositories/flights'
import usePriceFormat from '../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  alignChildren: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  blob: {
    backgroundColor: '#dcf7d9',
    color: '#15c500',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
  },
  yellowBlob: {
    backgroundColor: theme.palette.yellow,
    color: theme.palette.orange,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  weightSemiBold: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  btn: {
    marginLeft: 'auto',
    borderRadius: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 4),
    letterSpacing: 1.2,
  },
}))

const HotelRoomCard = ({ room, hotel }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { items } = useSelector((state) => state.checkout)
  const searchData = useSelector((state) => state.search)
  const { t } = useTranslation()
  const { guests } = useSelector((state) => state.hotels)
  const { currency } = useSelector((state) => state.auth)
  const { roomsCount, checkIn, checkOut } = useParams()
  const { formattedCurrency, formatPrice } = usePriceFormat()

  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const {
    exceedsPolicy,
    isDealFare,
    isNegotiatedFare,
    isDisabled,
    ratePlanCode,
    policyRatePlanCode,
  } = room
  const bedsCount = useMemo(() => {
    return searchData.hotels?.travelerCounts?.beds?.count || 1
  }, [searchData.hotels])

  const handleAddToCheckout = () => {
    if (exceedsPolicy === POLICY_RESULT.BLOCK) {
      const allowBypassingPolicy = true
      return dispatch(
        openPolicyExceededModal({
          allowReasons: allowBypassingPolicy,
          clickHandler: addToCheckout,
        })
      )
    }

    addToCheckout()
  }

  const addToCheckout = async () => {
    const city = await fetchCityByIata(hotel.params.cityCode)

    let payload = {
      city: [city?.['City ENG'], city?.['City SWE']],
      type: 'Hotel',
      room: room,
      hotel: hotel,
      usersPerRoom: bedsCount,
      passengers: [...guests],
      roomsCount,
      totalPrice: parseInt(room.totalPrice),
      localCurrency: room.localCurrency,
      localTotalPrice: room.localTotalPrice,
      verifyPrice: room.originalTotalprice,
      /*
      localCurrency: hotel?.hotelsData?.CurrencyConversions?.CurrencyConversion?.SourceCurrencyCode,
      localTotalPrice: parseInt(hotel.hotel.rooms.find(el => el.RoomRates.RoomRate.BookingCode === room.id).Total.AmountAfterTax),
      */
    }

    if (searchData?.hotels?.multistep) {
      const payloadHotel = {
        ...payload,
        passengers: undefined,
        type: undefined,
      }
      const checkoutItem = items.find((i) => i.type === payload.type)
      let checkoutHotels = []
      if (!!checkoutItem && !checkoutItem.trips?.length) {
        checkoutHotels.push({
          room: checkoutItem.room,
          hotel: checkoutItem.hotel,
          usersPerRoom: checkoutItem.usersPerRoom,
          city: checkoutItem.city,
          totalPrice: checkoutItem.totalPrice,
          localCurrency: checkoutItem.localCurrency,
          localTotalPrice: checkoutItem.localTotalPrice,
          verifyPrice: checkoutItem.verifyPrice,
          roomsCount: checkoutItem.roomsCount,
        })
      } else if (!!checkoutItem && !!checkoutItem.trips?.length) {
        checkoutHotels = [...checkoutItem.trips]
      }
      payload = {
        type: payload.type,
        passengers: payload.passengers,
        totalPrice: payload.totalPrice + (checkoutItem?.totalPrice || 0),
        isMultistep: true,
        trips: [...checkoutHotels, payloadHotel],
      }
    }

    dispatch(addToCheckoutAction(payload))
    history.push('/checkout')
  }

  const daysBetween = numberOfDaysBetween(checkOut, checkIn)

  const freeCancellationLabel =
    t('hotel popular free') +
    (room.freeCancellationDeadline
      ? ' ' +
        t('until').toLowerCase() +
        ' ' +
        moment(room.freeCancellationDeadline).format(
          `YYYY-MM-DD [${t('at')}] HH:mm`
        )
      : '')

  const policyIndicator = (
    <Policy
      policy={exceedsPolicy}
      deal={isDealFare}
      corporate={isNegotiatedFare}
      ratePlanCode={policyRatePlanCode || ratePlanCode}
    />
  )

  return (
    <Card elevation={0} classes={{ root: classes.root }}>
      <Box p={isMobile ? 2 : 3} pt={2} pb={2} style={{ cursor: 'pointer' }}>
        <Grid container>
          <Grid item xs={12} sm>
            <Box flexDirection="column">
              <Typography variant="h5" className={classes.weight}>
                {!room?.isCompactRoom ? room.title : t('compact room')}
              </Typography>
              <Box pt={1}>
                <Typography>
                  {room.sleepingSpots}{' '}
                  {room.sleepingSpots > 1 ? t('sleeps') : t('sleeping berth')}
                </Typography>
              </Box>
              <Typography>{room.size}</Typography>
            </Box>
          </Grid>
          <Grid item xs md={5}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {!!room.noWindowRoom && (
                <Box className={classes.yellowBlob} mb={1}>
                  <Typography
                    variant="caption"
                    className={classes.weightSemiBold}
                  >
                    {t('room without window')}
                  </Typography>
                </Box>
              )}

              {!!room.isNoBathroom && (
                <Box className={classes.yellowBlob} mb={1}>
                  <Typography
                    variant="caption"
                    className={classes.weightSemiBold}
                  >
                    {t('room without bathroom')}
                  </Typography>
                </Box>
              )}

              {!!room.isBunkbed && (
                <Box className={classes.yellowBlob} mb={1}>
                  <Typography
                    variant="caption"
                    className={classes.weightSemiBold}
                  >
                    {t('bunk bed')}
                  </Typography>
                </Box>
              )}

              {policyIndicator && <Box mb={1}>{policyIndicator}</Box>}

              {room.cancellationType === 'free' &&
              moment(room.freeCancellationDeadline).isAfter(new Date()) ? (
                <Box className={classes.blob}>
                  <Typography
                    variant="caption"
                    className={classes.weightSemiBold}
                  >
                    {freeCancellationLabel}
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.yellowBlob}>
                  <Typography
                    variant="caption"
                    className={classes.weightSemiBold}
                  >
                    {t('non refundable')}
                  </Typography>
                </Box>
              )}
              <Box pt={1}>
                <Box className={classes.alignChildren} pt={1}>
                  <LocalHotelIcon style={{ marginRight: '8px' }} />
                  <Typography>{room.bed}</Typography>
                </Box>
                {room.freeWifi ? (
                  <Box className={classes.alignChildren} pt={1}>
                    <WifiIcon style={{ marginRight: '8px' }} />
                    <Typography>{t('hotel popular wifi')}</Typography>
                  </Box>
                ) : null}
                {room.breakfastIncluded && room.breakfastIncluded !== '0' ? (
                  <Box className={classes.alignChildren} pt={1}>
                    <FreeBreakfastIcon style={{ marginRight: '8px' }} />
                    <Typography>{t('hotel popular breakfast')}</Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="h5" align="right" className={classes.weight}>
                <span style={{ fontSize: '12px' }}> {formattedCurrency}</span>{' '}
                {formatPrice(room.price)}
              </Typography>
              <Typography align="right">
                {roomsCount} {roomsCount > 1 ? t('rooms') : t('room')},{' '}
                {daysBetween} {daysBetween > 1 ? t('night') : t('one night')}
              </Typography>

              <Box pt={1}>
                <Typography align="right" variant="caption">
                  <span className={classes.weight}>
                    TOT {formattedCurrency}{' '}
                  </span>
                  <span style={{ fontSize: '16px' }}>
                    {formatPrice(room.totalPrice)}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} display="flex">
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={handleAddToCheckout}
                className={classes.btn}
                disabled={isDisabled}
              >
                {t('select the room')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default memo(HotelRoomCard)
