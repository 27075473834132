import React, { memo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import Moment from 'react-moment'
import moment from 'moment'
import FlightIcon from '@material-ui/icons/Flight'

import RoomIcon from '@material-ui/icons/Room'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { makeStyles } from '@material-ui/core/styles'
import { formatDuration } from '../../../utils/formatDurations'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { Train } from '@material-ui/icons'
import airplaneModels from '../../../constants/airplaneModels.json'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 0,
    paddingLeft: 0,
  },
  timeline: {
    paddingLeft: 0,
    width: '100%',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  connector: {
    backgroundColor: theme.palette.common.black,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
}))

// takes an array of flights and produces a timeline
const FlightTimeLine = ({ flight }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const renderTimeline = flight.map((stop, i, all) => {
    const nextRide = i < all.length - 1 && all[i + 1]
    const endTime = stop.arrival
    const start = moment(endTime)
    const end = moment(nextRide?.departure)
    const duration = formatDuration(end.diff(start, 'minutes'))

    const findDuration = (key) => {
      const startTime = moment(stop.via[key - 1].arrival)
      const endTime = moment(stop.via[key].departure)

      return formatDuration(endTime.diff(startTime, 'minutes'))
    }

    const getEquipmentText = (equipmentType) =>
      equipmentType
        ? airplaneModels.find((plane) => plane.iata === equipmentType)?.model
        : null

    return (
      <Fragment key={i}>
        <TimelineItem>
          <TimelineOppositeContent className={classes.root}>
            <Typography
              variant="subtitle2"
              className={i === 0 ? classes.bold : ''}
            >
              {stop.departTime}
            </Typography>
          </TimelineOppositeContent>

          <TimelineSeparator>
            <FlightIcon className={classes.rotate} fontSize="large" />
            <TimelineConnector className={classes.connector} />
          </TimelineSeparator>

          <TimelineContent>
            <Typography
              className={i === 0 ? classes.bold : ''}
              variant="subtitle2"
            >
              {stop.fromAirport
                ? `${stop.fromCity} (${stop.fromAirport})`
                : `${stop.from.city} (${stop.from.displayName})`}
            </Typography>

            {stop.via[0].equipmentType && (
              <Typography variant="subtitle2">
                {getEquipmentText(stop.via[i].equipmentType)}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>

        {i < all.length - 1 && (
          // Between Stations
          <TimelineItem>
            <TimelineOppositeContent className={classes.root}>
              <Typography variant="subtitle2">
                <Moment format="HH:mm">{stop.arrival}</Moment>
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <RadioButtonUncheckedIcon fontSize="large" />
              <TimelineConnector className={classes.connector} />
            </TimelineSeparator>

            <TimelineContent>
              <Typography variant="subtitle2">
                {stop.toAirport
                  ? `${stop.toCity} (${stop.toAirport})`
                  : `${stop.to.city}`}
              </Typography>
              <Box className={classes.align}>
                <SwapHorizIcon fontSize="small" />
                <Typography variant="body2">
                  {i18n.t('detail byte')} {duration}
                </Typography>
              </Box>
            </TimelineContent>
          </TimelineItem>
        )}

        {stop.via &&
          stop.via.length > 0 &&
          stop.via.map(
            (el, key) =>
              key > 0 && (
                <>
                  <TimelineItem key={el.flightnumber}>
                    <TimelineOppositeContent className={classes.root}>
                      <Typography variant="subtitle2">
                        {stop.via[key - 1].arriveTime}
                      </Typography>
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                      <RadioButtonUncheckedIcon fontSize="large" />
                      <TimelineConnector className={classes.connector} />
                    </TimelineSeparator>

                    <TimelineContent>
                      <Typography variant="subtitle2">{`${el.fromCity}`}</Typography>
                      <Box className={classes.align}>
                        <SwapHorizIcon fontSize="small" />
                        <Typography variant="body2">
                          {i18n.t('detail byte')} {findDuration(key)}
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem key={key}>
                    <TimelineOppositeContent className={classes.root}>
                      <Typography variant="subtitle2">
                        {el.departTime}
                      </Typography>
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                      {el.isTrain ? (
                        <Train fontSize="large"></Train>
                      ) : (
                        <FlightIcon
                          className={classes.rotate}
                          fontSize="large"
                        />
                      )}
                      <TimelineConnector className={classes.connector} />
                    </TimelineSeparator>

                    <TimelineContent>
                      <Typography variant="subtitle2">{`${el.fromCity}`}</Typography>

                      {stop.via[i].equipmentType && (
                        <Typography variant="subtitle2">
                          {getEquipmentText(el.equipmentType)}
                        </Typography>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                </>
              )
          )}

        {i === all.length - 1 && (
          // End
          <TimelineItem>
            <TimelineOppositeContent className={classes.root}>
              <Typography className={classes.bold} variant="subtitle2">
                {stop.arriveTime}
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <RoomIcon fontSize="large" />
            </TimelineSeparator>

            <TimelineContent>
              <Typography variant="body2" className={classes.bold}>
                {!!stop.toAirport
                  ? `${stop.toCity} (${stop.toAirport})`
                  : `${stop.to.city} (${stop.to.displayName})`}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
      </Fragment>
    )
  })

  return (
    <Timeline align="left" className={classes.timeline}>
      {renderTimeline}
    </Timeline>
  )
}

export default memo(FlightTimeLine)
