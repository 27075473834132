import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, Button, Card, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { useParams } from 'react-router-dom'
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter'
import StarsRating from '../../../components/reusable/ratingsStars'
import WifiIcon from '@material-ui/icons/Wifi'
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast'
import CheckIcon from '@material-ui/icons/Check'
import numberWithCommas from '../../../utils/numberWithCommas'
import numberOfdaysBetween from '../../../utils/daysBetween'
import { useTranslation } from 'react-i18next'
import { getCurrencyCodeBySign } from '../../../utils/general'
import { getFreeCancellationLabel } from '../../../utils/hotelHelpers'
import usePriceFormat from '../../../hooks/usePriceFormat'

const useStyles = makeStyles((theme) => ({
  card: { borderRadius: theme.spacing(1) },
  align: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
      '&:first-child': { marginLeft: 0 },
    },
  },
  alignItems: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  top: {
    marginTop: theme.spacing(4),
  },
  ratingContainer: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(1),
  },
  checkInCheckout: {
    padding: 5,
    borderRadius: theme.spacing(1),
  },
  adress: {
    display: 'flex',
    '& > *': {
      margin: '0 5px',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  choosebtn: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 4),
    letterSpacing: 1.1,
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightBold,
  },
  checkoutCheckinContainer: {
    fontWeight: 600,
    letterSpacing: '1.2',
    textTransform: 'capitalize',
  },
  yellowBlob: {
    backgroundColor: theme.palette.yellow,
    color: theme.palette.orange,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const HotelInfo = ({ info, addToCheckout, stars, room }) => {
  const { t } = useTranslation()
  const { checkIn, checkOut } = useParams()
  const { roomsCount } = useParams()
  const classes = useStyles()
  const hotel = useSelector((state) => state.hotels.single)
  const { currency } = useSelector((state) => state.auth)
  const currencyCode = getCurrencyCodeBySign(currency)
  const renderAdress = info?.CachedData?.ADRESS_LINE_1
  const { freeWifi } = info
  const { formatPrice } = usePriceFormat()

  const daysBetween = numberOfdaysBetween(checkOut, checkIn)

  const { breakfastIncluded, cancellationType, freeCancellationDeadline } = room

  const freeCancellationLabel = getFreeCancellationLabel(
    cancellationType,
    freeCancellationDeadline
  )

  return (
    <Box p={2} pl={0} pr={0} mb={2}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <Typography variant="h4" className={classes.weight}>
            {capitalizeFirstLetter(info?.title || '')}
          </Typography>

          <Box mt={2} mb={2} className={classes.align}>
            <LocationOnIcon />
            <Typography>
              {capitalizeFirstLetter(renderAdress || '')},
            </Typography>
            <Typography>{info?.CachedData?.ZIP_CODE}</Typography>
            <Typography>
              {capitalizeFirstLetter(info?.CachedData?.CITY2 || '')},
            </Typography>
            <Typography>
              {capitalizeFirstLetter(info?.CachedData.COUNTRY_NAME || '')}
            </Typography>
          </Box>

          <Box className={classes.alignItems}>
            <Card elevation={0}>
              <Box p={1}>
                <StarsRating stars={stars} />
              </Box>
            </Card>

            {freeCancellationLabel && (
              <Card elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <CheckIcon />
                  <Typography className={classes.align}>
                    {freeCancellationLabel}
                  </Typography>
                </Box>
              </Card>
            )}

            {breakfastIncluded && breakfastIncluded !== '0' && (
              <Card elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <FreeBreakfastIcon />
                  <Typography>{t('hotel popular breakfast')}</Typography>
                </Box>
              </Card>
            )}

            {freeWifi && (
              <Card elevation={0}>
                <Box p={0.5} pl={1.5} pr={1.5} className={classes.align}>
                  <WifiIcon />
                  <Typography>{t('wifi included')}</Typography>
                </Box>
              </Card>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Box display="flex" alignItems="flex-end" flexDirection="column">
            <Box display="flex" alignItems="flex-end" flexDirection="row">
              {!!room?.noWindowRoom && (
                <Box className={classes.yellowBlob} mb={1} mr={2}>
                  <Typography
                    variant="caption"
                    className={classes.weightSemiBold}
                  >
                    {t('room without window')}
                  </Typography>
                </Box>
              )}

              <Typography variant="h4" className={classes.weight}>
                {hotel && formatPrice(room?.price, { prependCurrency: true })}
              </Typography>
            </Box>

            <Typography>
              {roomsCount} {roomsCount > 1 ? t('rooms') : t('room')} ,{' '}
              {daysBetween} {daysBetween > 1 ? t('night') : t('one night')}{' '}
              <span className={classes.weight}>
                TOT{' '}
                {hotel &&
                  formatPrice(room?.totalPrice, {
                    prependCurrency: true,
                  })}
              </span>
            </Typography>

            <Box pt={2}>
              <Button
                disableElevation
                onClick={addToCheckout}
                className={classes.choosebtn}
                color="primary"
                variant="contained"
              >
                {t('select the room')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(HotelInfo)
