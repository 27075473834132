import React, { memo } from 'react'
import { Box, Typography, Button, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'

import Seat from '../../../icons/Seat'
import OccupiedSeat from '../../../icons/occupiedSeat'
import SeatsContainer from './seatsContainer'

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginTop: 0,
      marginRight: theme.spacing(1),
      marginLeft: 0,
      fontWeight: 500,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  selected: {
    cursor: 'pointer',
    borderRadius: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,

    '& > *': {
      marginTop: 0,
      marginRight: theme.spacing(0.5),
      marginLeft: 0,
      fontWeight: 500,
      '& p': {
        color: theme.palette.primary.main,
      },
    },
  },
  box: {
    cursor: 'pointer',
    background: 'white',
    borderRadius: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border}`,
    '& p': {
      color: theme.palette.primary.main,
    },
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  btn: {
    width: '100%',
    textAlign: 'left',
    textTransform: 'none',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(3),

    '& .MuiButton-label': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '& p': {
        marginLeft: theme.spacing(1),
      },
    },
  },
  selectedBtn: {
    color: theme.palette.primary.dark,
  },
  removeBtn: {
    padding: 0,
  },
  iconContainer: {
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(1),
  },
  iconExitContainer: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconExit: {
    display: 'inline-flex',
    alignItems: 'center',
    height: theme.spacing(3),
    width: theme.spacing(1),
    fontWeight: 'normal',
    fontSize: 9,
    color: '#fff',
    letterSpacing: 0,
    textAlign: 'left',
    background: theme.palette.secondary.main,
    justifyContent: 'center',
  },
}))

const PassengerDetails = ({
  activePassengerId,
  setActivePassenger,
  removeSeat,
  passengerSeats,
  activeFlight,
  selectSeat,
  flight,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { passengers } = useSelector((state) => state.flights)

  const renderPassengers = passengers.map((passenger, index) => {
    const id = activePassengerId
    const selectedPassenger = passengerSeats.find(
      (x) =>
        x.userId === passenger.uniqueId &&
        activeFlight.uniqueInd === x.flightKey
    )

    return (
      <Box
        mt={index === 0 ? 0 : 2}
        key={passenger.uniqueId}
        className={passenger.uniqueId === id ? classes.selected : classes.box}
      >
        <Button
          className={classes.btn}
          onClick={() => setActivePassenger(passenger.uniqueId)}
        >
          <Typography className={classes.weight}>{index + 1}.</Typography>
          <Typography className={classes.weight}>
            {passenger.firstName} {passenger.lastName}
          </Typography>
        </Button>

        {selectedPassenger?.seat && (
          <Box className={classes.align}>
            <Button
              className={classes.removeBtn}
              onClick={() => removeSeat(passenger.uniqueId)}
              endIcon={
                <DeleteIcon
                  color={'primary'}
                  className={
                    passenger.uniqueId === id ? classes.selectedBtn : ''
                  }
                />
              }
            >
              <Typography className={classes.weight}>
                {selectedPassenger?.seat}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    )
  })

  return (
    <Box p={6} pt={0}>
      <Box>
        <Typography className={classes.weight}>
          {t('seats modal available')}
        </Typography>
      </Box>
      <Box mt={3}>{renderPassengers}</Box>
      {isMobile ? (
        <SeatsContainer
          flight={flight}
          activePassengerId={activePassengerId}
          selectSeat={selectSeat}
          passengerSeats={passengerSeats}
        />
      ) : (
        ''
      )}
      <Box mt={3}>
        <Typography>{t('seats modal auto')}</Typography>
      </Box>

      <Box p={2} mt={2} className={classes.iconContainer}>
        <Box className={classes.align}>
          <OccupiedSeat />
          <Typography> {t('seats modal busy')}</Typography>
        </Box>
        <Box className={classes.align} mt={2}>
          <Seat />
          <Typography> {t('seats modal free')}</Typography>
        </Box>
        <Box className={classes.align} mt={2}>
          <Seat color={theme.palette.primary.main} />
          <Typography>{t('seats modal selected')} </Typography>
        </Box>
        <Box mt={2} className={classes.align}>
          <Box className={classes.iconExitContainer}>
            <Typography className={classes.iconExit}>E</Typography>
          </Box>
          <Typography>{t('seats modal exit')}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(PassengerDetails)
