import React, { memo, Fragment, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Box,
  Hidden,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import ExpandedSearchBar from '../../containers/search/expandedSearch'
import GuestExpandedSearchBar from '../../containers/search/guest/guestExpandedSearch'
import GuestMobileSearch from '../../containers/search/guest/guestMobileSearch'
import { useTranslation } from 'react-i18next'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MobileBottomNavigation from '../../containers/footer/mobileBottomNavigation'
import CenterSearchbar from '../../hooks/centerSearchbar'

import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import MobileSearch from '../../containers/search/mobileSearch'
import useDirections from '../../hooks/useDirectionsHook'
import useOneWay from '../../hooks/useOneWayHook'
import useDestinations from '../../hooks/useSwitchDestinationsHook'
import useFlightsHook from '../../hooks/useFlightsHook'
import useTravellers from '../../hooks/useTravellersHook'
import usePopover from '../../hooks/usePopoverHook'
import BannerRow from '../../containers/header/bannerRow'

import { disableExtendCheckout } from '../../store/search/searchAction'
import ExtendCheckoutBanner from '../../containers/header/extendCheckoutBanner'
import FlightTypeSwitcher from '../../components/flights/flightTypeSwitcher'

const Flights = ({ showSearchModes, currentSearchMode, setSearchMode }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isAuthenticated, user, enableMultiStop } = useSelector(
    (state) => state.auth
  )
  const singlePnrBooking = user?.singlePnrBooking

  const { searchType } = useSelector((state) => state.flights)

  const {
    earliestDate,
    latestDate,
    suggestedEarliestDate,
    suggestedLatestDate,
    allowedFlightDestination,
  } = user?.bookingLink || {}
  const isToLocked = allowedFlightDestination?.length > 0

  const {
    from: initialFrom,
    to: initialTo,
    departure: initialDeparture,
    arrival: initialArrival,
    travellers: initialTravellers,
    extendCheckout,
  } = useSelector((state) => state.search.flights)

  const { items } = useSelector((state) => state.checkout)

  useEffect(() => {
    const flight = items.find((i) => {
      return i.type === 'Flight'
    })

    if (!items.length) {
      dispatch(disableExtendCheckout())
    } else if ((!!flight || !user) && !!extendCheckout) {
      history.push('/checkout')
    }
  }, [items, extendCheckout, dispatch])

  const { airports } = useSelector((state) => state.flights)

  const { departure, depChange, arrival, arrOnChange } = useDirections(
    suggestedEarliestDate || initialDeparture,
    suggestedLatestDate || initialArrival,
    true,
    earliestDate,
    latestDate
  )
  const { isOneWayDest: isOneWay } = useOneWay(arrival)
  const { travellers, setTravellers, setAgentCompany } =
    useTravellers(initialTravellers)
  const MobSearchBar = isAuthenticated ? MobileSearch : GuestMobileSearch
  const SearchBar = isAuthenticated ? ExpandedSearchBar : GuestExpandedSearchBar

  const {
    switchDestinations,
    setFrom,
    setTo,
    fromDest: from,
    toDest: to,
  } = useDestinations(
    initialFrom,
    //    allowedDestination || initialTo,
    initialTo,
    i18n,
    'flight'
  )
  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTo,
    setIsPopoverOpenTo,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
  } = usePopover()
  const {
    getCities,
    onSubmit,
    counts,
    modifyCounts,
    ticketClass,
    setTicketClass,
    ticketClasses,
  } = useFlightsHook({
    fromDest: from,
    toDest: to,
    history,
    from: initialFrom,
    to: initialTo,
    i18n,
    arrival,
    departure,
    dispatch,
    isOneWayDest: isOneWay,
    travellers,
    isOutgoing: true,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTo,
    setIsPopoverOpenTraveller,
    forceNewSearch: !extendCheckout,
  })

  const defaultOptions = [
    {
      City: 'Muu',
      Country: 'Bläh',
      IATA: 'XYZ',
    },
  ]

  const optionLabel = (options) => {
    return options['Airport']
      ? `${options['City']}, ${options['Country']} (${options.IATA})`
      : ''
  }

  const renderOption = (option, { inputValue }) => {
    const matches = match(
      `${option['City']}, ${option['Country']} (${option.IATA})`,
      inputValue
    )
    const parts = parse(
      `${option['City']}, ${option['Country']} (${option.IATA})`,
      matches
    )

    return (
      <Fragment>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </Fragment>
    )
  }
  const checkoutTopBanner = useMemo(() => {
    return !!extendCheckout && !!isMobile ? (
      <ExtendCheckoutBanner type={'flight'} />
    ) : (
      false
    )
  }, [extendCheckout, isMobile])

  useEffect(() => {
    if (searchType === 'oneWay') {
      arrOnChange(null)
    }
    if (searchType === 'multiStop') {
      history.push('/flights-multicity')
    }
  }, [searchType])

  return (
    <>
      {!extendCheckout && <BannerRow bannerKey="flight"></BannerRow>}

      {!extendCheckout && enableMultiStop && false && (
        <Box style={{ display: 'block' }}>
          <FlightTypeSwitcher />
        </Box>
      )}

      <CenterSearchbar topContent={checkoutTopBanner}>
        <Hidden mdUp>
          <MobSearchBar
            /* */
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            setAgentCompany={setAgentCompany}
            /* */
            departure={departure}
            setDeparture={depChange}
            arrival={arrival}
            setArrival={arrOnChange}
            /* */
            travellers={travellers}
            setTravellers={setTravellers}
            switchDestinations={switchDestinations}
            getCities={getCities}
            isOneWay={isOneWay}
            onSubmit={onSubmit}
            array={airports}
            optionLabel={optionLabel}
            renderOption={renderOption}
            defaultOptions={defaultOptions}
            setIsPopoverOpenFrom={setIsPopoverOpenFrom}
            isPopoverOpenFrom={isPopoverOpenFrom}
            setIsPopoverOpenTo={setIsPopoverOpenTo}
            isPopoverOpenTo={isPopoverOpenTo}
            setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
            isPopoverOpenTraveller={isPopoverOpenTraveller}
            counts={isAuthenticated ? null : counts}
            modifyCounts={modifyCounts}
            setTicketClass={setTicketClass}
            ticketClass={ticketClass}
            ticketClasses={ticketClasses}
            earliestDate={earliestDate}
            latestDate={latestDate}
            isToLocked={isToLocked}
            usersDisabled={!!extendCheckout && !singlePnrBooking}
            disableTripTypeSelection={!!extendCheckout}
            disableReturn={searchType === 'oneWay'}
          />
        </Hidden>
        <Hidden smDown>
          <SearchBar
            /* */
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            setAgentCompany={setAgentCompany}
            /* */
            departure={departure}
            setDeparture={depChange}
            arrival={arrival}
            setArrival={arrOnChange}
            /* */
            travellers={travellers}
            setTravellers={setTravellers}
            switchDestinations={switchDestinations}
            getCities={getCities}
            isOneWay={isOneWay}
            onSubmit={onSubmit}
            array={airports}
            optionLabel={optionLabel}
            renderOption={renderOption}
            defaultOptions={defaultOptions}
            setIsPopoverOpenFrom={setIsPopoverOpenFrom}
            isPopoverOpenFrom={isPopoverOpenFrom}
            setIsPopoverOpenTo={setIsPopoverOpenTo}
            isPopoverOpenTo={isPopoverOpenTo}
            setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
            isPopoverOpenTraveller={isPopoverOpenTraveller}
            counts={isAuthenticated ? null : counts}
            modifyCounts={modifyCounts}
            setTicketClass={setTicketClass}
            ticketClass={ticketClass}
            ticketClasses={ticketClasses}
            showSearchModes={showSearchModes}
            currentSearchMode={currentSearchMode}
            setSearchMode={setSearchMode}
            earliestDate={earliestDate}
            latestDate={latestDate}
            isToLocked={isToLocked}
            usersDisabled={!!extendCheckout && !singlePnrBooking}
            extendCheckoutType={!!extendCheckout ? 'flight' : false}
            disableReturn={searchType === 'oneWay'}
          />
        </Hidden>
      </CenterSearchbar>

      <Hidden smUp>
        <MobileBottomNavigation />
      </Hidden>
    </>
  )
}

export default memo(Flights)
