import React, {Fragment, useCallback, useEffect, useState, useMemo} from 'react'
import {Box, Button, CircularProgress, Grid} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { fetchRailSchedules } from '../../repositories/suggestions'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import { getBrandedUrl } from '../../utils/branding'
import {useTranslation} from 'react-i18next'
import axios from 'axios'
import RailTripSearchItem from '../../containers/trains/trainContainers/trainSearchItem/RailTripSearchItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  tripEditOuter: {
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '100%'
  },

  openIcon: {
    color: theme.palette.primary.main,
    marginLeft: '13px'
  },

  topHeaderRow: {
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '23px',
      borderBottom: 'solid 1px #ededed'
    }
  },

  infoOuter: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px'
    }
  },

  closeInfoRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },

  closeInfoLine: {
    flex: 1,
    height: '1px',
    backgroundColor: '#ededed'
  },

  closeInfoBtn: {
    minHeight: '30px',
    padding: '5px 15px',
    border: 'solid 1px #ededed',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold'
  },

  topHeaderLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },

  showInfoRow: {
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    borderBottom: 'solid 1px #ededed',
    marginBottom: '20px',
    cursor: 'pointer'
  },

  mainHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: 'normal'
  },

  logoImage: {
    height: '58px',
    width: '58px'
  },

  topHeaderRight: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer'
  },

  cancelLinkText: {
    fontSize: '16px',
    fontWeight: 'normal'
  },

  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main
  },

  tripInfoRow: {
    marginBottom: '37px',
    marginTop: '34px',
    display: 'flex',
    alignItems: 'start',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: '27px',
      marginBottom: '46px'
    }
  },

  classTitle: {
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'center',
    color: '#000'
  },

  classesTextRow: {
    marginBottom: '19px'
  },

  tripInfoBlock: {
    '&.trip-info-from': {
      marginRight: '90px',
    },

    '&.trip-info-to': {
      marginRight: '70px'
    },

    '&.trip-info-dep': {
      marginRight: '60px'
    },

    '&:last-child': {
      marginRight: 0
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',

      '&:last-child': {
        marginBottom: 0
      },

      '&.trip-info-from, &.trip-info-to, &.trip-info-dep': {
        marginRight: 0
      },
    }
  },

  tripInfBlockTitle: {
    fontSize: '11px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    marginBottom: '2px'
  },

  tripInfoBlockValue: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 'normal'
  },

  loadingBlock: {
    display: 'flex',
    width: '100%',
    marginTop: '25px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const AutomateBookingRailTrip = ({ suggestion, jwt, selectTrip }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    dir: 'outbound',
    mobileInfoOpen: true,
    isMounted: false,
    loading: true,
    selectedOutbound: null,
    error: null,
    trips: []
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const getTrips = async () => {
    setState({...state, loading: true, isMounted: true, trips: []})
    const resultState = {isMounted: true, loading: false, trips: []}
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const rqData = { direction: state.dir }

      if (state.dir === 'return' && !!state.selectedOutbound) {
        rqData.bookingToken = state.selectedOutbound.solution.bookingToken
        rqData.correlationID = state.selectedOutbound.correlationID
      }

      const data = await fetchRailSchedules(jwt, rqData, 'tickets')
      if (!data?.list?.length) throw new Error('no trips found')

      resultState.trips = (data?.list?.length) ? [...data.list] : []
      resultState.error = null
      setState({...state, ...resultState})
      return true
    } catch (e) {
      if (!axios.isCancel(e)) {
        resultState.error = 'no ticket found'
        resultState.trips = []
        setState({...state, ...resultState})
      }
      return false
    }
  }

  useEffect(() => {
    getTrips()
  }, [state.dir])

  const toggleInfo = useCallback((isOpen) => {
    setState({...state, mobileInfoOpen: !!isOpen})
  }, [state])

  const onSelectTicket = useCallback(ticket => {
    if (!ticket) return false
    if (state.dir === 'outbound') {
      const retDate = moment(suggestion?.parsedSourceData?.returnDate, 'YYYY-MM-DD HH:mm')

      if (retDate.isValid()) {
        setState({...state, selectedOutbound: {...ticket}, selectedReturn: null, dir: 'return'})
      } else {
        selectTrip({outbound: {...ticket}, return: null})
      }
    } else if (state.dir === 'return') {
      selectTrip({outbound: {...state.selectedOutbound}, return: {...ticket}})
    }
  }, [state, getTrips])

  const getTrainClass = useCallback(() => {
    const parsed = suggestion?.parsedSourceData
    const isParsedTkt = !!parsed?.railClassKey && !!parsed?.railFlexKey
    let tktLine = ''

    if (isParsedTkt) {
      const clExplode = parsed.railClassKey.split('_')
      const classNumText = clExplode?.[1] === 'cs' ? t('class calm') : t('class')
      const classNum = clExplode?.[1] === '001' ? '1' : '2'
      tktLine = `${classNum} ${classNumText} ${t(parsed.railFlexKey)}`
    } else {
      tktLine = parsed?.trainClass
    }
    return tktLine
  }, [suggestion, t])

  const depDate = useMemo(() => {
    const depDateText = state.dir === 'outbound' ? suggestion?.parsedSourceData?.depDate : suggestion?.parsedSourceData?.returnDate
    const depDate = moment(depDateText, 'YYYY-MM-DD HH:mm')
    return !depDate.isValid() ? null : depDate.format('dddd D MMM HH:mm')
  }, [suggestion, state])

  const curTripData = useMemo(() =>{
    return suggestion?.tripData?.[state.dir]
  }, [suggestion, state.dir])

  return (
    <Box key="tkt-trip-edit-outer" className={classes.tripEditOuter}>
      {!!suggestion && (
        <Fragment>
          <Box key={'info-outer-block-row'} className={classes.infoOuter}>
            <Box key="top-header-row" className={classes.topHeaderRow}>
              <Box key="top-header-text-block" className={classes.topHeaderLeft}>
              <span key="header-text" className={classes.mainHeader}>
                {t(state.dir === 'outbound' ? 'select outbound trip': 'select return trip')}
              </span>
              </Box>
              {!isMobile && (
                <Box key="top-header-right-block" className={classes.topHeaderRight}>
                  <img src={getBrandedUrl('logo.jpg')} className={classes.logoImage}/>
                </Box>
              )}
            </Box>
            {(state.mobileInfoOpen || !isMobile) &&
              <Fragment>
                <Box key="trip-info-row" className={classes.tripInfoRow}>
                  <Box key="trip-info-from" className={`${classes.tripInfoBlock} trip-info-from`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{_.capitalize(t('from'))}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>
                      {state.dir === 'outbound' ? suggestion.parsedSourceData.depLocation : suggestion.parsedSourceData.destLocation}
                    </Box>
                  </Box>

                  <Box key="trip-info-to" className={`${classes.tripInfoBlock} trip-info-to`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{t('search to')}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>
                      {state.dir === 'outbound' ? suggestion.parsedSourceData.destLocation : suggestion.parsedSourceData.depLocation}
                    </Box>
                  </Box>

                  <Box key="trip-info-dep" className={`${classes.tripInfoBlock} trip-info-dep`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{t('departure')}</Box>
                    <Box key="trip-info-block-value" className={classes.tripInfoBlockValue}>{depDate}</Box>
                  </Box>

                  <Box key="trip-info-ticket" className={`${classes.tripInfoBlock} trip-info-block`}>
                    <Box key="trip-info-block-title" className={classes.tripInfBlockTitle}>{t('ticket')}</Box>
                    <Box key="trip-info-block-value"  className={classes.tripInfoBlockValue}>{getTrainClass()}</Box>
                  </Box>
                </Box>
                {isMobile &&
                  <Box key="close-info-row" className={classes.closeInfoRow}>
                    <Box key="close-info-left-line" className={classes.closeInfoLine}/>
                    <Box key="close-info-button" className={classes.closeInfoBtn} onClick={() => toggleInfo(false)}>
                      {t('detail collapse')}
                    </Box>
                    <Box key="close-info-right-line" className={classes.closeInfoLine}/>
                  </Box>
                }
              </Fragment>
            }
            {!state.mobileInfoOpen && isMobile &&
              <Box key="show-info-row" className={classes.showInfoRow} onClick={() => toggleInfo(true)}>
                <span>{t('View search details')}</span> <KeyboardArrowDownIcon key="open-icon" className={classes.openIcon}/>
              </Box>
            }
          </Box>

          {!!state.loading &&
            <Box className={classes.loadingBlock}>
              <CircularProgress/>
            </Box>
          }

          {!state.loading && !!state.error &&
            <div key={'error-block'} className={classes.errorBlock}>
              {t('trips not found')}
            </div>
          }

          {!state.loading && !state.error && !!state.trips?.length && (
            <Fragment>
              {!!isMobile && (
                <Grid key={'classes-text-row'} container spacing={0} className={classes.classesTextRow}>
                  <Grid key={'classes-text-left'} item xs={6} lg={8}/>
                  <Grid key={'classes-text-right'} item xs={6} lg={4}>
                    <Grid key={'classes-inner-row'} container spacing={0}>
                      <Grid key={'classes-inner-row-left'} item xs={6} className={classes.classTitle}>
                        2 {t('class')}
                      </Grid>
                      <Grid key={'classes-inner-row-right'} item xs={6} className={classes.classTitle}>
                        1 {t('class')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <div key="trips-list">
                {state.trips.map((trip, i) => {
                  return (<RailTripSearchItem key={`trip-item-${i}`} onSelectTicket={onSelectTicket} item={trip} currTrip={curTripData}/>)
                })}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Box>
  )
}

export default AutomateBookingRailTrip
