import _ from 'lodash'
import constants from './constants'
import {
  fetchCityCoordinates,
  fetchHotelCities,
  fetchHotelRatings,
  fetchHotelSearch,
  cancelFetch as cancelHotelFetch,
} from '../../repositories/hotel'
import storage from 'local-storage-fallback'

export const fetchHotelSearchAction =
  ({ countryCode, lat, lng, checkIn, checkOut, guests, roomCount }) =>
  async (dispatch, getState) => {
    const state = getState()

    // Compare stored checksum to this search. If same, return immediately.
    let searchChecksum = `${countryCode}${lat}${lng}${checkIn}${checkOut}${guests}${roomCount}`
    if (
      state.hotels.searchChecksum === searchChecksum &&
      state.hotels.search?.length > 0
    )
      return

    // Save checksum of this search
    dispatch({
      type: constants.SET_HOTEL_SEARCH_CHECKSUM,
      payload: searchChecksum,
    })

    cancelHotelFetch('fetchHotelsList')
    dispatch(removeHotelStateAll())
    dispatch({
      type: constants.SET_HOTEL_ERROR_RESULT,
      payload: null,
    })
    // Parse to Float
    const latitude = parseFloat(lat)
    const longitude = parseFloat(lng)

    const payload = {
      startDate: checkIn,
      endDate: checkOut,
      roomsCount: roomCount,
      detailed: 1,
      guests: [
        {
          ageCode: 'adult',
          count: guests,
        },
      ],
      latitude: latitude,
      longitude: longitude,
      countryCode: countryCode,
    }

    dispatch({ type: constants.SET_HOTEL_LOADING_RESULTS, payload: true })

    let hotelsCount
    let error

    try {
      const fetchParams = { fetchKey: 'fetchHotelsList' }
      hotelsCount = await fetchHotelSearch(payload, dispatch, fetchParams)
    } catch (e) {
      error = e
    }

    if (!hotelsCount || error) {
      return dispatch({
        type: constants.SET_HOTEL_ERROR_RESULT,
        payload: 'no hotels',
      })
    }

    dispatch({ type: constants.SET_HOTEL_LOADING_RESULTS, payload: false })
  }

export const fetchSingleHotelAction =
  (params) => async (dispatch, getState) => {
    const state = getState()

    const {
      chainCode,
      cityCode,
      countryCode,
      guests,
      hotelCode,
      roomsCount,
      checkIn,
      checkOut,
      minAge = false,
    } = params

    const payload = {
      chainCode: chainCode,
      cityCode: cityCode,
      countryCode: countryCode,
      detailed: 1,
      guests: [{ ageCode: 'adult', count: guests }],
      hotelCode: hotelCode,
      roomsCount: roomsCount,
      startDate: checkIn,
      endDate: checkOut,
      minAge,
    }

    // Compare stored checksum to this search. If same, return immediately.
    let singleChecksum = JSON.stringify({
      payload,
      date: new Date().toISOString().slice(0, 10),
    })
    if (state.hotels.singleChecksum === singleChecksum && state.hotels.single)
      return

    // Save checksum of this search
    dispatch({
      type: constants.SET_HOTEL_SINGLE_CHECKSUM,
      payload: singleChecksum,
    })

    dispatch(removeHotelStateAll())
    dispatch({
      type: constants.SET_SINGLE_HOTEL_ERROR,
      payload: null,
    })

    dispatch({ type: constants.SET_SINGLE_LOADING_HOTEL, payload: true })
    let hotelsCount = await fetchHotelSearch(payload, dispatch, params)

    if (!hotelsCount) {
      dispatch({ type: constants.SET_SINGLE_LOADING_HOTEL, payload: false })
      return dispatch({
        type: constants.SET_SINGLE_HOTEL_ERROR,
        payload: 'No Rooms found',
      })
    }
    dispatch({ type: constants.SET_SINGLE_LOADING_HOTEL, payload: false })
  }

export const fetchHotelCitiesAction = (string) => async (dispatch) => {
  let cities
  try {
    cities = await fetchHotelCities(string)
  } catch {
    cities = []
  }
  return dispatch({ type: constants.SET_HOTEL_CITIES, payload: cities })
}

export const setCoordinatesAction = (city) => ({
  type: constants.SET_CITY_COORDINATES,
  payload: city,
})

export const fetchCityCoordinatesAction = (cityId) => async (dispatch) => {
  const city = await fetchCityCoordinates(cityId)
  return dispatch(setCoordinatesAction(city))
}

export const fetchHotelRatingsAction =
  ({ lat, lng, countryCode }) =>
  async (dispatch) => {
    const latitude = parseFloat(lat)
    const longitude = parseFloat(lng)
    const ratings = await fetchHotelRatings({
      latitude,
      longitude,
      countryCode,
    })
    return dispatch({ type: constants.SET_HOTEL_RATINGS, payload: ratings })
  }

export const removeHotelStateAll = () => ({
  type: constants.CLEAN_HOTEL_SEARCH_STATES,
})

export const setHotelGuestsAction = (guests) => (dispatch) => {
  storage.setItem('hotelGuests', JSON.stringify(guests))
  dispatch({ type: constants.SET_HOTEL_GUESTS, payload: guests })
}

export const setHotelPopularFilters = (filters) => ({
  type: constants.SET_HOTEL_POPULAR_FILTER,
  payload: filters,
})

export const setHotelMaxPriceFilters = (price) => ({
  type: constants.SET_HOTEL_MAX_PRICE_FILTER,
  payload: price,
})

export const setHotelStarsFilters = (filters) => ({
  type: constants.SET_HOTEL_STARS_FILTER,
  payload: filters,
})

export const setHotelGuestRatingFilter = (filters) => ({
  type: constants.SET_HOTEL_GUEST_RATING_FILTER,
  payload: filters,
})

export const resetHotelFilters = () => ({
  type: constants.RESET_HOTEL_FILTERS,
})

export const setSelectedHotel = (hotelCode) => ({
  type: constants.SET_SELECTED_HOTEL,
  payload: hotelCode,
})

export const setSelectedHotelPrice = (hotelCode) => ({
  type: constants.SET_SELECTED_HOTEL_PRICE,
  payload: hotelCode,
})

export const setShowHotelMap = (showMap) => ({
  type: constants.SET_SHOW_MAP,
  payload: showMap,
})

export const setMapCenter = (mapCenter) => ({
  type: constants.SET_MAP_CENTER,
  payload: mapCenter,
})

export const setEnableZoneFilter = (enabled) => ({
  type: constants.SET_ENABLE_ZONEFILTER,
  payload: enabled,
})

export const setZoneFilteredSearch = (search) => ({
  type: constants.SET_ZONE_FILTERED_SEARCH,
  payload: search,
})

export const setSeenZoneFilter = (hasSeen) => ({
  type: constants.SET_SEEN_ZONE_FILTER,
  payload: hasSeen,
})
